import { ComparisonTableProps } from './types';
import {
  Table,
  StyledCta,
  TableHeader,
  ImageContainer,
  TableCellContainer,
  TableWrapper,
} from './styledComponents';
import { GatsbyImg } from '@smartproxy-web/ui';
import { TableColumn } from './TableColumn';
import { LinkButton } from '../LinkButton';
import { usePageContext } from 'contexts/PageContext';
import { buildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { getBackgroundColor, groupDataByColumn } from './utils';
import { SliceHeader, Slice } from 'components/v2/Slice';

export const ComparisonTable: React.FC<ComparisonTableProps> = ({
  primary: {
    background,
    subtitle,
    title,
    cta_icon: ctaIcon,
    cta_text: ctaText,
    cta_link: ctaLink,
    second_column_header: secondColumnHeader,
    second_column_header_icon: secondColumnHeaderIcon,
    third_column_header: thirdColumnHeader,
    third_column_header_icon: thirdColumnHeaderIcon,
    fourth_column_header: fourthColumnHeader,
    fourth_column_header_icon: fourthColumnHeaderIcon,
    fifth_column_header: fifthColumnHeader,
    fifth_column_header_icon: fifthColumnHeaderIcon,
    sixth_column_header: sixthColumnHeader,
    sixth_column_header_icon: sixthColumnHeaderIcon,
    table_text_alignment: tableTextAlignment,
    is_upper_content_centered: isUpperContentCentered,
  },
  items,
}) => {
  const headers = [
    {
      text: secondColumnHeader,
      image: secondColumnHeaderIcon,
    },
    {
      text: thirdColumnHeader,
      image: thirdColumnHeaderIcon,
    },
    {
      text: fourthColumnHeader,
      image: fourthColumnHeaderIcon,
    },
    {
      text: fifthColumnHeader,
      image: fifthColumnHeaderIcon,
    },
    {
      text: sixthColumnHeader,
      image: sixthColumnHeaderIcon,
    },
  ];
  const { type, tags } = usePageContext();
  const { rows, maxColumnCount } = groupDataByColumn(items);
  const ctaTrackingSelector = buildTrackingSelector({
    prefix: type,
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    context: ctaText,
  });

  return (
    <Slice
      backgroundColor={getBackgroundColor(background)}
      data-section-id={generateSectionsId(
        SECTION_NAMES.COMPARISON_TABLE,
        tags,
        type
      )}
      id={buildTrackingSelector({
        prefix: type,
        page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
        context: title.text,
      })}
    >
      <SliceHeader
        title={title}
        description={subtitle}
        align={isUpperContentCentered || 'left'}
        bottomItems={
          ctaLink.url && (
            <StyledCta>
              <LinkButton
                to={ctaLink.url}
                color="blue"
                variant="outlined"
                fullWidth
                data-tracker={ctaTrackingSelector}
                onClick={() => logPostHogEvent(ctaTrackingSelector)}
                leftIcon={<GatsbyImg image={ctaIcon} />}
              >
                {ctaText}
              </LinkButton>
            </StyledCta>
          )
        }
      />
      <TableWrapper>
        <Table>
          <TableCellContainer numbOfColumns={maxColumnCount}>
            {headers.map(
              ({ text, image }) =>
                text !== null && (
                  <TableHeader key={text}>
                    {!!image?.url && (
                      <ImageContainer>
                        <GatsbyImg image={image} />
                      </ImageContainer>
                    )}
                    <p>{text}</p>
                  </TableHeader>
                )
            )}
          </TableCellContainer>
          {rows.map((item, idx) => (
            <TableColumn
              key={idx}
              columnData={item}
              idx={idx}
              numbOfColumns={maxColumnCount}
              tableTextAlignment={tableTextAlignment}
              lastArrayElement={idx === rows.length - 1}
            />
          ))}
        </Table>
      </TableWrapper>
    </Slice>
  );
};
