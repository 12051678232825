import { mediaQueries } from './media-query';
import { BreakpointSize, ResponsiveStylesProps } from './types';

const assertValueMap = (
  value: React.CSSProperties | Record<BreakpointSize, React.CSSProperties>
): value is Record<BreakpointSize, React.CSSProperties> => {
  return typeof value === 'object';
};

export const buildResponsiveStyles = (
  srcStyles: ResponsiveStylesProps<keyof React.CSSProperties>
) => {
  const newStyles = {};

  for (const key in srcStyles) {
    const possibleValueOrValueMap = srcStyles[key];

    if (assertValueMap(possibleValueOrValueMap)) {
      const valueMap = possibleValueOrValueMap;

      Object.entries(valueMap).forEach(([size, v]) => {
        if (size === 'base') {
          newStyles[key] = v;
        } else {
          const mediaQuery = mediaQueries[size as BreakpointSize];
          newStyles[mediaQuery] = {
            ...(!!newStyles[mediaQuery] && newStyles[mediaQuery]),
            [key]: v,
          };
        }
      });
    } else {
      const value = possibleValueOrValueMap;
      newStyles[key] = value;
    }
  }

  return newStyles;
};
