import { CarouselAutoplay, CarouselNavigation } from '@smartproxy-web/ui';
import { SwiperOptions } from 'swiper/types';

export const BANNER_TOP_CLOSED = 'bannerTopClosed';
export const COMPACT_HEADER_WIDTH = 1200;
export const COMPACT_HEADER_MEDIA_QUERY = `@media (max-width: ${COMPACT_HEADER_WIDTH}px)`;
export const HEADER_HEIGHT_DESKTOP = 45;
export const DEFAULT_DESKTOP_OFFSET = -200;
export const slidesGap = 50;
export const CLOSE_BANNER_SELECTOR = 'close-promo-banner';

export const swiperConfig = {
  modules: [CarouselAutoplay, CarouselNavigation],
  navigation: true,
  spaceBetween: slidesGap,
  slidesPerView: 1,
  allowTouchMove: false,
  autoplay: {
    delay: 12000,
    disableOnInteraction: true,
  },
  speed: 2000,
} satisfies SwiperOptions;
