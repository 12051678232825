import { useState } from 'react';
import { ItemsContainer } from './ItemText';
import { ItemImageContainer, TextItemsList } from './styledComponents';
import { TextImageDynamicProps } from './types';
import { Box, GatsbyImg } from '@smartproxy-web/ui';
import { Slice, SliceHeader } from '../Slice';

export const TextImageDynamic = ({
  primary: {
    title,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
    background,
    is_image_on_left: isImageOnLeft,
  },
  items,
}: TextImageDynamicProps) => {
  const [openedItem, setOpenedItem] = useState<number>(0);

  return (
    <Slice backgroundColor={background}>
      <SliceHeader
        title={title}
        description={subtitle}
        align={isSubtitleCentered ? 'center' : 'left'}
      />
      <Box sx={{ width: '100%', display: 'flex', gap: '80px' }}>
        <TextItemsList isImageOnLeft={isImageOnLeft} background={background}>
          {items.map(
            (
              {
                item_description: description,
                item_title: itemTitle,
                cta_link: ctaLink,
                cta_text: ctaText,
              },
              index
            ) => (
              <ItemsContainer
                key={itemTitle.text}
                description={description}
                ctaLink={ctaLink}
                ctaText={ctaText}
                itemTitle={itemTitle}
                isOpen={openedItem === index}
                onClick={() => {
                  setOpenedItem(index);
                }}
              />
            )
          )}
        </TextItemsList>
        {items.map(({ item_image: image }, index) => (
          <ItemImageContainer selected={openedItem === index} key={index}>
            <GatsbyImg image={image} />
          </ItemImageContainer>
        ))}
      </Box>
    </Slice>
  );
};
