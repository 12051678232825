import { GatsbyImg, TGatsbyImage, PrismicTextField } from '@smartproxy-web/ui';
import { RichText } from '@smartproxy-web/features';
import {
  BlogAuthorContainer,
  BlogAuthorDescription,
  BlogAuthorImageWrapper,
  BlogAuthorInfoContainer,
  BlogAuthorName,
  BlogAuthorWrapperContainer,
  HeadingStyled,
  ParagraphWrapper,
  Wrapper,
} from './AuthorStyledComponents';

const text = {
  global: {
    disclaimer: (
      <>
        All information on Smartproxy Blog is provided on an &quot;as is&quot;
        basis and for informational purposes only. We make no representation and
        disclaim all liability with respect to your use of any information
        contained on Smartproxy Blog or any third-party websites that may be
        linked therein.
      </>
    ),
    title: 'About the author',
  },
  china: {
    disclaimer: (
      <>
        Smartdaili博客上的所有信息都是按原样提供的，仅供信息目的。对于您使用Smartdaili博客或任何可能链接到其中的第三方网站上的任何信息，我们不作任何声明，也不承担任何责任。在从事任何形式的抓取活动之前，您应该咨询您的法律顾问，并仔细阅读特定网站的服务条款。
      </>
    ),
    title: '关于作者',
  },
} as const;

type AuthorProps = {
  primary: {
    blog_author: {
      document: {
        data: {
          authors_image: TGatsbyImage;
          authors_bio: PrismicTextField;
          authors_title: PrismicTextField;
          full_name: PrismicTextField;
        };
      };
    };
    is_global?: boolean;
  };
};

export default function Author({ primary }: AuthorProps) {
  if (!Boolean(primary?.blog_author?.document?.data)) {
    return <></>;
  }

  const { authors_image, authors_bio, authors_title, full_name } =
    primary.blog_author.document.data;
  const { is_global } = primary;

  const langaugeText = is_global ? text.global : text.china;

  return (
    <Wrapper id="knowledge-hub-author">
      <HeadingStyled level={3}>{langaugeText.title}</HeadingStyled>

      <BlogAuthorContainer>
        <BlogAuthorWrapperContainer>
          <BlogAuthorImageWrapper>
            <GatsbyImg image={authors_image} />
          </BlogAuthorImageWrapper>

          <BlogAuthorInfoContainer>
            <BlogAuthorName>
              <RichText field={full_name} />
            </BlogAuthorName>

            <BlogAuthorDescription>
              <RichText field={authors_title} />
            </BlogAuthorDescription>
          </BlogAuthorInfoContainer>
        </BlogAuthorWrapperContainer>

        <div>
          <RichText field={authors_bio} />
        </div>
      </BlogAuthorContainer>

      <ParagraphWrapper>{langaugeText.disclaimer}</ParagraphWrapper>
    </Wrapper>
  );
}
