import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';

export const BlogParentContainer = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    margin: '0 auto',
    maxWidth: '1024px',
    width: '100%',
    borderRadius: '24px',
    display: 'grid',
    columnGap: '24px',
    h3: {
      fontWeight: 700,
      fontSize: theme.fonts.size.h4,
      lineHeight: theme.fonts.lineHeight.h4,
      fontFamily: theme.fonts.family.additional,
      color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
      marginBottom: '24px',
    },
    [mediaQueries.md]: {
      padding: '24px',
      gridTemplateColumns: '55% 45%',
      height: '620px',
      background: isDark ? theme.colors.neutral.N94 : theme.colors.neutral.N02,
      border: `1px solid ${
        isDark ? theme.colors.neutral.N80 : theme.colors.neutral.N20
      }`,
    },
    [mediaQueries.lg]: {
      gridTemplateColumns: 'repeat(2, auto)',
    },
  })
);

export const LeftSideContainer = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    [mediaQueries.md]: {
      borderRight: `1px solid ${
        isDark ? theme.colors.neutral.N80 : theme.colors.neutral.N20
      }`,
      paddingRight: '24px',
      marginBottom: 0,
    },
  })
);

export const RightSideContainer = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    '::-webkit-scrollbar': {
      width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
      background: isDark ? theme.colors.neutral.N80 : theme.colors.neutral.N20,
      borderRadius: '24px',
    },
  })
);

export const BlogImageWrapper = styled(GatsbyLink)({
  width: '100%',
  height: '200px',
  marginBottom: '24px',
  borderRadius: '12px',
  background: theme.colors.neutral.N80,
  div: {
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    img: {
      objectFit: 'cover !important' as 'cover',
    },
  },
});

export const NewTopic = styled.span<{ isDark: boolean }>(({ isDark }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '41px',
  height: '21px',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '13px',
  letterSpacing: '1px',
  color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  textTransform: 'uppercase',
  position: 'relative',
  borderColor: 'transparent',
  '&::after': {
    borderRadius: '24px',
    position: 'absolute',
    content: '""',
    inset: -1,
    background: theme.colors.gradient.default,
    padding: 1,
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

export const BlogTitle = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  h4: {
    color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
    fontWeight: 700,
    fontFamily: theme.fonts.family.additional,
    display: '-webkit-box',
    ' -webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
      cursor: 'pointer',
    },
  },
}));

export const Description = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  marginTop: '12px',
  p: {
    color: isDark ? theme.colors.neutral.N40 : theme.colors.neutral.N80,
    fontSize: theme.fonts.size.p_medium,
    fontFamily: theme.fonts.family.additional,
    fontWeight: 400,
  },
}));

export const AuthorBlock = styled.div({
  marginTop: '12px',
  display: 'flex',
  columnGap: '8px',
  rowGap: '12px',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const AuthorImage = styled.div({
  width: '36px',
  height: '36px',
  background: theme.colors.neutral.N80,
  borderRadius: '50%',
  div: {
    borderRadius: '50%',
  },
});

export const BlogInfo = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  p: {
    fontSize: theme.fonts.size.p_small,
    fontFamily: theme.fonts.family.additional,
    fontWeight: 400,
    color: theme.colors.neutral.N50,
  },
  path: {
    fill: theme.colors.neutral.N50,
  },
});

export const BlogData = styled.div({
  marginBottom: '24px',
  display: 'flex',
  columnGap: '16px',
  marginRight: theme.spacings.XS,
  ':last-of-type': {
    marginBottom: 0,
  },
});

export const SmallBlogImageWrapper = styled(GatsbyLink)({
  minWidth: '100px',
  height: '100px',
  borderRadius: '12px',
  background: theme.colors.neutral.N80,
  div: {
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    maxWidth: '100px',
    img: {
      objectFit: 'cover !important' as 'cover',
    },
  },
});

export const Topics = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '12px',
});

export const Topic = styled(GatsbyLink)<{ isDark: boolean }>(({ isDark }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '10px',
  lineHeight: '13px',
  letterSpacing: '1px',
  fontFamily: theme.fonts.family.additional,
  fontWeight: 500,
  color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  border: `1px solid ${
    isDark ? theme.colors.neutral.N80 : theme.colors.neutral.N20
  }`,
  borderRadius: '24px',
  padding: '4px 8px',
  textTransform: 'uppercase',
  ':hover': {
    cursor: 'pointer',
    color: isDark ? theme.colors.neutral.N99 : theme.colors.neutral.N00,
    background: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
    transition: '0.3s',
  },
}));
