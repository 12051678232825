import React, { ReactNode, useRef } from 'react';
import {
  StyledLightbox,
  StyledLightboxControls,
  StyledLightboxInner,
} from './styledComponents';
import { CSSTransition } from 'react-transition-group';
import { IconClose } from '../../images';
import { Button } from '../Button';
import { useKeyPress } from '@smartproxy-web/shared/hooks';

interface LightboxProps {
  isOpen: boolean;
  children: ReactNode;
  handleClose: (event?: React.MouseEventHandler<HTMLElement>) => void;
  closeBtnTrackingId?: string;
}

export const Lightbox = ({
  isOpen,
  children,
  handleClose,
  closeBtnTrackingId,
}: LightboxProps) => {
  useKeyPress('Escape', handleClose);

  const lightboxElRef = useRef(null);

  return (
    <CSSTransition
      nodeRef={lightboxElRef}
      in={isOpen}
      mountOnEnter
      unmountOnExit
      timeout={1000}
      classNames="lightbox-backdrop"
    >
      <StyledLightbox
        ref={lightboxElRef}
        onClick={() => handleClose()}
        role="alertdialog"
        aria-modal="true"
        aria-label="Embed Lightbox"
      >
        <StyledLightboxControls>
          <Button
            data-tracker={closeBtnTrackingId}
            variant="text"
            color="white"
            size="lg"
            onClick={(e) => {
              e.stopPropagation();
              handleClose(e);
            }}
            autoFocus
          >
            <IconClose />
          </Button>
        </StyledLightboxControls>
        <StyledLightboxInner data-lightbox>{children}</StyledLightboxInner>
      </StyledLightbox>
    </CSSTransition>
  );
};
