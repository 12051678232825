import { SPACING_DEFAULT_HEIGHT } from 'constants/common';
import React from 'react';
import { SpacingBackgroundWrapper } from './styledComponents';

export interface ISpacing {
  primary: {
    background: string;
    height: number;
  };
}

export const Spacing: React.FC<ISpacing> = ({
  primary: { background, height },
}) => (
  <SpacingBackgroundWrapper
    background={background}
    height={height || SPACING_DEFAULT_HEIGHT}
  />
);
