import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { IColor } from 'types';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const BackgroundWrapper = styled.div<
  IColor & { backgroundImage: string }
>(({ background, backgroundImage }) => ({
  background,
  backgroundImage: `url(${backgroundImage})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  padding: '20px',
  width: '100%',
  [mediaQueries.sm]: {
    padding: '40px',
  },
  [mediaQueries.md]: {
    padding: `${theme.spacings.L}`,
  },
}));

export const HeroContent = styled.div({
  ...flexColContainerStyles,
  padding: '32px 20px',
  alignItems: 'flex-start',
  display: 'table',
  h1: {
    textAlign: 'left',
    margin: 0,
    fontSize: theme.fonts.size.h2,
    lineHeight: theme.fonts.lineHeight.h2,
    color: theme.colors.neutral.N00,
  },
  color: theme.colors.neutral.N00,
  width: '1024px',
  borderRadius: '24px',
  border: `1px solid var(--neutral-n-80, ${theme.colors.neutral.N80})`,
  background: 'rgba(13, 15, 16, 0.60)',
  backdropFilter: 'blur(12px)',
  a: {
    marginTop: 0,
  },
  [mediaQueries.sm]: {
    display: 'flex',
    alignItems: 'center',
    h1: {
      textAlign: 'center',
      fontSize: theme.fonts.size.h1,
      lineHeight: theme.fonts.lineHeight.h1,
      margin: '0 auto',
      maxWidth: 'unset',
      fontWeight: 600,
    },
  },
  [mediaQueries.md]: {
    alignItems: 'center',
    h1: {
      fontSize: '64px',
      lineHeight: '88px',
    },
    padding: `36px ${theme.spacings.S}`,
  },
});

export const Subtitle = styled.div({
  marginTop: theme.spacings.S,
  color: theme.colors.neutral.N20,
  'p, em, strong': {
    fontFamily: theme.fonts.family.additional,
    textAlign: 'left',
    lineHeight: theme.fonts.lineHeight.p_small,
    fontWeight: '400',
    [mediaQueries.sm]: {
      textAlign: 'center',
    },
  },
});

export const CtaContainer = styled.div({
  height: '40px',
  display: 'table-row',
  width: '100%',
  'a > div': {
    width: '100%',
  },
  'a > div:last-of-type': {
    marginTop: '20px',
  },
  [mediaQueries.sm]: {
    justifyContent: 'center',
    columnGap: theme.spacings.S,
    marginTop: '36px',
    flexDirection: 'row',

    height: '40px',
    display: 'flex',
    'a > div': {
      minWidth: '174px',
    },
    'a > div:last-of-type': {
      marginTop: 0,
    },
  },
});
