interface LinkProps extends React.ComponentProps<'a'> {
  children: React.ReactNode;
  as?: React.ElementType;
}

export const Link = ({ children, as, ...rest }: LinkProps) => {
  if (typeof as === 'string' && as != 'a') {
    throw new Error('Link has a HTML element of `as` instead of `a`');
  }

  const Component = !as ? 'a' : as;
  return <Component {...rest}>{children}</Component>;
};
