import { useEffect } from 'react';
import {
  Carousel,
  CarouselRenderItem,
  IconArrowFAQ,
  IconBannerClose,
} from '@smartproxy-web/ui';
import {
  Arrow,
  BannerContainer,
  BannerWrapper,
  Banners,
  CloseButton,
} from './styledComponents';
import { useState } from 'react';
import { useScroll } from 'hooks/useScrollBlock';
import { ContentItem } from './ContentItem';
import { Swiper as SwiperInstance } from 'swiper/types';
import {
  BANNER_TOP_CLOSED,
  CLOSE_BANNER_SELECTOR,
  DEFAULT_DESKTOP_OFFSET,
  HEADER_HEIGHT_DESKTOP,
  swiperConfig,
} from './constants';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from '../../helpers/browserStorage';
import { ItemProps, PromoBannerProps } from './types';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { useBuildContext } from '../../contexts';

export const PromoBanner = ({ items }: PromoBannerProps) => {
  const [offset, setOffset] = useState<number>(DEFAULT_DESKTOP_OFFSET);
  const [swiperRef, setSwiperRef] = useState<SwiperInstance | null>(null);
  const [isBannerTopOpen, setIsBannerTopOpen] = useState(true);
  const { lang } = useBuildContext();

  const onScroll = () => {
    if (window.scrollY < HEADER_HEIGHT_DESKTOP) {
      setOffset(DEFAULT_DESKTOP_OFFSET);
    } else {
      setOffset(HEADER_HEIGHT_DESKTOP);
    }
  };

  const isMultipleItems = items?.length > 1;

  const handleRightArrowClick = () => {
    swiperRef!.slideNext();
  };

  const handleLeftArrowClick = () => {
    swiperRef!.slidePrev();
  };

  const handleClose = () => {
    setIsBannerTopOpen(false);
    setSessionStorageItem(BANNER_TOP_CLOSED, JSON.stringify(true));
    logPostHogEvent(CLOSE_BANNER_SELECTOR);
  };

  useEffect(() => {
    !!getSessionStorageItem(BANNER_TOP_CLOSED)
      ? setIsBannerTopOpen(false)
      : setIsBannerTopOpen(true);
  }, []);

  useScroll(isBannerTopOpen, onScroll);

  const renderCarouselItem: CarouselRenderItem<ItemProps> = ({
    item,
    index,
  }) => <ContentItem {...item} lang={lang} index={index} />;

  return (
    isBannerTopOpen && (
      <BannerContainer offset={offset}>
        <BannerWrapper>
          {isMultipleItems && (
            <Arrow onClick={handleLeftArrowClick}>
              <IconArrowFAQ />
            </Arrow>
          )}
          <Banners>
            <Carousel
              renderItem={renderCarouselItem}
              items={items}
              config={{
                ...swiperConfig,
                loop: isMultipleItems ? true : false,
                onSwiper: (swiper) => setSwiperRef(swiper),
              }}
              sideGradient={false}
            />
          </Banners>
          {isMultipleItems && (
            <Arrow isRight onClick={handleRightArrowClick}>
              <IconArrowFAQ />
            </Arrow>
          )}
          <CloseButton
            onClick={handleClose}
            data-tracker={CLOSE_BANNER_SELECTOR}
          >
            <IconBannerClose />
          </CloseButton>
        </BannerWrapper>
      </BannerContainer>
    )
  );
};
