export const getSessionStorageItem = (itemName: string) =>
  sessionStorage.getItem(itemName) ?? '';

export const setSessionStorageItem = (itemName: string, itemValue: string) =>
  sessionStorage.setItem(itemName, itemValue);

export const getLocalStorageItem = (itemName: string) =>
  localStorage.getItem(itemName) ?? '';

export const setLocalStorageItem = (itemName: string, itemValue: string) =>
  localStorage.setItem(itemName, itemValue);
