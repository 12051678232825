import {
  BackgroundWrapper,
  CtaContainer,
  HeroContent,
  Subtitle,
} from './styledComponents';
import { HeroProps } from './types';
import { RichText } from '../RichText';
import { LinkButton } from '../LinkButton';
import { SliceHeader } from '../Slice';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { normalizeString } from '@smartproxy-web/shared/utils';
import { logEvent } from '../../helpers';
import { useSliceDetails } from '../../hooks';

export const KnowledgeHubHero = ({
  primary: {
    background,
    title,
    subtitle,
    primary_cta_link: primaryCtaLink,
    primary_cta_label: primaryCtaLabel,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_label: secondaryCtaLabel,
    image,
  },
}: HeroProps) => {
  const { id: sliceId, name: sliceName } = useSliceDetails();

  const buildCtaTrackingSelector = initBuildTrackingId({
    section: sliceName,
    type: 'cta',
  });

  const hasValidCta = !!primaryCtaLink.url || !!secondaryCtaLink.url;

  const handleCtaClick = (label: string) => {
    const elementId = normalizeString(label);

    logEvent({
      eventId: buildCtaTrackingSelector({
        context: elementId,
        type: 'cta',
      }),
      sliceId,
      elementId: `${elementId}-cta`,
    });
  };

  return (
    <BackgroundWrapper background={background} backgroundImage={image.url}>
      <HeroContent>
        <SliceHeader
          title={title}
          sx={{
            marginBottom: 0,
          }}
        />
        <Subtitle>
          <RichText field={subtitle} />
        </Subtitle>
        {hasValidCta && (
          <CtaContainer>
            {!!primaryCtaLabel?.text && !!primaryCtaLink?.url && (
              <LinkButton
                to={primaryCtaLink.url}
                variant="filled"
                color="white"
                data-tracker={buildCtaTrackingSelector({
                  context: normalizeString(primaryCtaLabel.text),
                })}
                onClick={() => handleCtaClick(primaryCtaLabel.text)}
              >
                {primaryCtaLabel.text}
              </LinkButton>
            )}
            {!!secondaryCtaLabel?.text && !!secondaryCtaLink?.url && (
              <LinkButton
                to={secondaryCtaLink.url}
                variant="outlined"
                color="white"
                data-tracker={buildCtaTrackingSelector({
                  context: normalizeString(secondaryCtaLabel.text),
                })}
                onClick={() => handleCtaClick(secondaryCtaLabel.text)}
              >
                {secondaryCtaLabel.text}
              </LinkButton>
            )}
          </CtaContainer>
        )}
      </HeroContent>
    </BackgroundWrapper>
  );
};
