import { BreakpointSize, MediaQueryMode } from './types';

// All breakpoints are defined as mobile-first with min-width queries.
// e.g breakpoints.base = 0 to Infinity, breakpoints.sm = 0 to 30rem, breakpoints.md = 0 to 48rem
export const breakpoints: Record<BreakpointSize, string> = {
  base: '0rem', // 0px
  sm: '30rem', // 480px
  md: '48rem', // 768px
  lg: '64rem', // 1024px
  xl: '75rem', // 1200px
  '2xl': '96rem', // 1536px
} as const;

export const mediaQueryMode: MediaQueryMode = 'min-width';
