import { useState } from 'react';
import {
  StyledTabbedCodeBlockWrapper,
  StyledCodeWindowWrapper,
  StyledCodeAreaWrapper,
  StyledCodeBlockCopyWrapper,
  StyledCodeBlockWrapper,
  lineHeight,
} from './styledComponents';
import { CodeBlockLanguage, TabbedCodeBlockProps } from './types';
import { TabbedCodeBlockHeader } from './TabbedCodeBlockHeader';
import { TabbedCodeBlockLanguages } from './TabbedCodeBlockLanguages';
import {
  Button,
  CodeBlock,
  IconCopyAlt,
  IconDoneCircle,
  theme,
} from '@smartproxy-web/ui';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { useSliceDetails } from '../../hooks';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import {
  getMaxLinesOfCodeCount,
  transformTabbedCodeBlockProps,
} from './helpers';

export const TabbedCodeBlock = ({
  primary,
  items,
  compact = false,
}: TabbedCodeBlockProps & { compact: boolean }) => {
  const { actions, isDarkTheme, languages, showCopyButton, subtitle, title } =
    transformTabbedCodeBlockProps({ primary, items });

  const [activeLanguage, setActiveLanguage] = useState(languages[0]);
  const [showingCopiedFeedback, setShowingCopiedFeedback] = useState(false);

  const maxLOC = getMaxLinesOfCodeCount(
    languages.map((lang) => lang.code.text)
  );
  const maxLOCHeight = maxLOC * lineHeight;

  const { id: sliceId, name: sliceName } = useSliceDetails();
  const createTrackingId = initBuildTrackingId({
    section: sliceName,
  });
  const copyButtonTrackingId = createTrackingId({
    context: 'copy',
    type: 'button',
  });

  const handleLanguageChange = (language: CodeBlockLanguage) => {
    setActiveLanguage(language);
  };

  const handleCopyClick = async () => {
    if (showingCopiedFeedback) return;
    logPostHogEvent(copyButtonTrackingId);
    if (sliceId) {
      logGAElementClickEvent({
        sectionId: sliceId,
        elementId: copyButtonTrackingId,
      });
    }
    setShowingCopiedFeedback(true);
    await navigator.clipboard.writeText(activeLanguage.code.text);
    setTimeout(() => {
      setShowingCopiedFeedback(false);
    }, 2000);
  };

  const hasHeader = !!title || !!subtitle || !!actions.length;
  const hasMultiLanguages = languages.length > 1;

  return (
    <StyledTabbedCodeBlockWrapper compact={compact} isDarkTheme={!!isDarkTheme}>
      <div>
        {hasHeader && (
          <TabbedCodeBlockHeader
            title={title}
            subtitle={subtitle}
            actions={actions}
            buildTrackingId={createTrackingId}
          />
        )}
        {languages.length > 0 && (
          <StyledCodeWindowWrapper>
            {hasMultiLanguages && (
              <TabbedCodeBlockLanguages
                sliceId={sliceId}
                languages={languages}
                activeLanguage={activeLanguage}
                buildTrackingId={createTrackingId}
                handleLanguageChange={handleLanguageChange}
              />
            )}
            <StyledCodeAreaWrapper
              height={maxLOCHeight}
              hasMultiLanguages={hasMultiLanguages}
            >
              <StyledCodeBlockWrapper>
                <CodeBlock code={activeLanguage.code.text} language="tsx" />
              </StyledCodeBlockWrapper>
            </StyledCodeAreaWrapper>
            {showCopyButton && (
              <StyledCodeBlockCopyWrapper>
                <Button
                  size="lg"
                  variant="text"
                  color={'white'}
                  onClick={handleCopyClick}
                  disabled={showingCopiedFeedback}
                  data-tracker={copyButtonTrackingId}
                >
                  {showingCopiedFeedback ? (
                    <IconDoneCircle color={theme.colors.info.I99} />
                  ) : (
                    <IconCopyAlt />
                  )}
                </Button>
              </StyledCodeBlockCopyWrapper>
            )}
          </StyledCodeWindowWrapper>
        )}
      </div>
    </StyledTabbedCodeBlockWrapper>
  );
};

export const MiniTabbedCodeBlock = (props) => (
  <TabbedCodeBlock {...props} compact />
);
