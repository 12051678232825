import { TSHIRT_SIZE } from 'components/constants';
import React from 'react';
import Textarea from 'components/Textarea';
import { TTextareaInput } from './types';
import { TextareaWrapper } from './styledComponents';

const TextareaInput: React.FC<TTextareaInput> = ({
  name,
  label,
  description,
  context: {
    errors,
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
    isSubmitting,
  },
  ...rest
}) => {
  const error = touched[name] && errors[name];
  const handleChange = (value: string) => {
    setFieldValue(name, value, true);
    setFieldError(name, error);
  };

  const handleBlur = () => {
    setFieldTouched(name, true, true);
    setFieldError(name, error);
  };

  return (
    <TextareaWrapper>
      <Textarea
        disabled={isSubmitting}
        value={values[name]}
        height={TSHIRT_SIZE.XXXL}
        label={label}
        error={error}
        onChange={handleChange}
        onBlur={handleBlur}
        description={!error && description}
        {...rest}
      />
    </TextareaWrapper>
  );
};

export default TextareaInput;
