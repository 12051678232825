import { CarouselAutoplay, CarouselFreemode } from '@smartproxy-web/ui';
import { SwiperOptions } from 'swiper/types';

export const swiperConfig = {
  autoplay: {
    delay: 1,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
  slidesPerView: 'auto',
  speed: 5000,
  freeMode: true,
  grabCursor: true,
  centerInsufficientSlides: true,
  modules: [CarouselAutoplay, CarouselFreemode],
} satisfies SwiperOptions;

export const slidesGap = 24;
export const slideWidth = 238;
