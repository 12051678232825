import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import TextContainer from 'components/TextContainer';
import { Title } from 'features/Hero/styledComponents';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const LinksList = styled.ul<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: '100%',
  maxWidth: '1024px',
  gridTemplateColumns: `repeat(4, 1fr)`,
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  listStyle: 'none',
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledTitle = styled(Title)({
  display: CSS_DISPLAY.FLEX,
});

export const StyledSubTitle = styled(TextContainer)<ITheme>((props) => ({
  margin: 0,
  p: {
    fontSize: '20px',
    color: getThemeMainColor(props, MAIN_COLOR.WHITE),
  },
}));

export const StyledTextContainer = styled(TextContainer)({
  margin: 0,
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    flexWrap: CSS_FLEX_WRAP.WRAP,
  },
  'p:first-of-type': {
    fontWeight: 700,
  },
});
