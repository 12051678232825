import React from 'react';
import {
  Box,
  BoxResponsiveStylesProps,
  PrismicTextField,
  theme,
} from '@smartproxy-web/ui';
import { RichText } from '../RichText';

interface SliceHeaderProps {
  title?: PrismicTextField;
  description?: PrismicTextField;
  align?: 'center' | 'left';
  bottomItems?: React.ReactNode;
  sx?: BoxResponsiveStylesProps;
  h?: 'h2' | 'h3';
  isDark?: boolean;
}

export const SliceHeader: React.FC<SliceHeaderProps> = ({
  title,
  description,
  align,
  bottomItems,
  sx,
  isDark,
}) => {
  if (!title?.text && !description?.text && !bottomItems) return null;

  return (
    <Box
      data-slice-header
      as="header"
      sx={{
        color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
        textAlign: { base: 'left', lg: align || 'center' },
        marginBottom: { base: '32px', md: '60px', lg: '80px' },
        display: 'flex',
        flexDirection: 'column',
        width: {
          base: '100%',
          lg: align === 'left' ? '600px' : '100%',
        },
        alignItems: {
          base: 'flex-start',
          md: align === 'left' ? 'flex-start' : 'center',
        },
        ...sx,
      }}
    >
      {title?.text && <RichText field={title} />}
      {description?.text && (
        <Box
          sx={{
            color: isDark ? theme.colors.neutral.N20 : theme.colors.neutral.N80,
            marginTop: title ? { base: '20px', lg: '24px' } : '0px',
            textAlign: { base: 'left', lg: align || 'center' },
          }}
        >
          <RichText field={description} />
        </Box>
      )}
      {bottomItems && (
        <Box
          data-slice-bottom-items
          sx={{
            marginTop: { base: '32px', md: '36px' },
            display: 'flex',
            flexDirection: 'column',
            gap: '36px',
            width: '100%',
            alignItems: {
              base: 'flex-start',
              md: align === 'left' ? 'flex-start' : 'center',
            },
          }}
        >
          {bottomItems}
        </Box>
      )}
    </Box>
  );
};
