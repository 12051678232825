import styled from '@emotion/styled';
import { theme } from '../../theme';

export const StyledDivider = styled.div({
  height: '1px',
  width: '100%',
  padding: '0 20px',
  background: theme.colors.neutral.N99,
});

export const DividerLine = styled.span({
  height: '100%',
  width: '100%',
  background: theme.colors.neutral.N80,
  maxWidth: '1024px',
  display: 'block',
  margin: '0 auto',
});
