import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../../constants';
import { StyledNavMenuList } from '../styledComponents';

export const StyledBottomNavMenuList = styled(StyledNavMenuList)({
  display: 'none',
  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'block',
    paddingBottom: '12px',
    paddingTop: '12px',
    backgroundColor: themev2.colors.gray.S900,
  },
});
