interface BuildSocialShareLinkParams {
  url: string;
  title: string;
}

export const buildShareLinks = ({ url, title }: BuildSocialShareLinkParams) => {
  return {
    linkedin: `https://www.linkedin.com/shareArticle?title=${title}&url=${url}`,
    twitter: `https://twitter.com/share?text=${title}&url=${url}`,
    facebook: `https://www.facebook.com/sharer.php?u=${url}`,
  };
};
