/**
 * Strips out the `http://`, `http://www.`, `https://`, `https://www.`
 * from any url string and returns the remainder
 * @param urlString
 * @returns {string}
 */
export const stripUrlProtocol = (urlString: string) => {
  const urlRegex = /^(https?:\/\/)?(www.)?/i;
  return urlString.replace(urlRegex, '');
};
