export enum TEXT_ALIGN {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export enum TSHIRT_SIZE {
  XXXL = 'xxxl',
  XXL = 'xxl',
  XL = 'xl',
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs',
}

export enum TEXT_TYPE {
  LARGE = 'large',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P1 = 'p1',
  P2 = 'p2',
  SMALL = 'small',
}

export enum INPUT_TYPES {
  PASSWORD = 'password',
  TEXT = 'text',
  NUMBER = 'number',
}

export enum ICONS {
  ARROW_LEFT = 'arrow-left',
  USER_CHECK = 'user-check',
  CHECK_CIRCLE = 'check-circle',
  CLOSE_CIRCLE = 'close-circle',
  USER = 'user',
  CHECK = 'check',
  LOADING = 'loading',
}

export enum TARGET {
  BLANK = '_blank',
  SELF = '_self',
}

export enum INPUT_TYPE {
  SUBMIT = 'submit',
}

export enum DOM_STATE {
  ACTIVE = ':active',
  FOCUS = ':focus',
  HOVER = ':hover',
  HOVER_ACTIVE = ':hover, :active',
  HOVER_ACTIVE_FOCUS = ':hover, :active, :focus',
  VISITED = ':visited',
}
