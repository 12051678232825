import { BottomTextContainer, StyledBottomText } from '../styledComponents';
import { RichText } from '../../RichText';
import { GatsbyImg } from '@smartproxy-web/ui';
import { BottomTextProps } from '../types';

export const BottomText = ({
  bottomLogo,
  bottomText,
  isDark,
}: BottomTextProps) => {
  return (
    <BottomTextContainer>
      {!!bottomLogo && <GatsbyImg image={bottomLogo} />}
      <StyledBottomText isDark={isDark}>
        <RichText field={bottomText} />
      </StyledBottomText>
    </BottomTextContainer>
  );
};
