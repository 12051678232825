import { PrismicTextField, TGatsbyImage } from '@smartproxy-web/ui';
import { TextGrid, TextGridItemProps, TextGridProps } from '.';

interface PrismicTextGridProps {
  primary: {
    title?: PrismicTextField;
    subtitle?: PrismicTextField;
    is_dark_theme: boolean;
    is_header_centered: boolean;
    is_subtitle_centered: boolean;
    background: string;
  };
  items: PrismicTextGridItemProps[];
}

interface PrismicTextGridItemProps {
  icon?: TGatsbyImage;
  title?: PrismicTextField;
  description?: PrismicTextField;
}

export const PrismicTextGrid = (props: PrismicTextGridProps) => {
  return <TextGrid {...transformPrismicTextGridProps(props)} />;
};

const transformPrismicTextGridProps = (
  prismicData: PrismicTextGridProps
): TextGridProps => {
  const isValidTextGridItem = (
    item: PrismicTextGridItemProps
  ): item is TextGridItemProps => !!item.title && !!item.description;

  return {
    title: prismicData.primary.title,
    background: prismicData.primary.background,
    subtitle: prismicData.primary.subtitle,
    isDarkTheme: prismicData.primary.is_dark_theme,
    isHeaderCentered:
      prismicData.primary.is_header_centered ||
      prismicData.primary.is_subtitle_centered ||
      false,
    items: prismicData.items.filter(isValidTextGridItem),
  };
};
