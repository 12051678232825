export const slugify = (title?: string) => {
  if (!title) return '';

  const initialNumberingRegex = /^([0-9]\.\s?)+/g;
  const spacesRegex = /\/|\s+/g;
  const punctuationRegex = /[^a-zA-Z0-9\u4e00-\u9fa5-]+/g;

  return title
    .toLowerCase()
    .trim()
    .replaceAll(initialNumberingRegex, '')
    .replaceAll(spacesRegex, '-')
    .replaceAll('&', 'and')
    .replaceAll(punctuationRegex, '');
};
