import { MouseEvent, useRef } from 'react';
import {
  SelectedTabBackground,
  Tab,
  ToggleButtonContainer,
  tabContainerPadding,
} from './styledComponents';
import { ThemeColor } from '../../theme';
import { BuildTrackingIdParams } from '@smartproxy-web/shared/helpers';

export interface ToggleButtonProps {
  options: string[];
  selectedIndex: number;
  onChange: (event: MouseEvent<HTMLDivElement>, index: number) => void;
  color?: ThemeColor;
  createTrackingId?: (props?: BuildTrackingIdParams) => string;
}

export const ToggleButton = ({
  options,
  selectedIndex,
  onChange,
  color,
  createTrackingId,
}: ToggleButtonProps) => {
  const tabsRef = useRef<Map<number, HTMLDivElement>>(new Map());

  const getActiveItemDimensions = (element: HTMLElement) => ({
    x: element.offsetLeft - tabContainerPadding,
    width: element.offsetWidth,
  });

  const selectedTabEl = tabsRef.current.get(selectedIndex);
  const selectedTabDimensions = selectedTabEl
    ? getActiveItemDimensions(selectedTabEl)
    : {
        x: tabContainerPadding / 2,
        width: 0,
      };

  return (
    <ToggleButtonContainer color={color}>
      {options.map((option, i) => (
        <Tab
          ref={(node) => {
            const map = tabsRef.current;
            if (node) {
              map.set(i, node);
            } else {
              map.delete(i);
            }
          }}
          key={option}
          data-tracker={
            !!createTrackingId &&
            createTrackingId({ context: `toggle-${option}`, type: 'trigger' })
          }
          isActive={selectedIndex === i}
          onClick={(e) => onChange(e, i)}
          color={color}
        >
          {option}
        </Tab>
      ))}
      <SelectedTabBackground
        color={color}
        sx={{
          width: selectedTabDimensions.width,
          transform: `translateX(${selectedTabDimensions.x}px)`,
        }}
      />
    </ToggleButtonContainer>
  );
};
