import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { CSS_DISPLAY, getThemeBorderColor, COLOR_SHADE, ITheme } from 'theme';
import React from 'react';

const spinAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const Loader = styled.div<{ color?: string; size: SIZE } & ITheme>(
  ({ color, size, ...props }) => {
    const borderColor = color || 'transparent';
    return {
      display: CSS_DISPLAY.INLINE_BLOCK,
      width: `${size}px`,
      height: `${size}px`,
      '&:after': {
        content: '" "',
        display: CSS_DISPLAY.BLOCK,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        border: `2px solid ${getThemeBorderColor(props, COLOR_SHADE.WHITE)}`,
        borderBottomColor: borderColor,
        animation: `${spinAnimation} 1.2s linear infinite`,
      },
    };
  }
);

export enum SIZE {
  SMALL = 24,
  BIG = 80,
}

interface ISpinnerProps {
  size?: number;
  color?: string;
}

const Spinner: React.FC<ISpinnerProps> = ({ size = SIZE.SMALL, color }) => (
  <Loader color={color} size={size} />
);

export default Spinner;
