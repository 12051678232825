import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';
import { Slice } from '../Slice';

export const QuoteComponentWrapper = styled(Slice)(({ backgroundColor }) => ({
  backgroundColor,
}));

export const QuoteContent = styled.div({
  padding: '36px',
  alignItems: 'flex-start',
  width: 'auto',
  borderRadius: '12px',
  background: theme.colors.neutral.N04,
  gap: '24px',
  display: 'flex',
  'p, em, strong': {
    fontFamily: theme.fonts.family.additional,
    fontSize: theme.fonts.size.h4,
    color: theme.colors.neutral.N99,
    textAlign: 'left',
    lineHeight: theme.fonts.lineHeight.p_small,
    fontWeight: '700',
  },

  svg: {
    width: '48px',
    height: '48px',
    flexShrink: 0,
    verticalAlign: 'top',
  },

  [mediaQueries.sm]: {
    alignItems: 'center',
  },
  [mediaQueries.md]: {
    alignItems: 'center',
    padding: `36px ${theme.spacings.S}`,
  },
});
