import { graphql, useStaticQuery } from 'gatsby';

export const query = () =>
  useStaticQuery(graphql`
    query BannerTop {
      prismicBannerTop {
        data {
          background
          is_background_gradient
          cta_label {
            richText
            text
          }
          cta_link {
            url
          }
          icon {
            alt
            gatsbyImageData
          }
          info {
            richText
            text
          }
          is_background_dark
          show_in_all_pages
          is_hidden_from_paths
          title {
            richText
            text
          }
          body {
            ... on PrismicBannerTopDataBodyPageList {
              id
              slice_type
              items {
                documents {
                  type
                  tags
                }
              }
            }
          }
        }
      }
    }
  `);
