import spTracker, { track } from '@smartproxy/sp-tracker-js';

declare const window: Window &
  typeof globalThis & {
    dataLayer?: unknown[];
  };

const windowWithDataLayer = (() => {
  if (typeof window === 'undefined') return [];
  window.dataLayer = window.dataLayer ?? [];
  return window.dataLayer;
})();

export const logGAElementClickEvent = ({
  elementId,
  sectionId,
  elementGroup,
  elementSubGroup,
}: {
  elementId: string;
  sectionId: string;
  elementGroup?: string;
  elementSubGroup?: string;
}) => {
  windowWithDataLayer.push({
    event: 'element_click',
    clicked_element_data: [
      {
        element_identifier: elementId,
        ...(!!sectionId && { element_section: sectionId }),
        ...(!!elementGroup && { element_group: elementGroup }),
        ...(!!elementSubGroup && { element_subgroup: elementSubGroup }),
      },
    ],
  });
};

export const initPosthog = (projectName: string) => {
  spTracker.init({
    project: projectName,
    isProduction: process.env.GATSBY_NODE_ENV === 'production',
  });
};

export const logPostHogEvent = (eventName: string) => track(eventName);
