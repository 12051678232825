import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { themev2 } from 'theme-v2';

export const StyledDropdownArrow = styled.div({
  borderRight: `0.5px solid ${themev2.colors.gray.S500}`,
  borderBottom: `0.5px solid ${themev2.colors.gray.S500}`,
  height: 10,
  width: 10,
  transform: 'translateY(-4px) rotate(45deg)',
  transformOrigin: 'center',
  backgroundColor: '#0D0F10',
});

export const StyledLanguageDropdownContents = styled(DropdownMenu.Content)({
  backgroundColor: '#0D0F10',
  border: `1px solid rgba(149, 149, 149, 0.4)`,
  backdropFilter: 'blur(2px)',
  padding: '12px',
  borderRadius: '6px',
  width: '144px',
  zIndex: 3,
});

export const StyledLanguageDropdownItem = styled(DropdownMenu.Item)({
  ':focus-visible': {
    outline: 'none',
  },

  ':focus-visible a, a:focus a:hover': {
    '&[data-active=true]': {
      backgroundColor: '#FFFFFF0A',
    },
  },

  ':focus-visible a, a:focus': {
    '&[data-active=true]': {
      color: themev2.colors.white.default,
    },
  },

  a: {
    color: themev2.colors.gray.S500,
    fontSize: themev2.fonts.size.p_small,
    padding: '6px 12px',
    display: 'block',
    transition: '0.3s ease-in-out',

    '&[data-active=false]': {
      cursor: 'default',
      color: themev2.colors.blue.default,
    },

    '&[data-active=true]:hover': {
      color: themev2.colors.gray.S400,
    },
  },
});
