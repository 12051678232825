import { TSHIRT_SIZE } from 'components/constants';
import React, { ChangeEvent } from 'react';
import {
  Closeicon,
  InputContainer,
  IconContainer,
  SearchIcon,
  StyledInput,
} from './styledComponents';
import { IInput } from './types';

const Input: React.FC<IInput> = ({
  inputValue,
  placeholder,
  onInputChange,
  isListVisible,
  onFocus,
  onBlur,
  onClear,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    onInputChange(e.target.value);
  };
  const isListOpen = inputValue.length >= 2 && isListVisible;

  return (
    <InputContainer>
      <StyledInput
        width={TSHIRT_SIZE.S}
        value={inputValue}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
      <IconContainer onMouseDown={onClear}>
        <Closeicon isVisible={isListOpen} />
        <SearchIcon isVisible={!isListOpen} />
      </IconContainer>
    </InputContainer>
  );
};

export default Input;
