import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const LogosContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  columnGap: '36px',
  width: '100%',
  padding: '0 20px 32px',
  '.swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },
  [mediaQueries.sm]: {
    padding: '0 40px 36px',
  },
  [mediaQueries.md]: {
    padding: '60px 0',
    maxWidth: '1440px',
  },
});

export const Text = styled.p({
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontFamily: theme.fonts.family.additional,
  marginBottom: '36px',
  [mediaQueries.md]: {
    textAlign: 'center',
  },
});
