import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { PrismicTextField, TGatsbyImage } from 'types';
import { SmallImageWrapper } from './styledComponents';
import { RichText } from 'components/v2/RichText';
import { IconDonev2 } from 'images';
import { theme } from 'theme-v2/theme';

interface IItem {
  image?: TGatsbyImage;
  imageDescription?: PrismicTextField;
}
const RepeatableZoneItem: React.FC<IItem> = ({ image, imageDescription }) => {
  if (!imageDescription?.text) return null;

  return (
    <>
      {!!image?.url ? (
        <SmallImageWrapper>
          <GatsbyImg image={image} />
        </SmallImageWrapper>
      ) : (
        <IconDonev2 color={theme.colors.blue.default} />
      )}
      {imageDescription && <RichText field={imageDescription} />}
    </>
  );
};

export default RepeatableZoneItem;
