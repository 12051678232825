import { Product } from 'constants/products';
import { IDocument } from './types';
import { normalizeString } from '@smartproxy-web/shared/utils';

export const excludePricingFeatures = (pricingItem: IDocument) => {
  return pricingItem.pricing_card.type !== 'pricing_cards_features';
};

export const getProductType = (pricingItems: IDocument[]): Product | string => {
  const initialProduct = pricingItems[0].pricing_card.document.data.product;

  for (let i = 0; i < pricingItems.length; i++) {
    const itemProduct = pricingItems[i].pricing_card.document.data.product;
    if (itemProduct && initialProduct !== itemProduct) {
      return 'mixed';
    }
  }

  return normalizeString(initialProduct);
};
