interface IFontSizes {
  /**
   * Large font size
   * font-size: 96px
   * line-height: 144px;
   * */
  large: string;
  /**
   * H1 font size
   * font-size: 36px
   * line-height: 54px;
   * */
  h1: string;
  /**
   * H2 font size
   * font-size: 32px
   * line-height: 48px;
   * */
  h2: string;
  /**
   * H3 font size
   * font-size: 24px
   * line-height: 36px;
   * */
  h3: string;
  /**
   * H4 font size
   * font-size: 20px
   * line-height: 26px;
   * */
  h4: string;
  /**
   * H5 font size
   * font-size: 18px
   * line-height: 23px;
   * */
  h5: string;
  /**
   * Paragraph extra large font size
   * font-size: 20px
   * line-height: 26px;
   * */
  p_xlarge: string;
  /**
   * Paragraph large font size
   * font-size: 18px
   * line-height: 27px;
   * */
  p_large: string;
  /**
   * Paragraph medium font size
   * font-size: 16px
   * line-height: 24px;
   * */
  p_medium: string;
  /**
   * Paragraph small font size
   * font-size: 14px
   * line-height: 21px;
   * */
  p_small: string;
  /**
   * Paragraph extra small font size
   * font-size: 12px
   * line-height: 18px;
   * */
  p_xsmall: string;
}

interface IFontFamily {
  /**
   * Main font family
   * font-size: 'Poppins, sans-serif'
   * */
  main: string;
  /**
   * Monospace font family
   * font-size: 'Fira Code
   * */
  mono: string;
  /**
   * Additional font family
   * font-size: 'DM Sans'
   * */
  additional: string;
}

export interface ThemeFonts {
  size: IFontSizes;
  lineHeight: IFontSizes;
  family: IFontFamily;
}

export const bodyFontSize = 16;

export const fonts: ThemeFonts = {
  family: {
    main: 'Poppins, sans-serif',
    mono: 'Fira Code, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
    additional: 'DM Sans',
  },
  size: {
    large: '96px',
    h1: '36px',
    h2: '32px',
    h3: '24px',
    h4: '20px',
    h5: '18px',
    p_xlarge: '20px',
    p_large: '18px',
    p_medium: `${bodyFontSize}px`,
    p_small: '14px',
    p_xsmall: '12px',
  },
  lineHeight: {
    large: '144px',
    h1: '54px',
    h2: '48px',
    h3: '36px',
    h4: '26px',
    h5: '23px',
    p_xlarge: '26px',
    p_large: '27px',
    p_medium: '24px',
    p_small: '21px',
    p_xsmall: '18px',
  },
};
