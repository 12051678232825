import styled from '@emotion/styled';
import { theme } from 'theme-v2/theme';
import { MEDIA_QUERIES } from 'globalStyles';

type FeaturesProps = {
  title: string;
  checkmark: React.ReactNode;
  features: { feature: string }[];
  inRow: boolean;
};

// TODO refactor when moving to nextjs
export default function Features({
  title,
  checkmark,
  features,
  inRow,
}: FeaturesProps) {
  return (
    <FeaturesContainer inRow={inRow}>
      <FeatureTitle>{title}</FeatureTitle>
      <FeaturesList inRow={inRow}>
        {features.map(({ feature }) => (
          <Feature key={feature}>
            <div>{checkmark}</div>
            <p>{feature}</p>
          </Feature>
        ))}
      </FeaturesList>
    </FeaturesContainer>
  );
}

export const FeaturesContainer = styled.div<{ inRow: boolean }>(
  ({ inRow }) => ({
    display: 'flex',
    flexDirection: 'column',
    order: 1,
    maxWidth: '910px',
    margin: '0 auto',

    paddingBottom: inRow ? 0 : '36px',
    width: inRow ? '100vw' : '300px',
    padding: '2.25rem 0',

    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      width: '300px',
    },
  })
);

export const FeaturesList = styled.div<{ inRow: boolean }>(({ inRow }) => ({
  display: 'flex',
  flexDirection: inRow ? 'row' : 'column',
  justifyContent: 'flex-start',
  rowGap: theme.spacings.XS,
  columnGap: '16px',
  flexWrap: 'wrap',
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: 'column',
  },
}));

export const FeatureTitle = styled.p({
  fontSize: theme.fonts.size.p_medium,
  color: theme.colors.black.S900,
  fontWeight: 500,
  marginBottom: theme.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    fontSize: theme.fonts.size.h4,
    lineHeight: theme.fonts.lineHeight.h4,
    fontWeight: 400,
    textAlign: 'left',
  },
});

export const Feature = styled.div({
  display: 'flex',
  color: theme.colors.black.S900,
  fontSize: theme.fonts.size.p_small,
  p: {
    marginLeft: theme.spacings.XS,
  },
  div: {
    width: '21px',
    height: '21px',
    img: {
      width: '21px',
      height: '21px',
    },
  },
});
