import { useState } from 'react';
import { logGAElementClickEvent } from '@smartproxy-web/analytics';
import { useBuildContext } from '../contexts';
import { useSliceDetails } from './useSliceDetails';
import { translations } from '../components/BlogPostIntro/constants';

export const useShowMoreLess = () => {
  const { lang } = useBuildContext();
  const { id: sliceId } = useSliceDetails();
  const [showAll, setShowAll] = useState(false);
  const [buttonText, setButtonText] = useState<string>(
    translations.show_more[lang]
  );

  const handleShowMoreLess = () => {
    setShowAll(!showAll);
    const text = showAll
      ? translations.show_more[lang]
      : translations.show_less[lang];
    setButtonText(text);

    if (sliceId) {
      logGAElementClickEvent({
        elementId: buttonText,
        sectionId: sliceId,
      });
    }
  };

  return { showAll, handleShowMoreLess, buttonText };
};
