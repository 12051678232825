import {
  Carousel,
  CarouselRenderItem,
  PrismicTextField,
  ToggleButton,
} from '@smartproxy-web/ui';
import { SliceHeader } from '../Slice';
import { useState } from 'react';
import { LinkButton } from '../LinkButton';
import { StyledCarouselBlock, StyledCarouselCta } from './styledComponents';
import { useSliceDetails } from '../../hooks';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { slideWidth, slidesGap, swiperConfig } from './carouselConfig';
import { CarouselBlockCard, CarouselCardProps } from './CarouselBlockCard';
import { logEvent } from '../../helpers';
import { translations } from '../BlogPostIntro/constants';
import { useBuildContext } from '../../contexts';

export interface CarouselCategory {
  url?: string;
  label: string;
}

export interface CarouselProps {
  title?: PrismicTextField;
  subtitle?: PrismicTextField;
  categories: CarouselCategory[];
  items: CarouselCardProps[][];
  shouldShowCtaBtn?: boolean;
  shouldShowTabs?: boolean;
  isDark: boolean;
}

export const CarouselBlock = ({
  title,
  subtitle,
  categories,
  items,
  shouldShowCtaBtn,
  shouldShowTabs,
  isDark,
}: CarouselProps) => {
  const { lang } = useBuildContext();
  const { id: sliceId, name: sliceName } = useSliceDetails();
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

  const activeCategory = categories[activeCategoryIndex];
  const categoryItems = items[activeCategoryIndex];
  const areTabsVisible =
    shouldShowTabs && !!categories && categories.length >= 2;
  const isCarouselVisible = !!categoryItems && categoryItems.length > 0;

  const createTrackingId = initBuildTrackingId({
    section: sliceName,
  });

  const handleToggleChange = (_, index) => {
    setActiveCategoryIndex(index);

    const nextActiveCategoryLabel = categories[index].label;
    const nextActiveCategoryId = createTrackingId({
      context: nextActiveCategoryLabel,
      type: 'trigger',
    });
    logEvent({
      sliceId,
      elementId: nextActiveCategoryLabel,
      eventId: nextActiveCategoryId,
    });
  };

  const handleCardClick = (title: string) => {
    const nextActiveCategoryId = createTrackingId({
      context: title,
      type: 'trigger',
    });
    logEvent({
      sliceId,
      elementId: title,
      eventId: nextActiveCategoryId,
      elementGroup: activeCategory.label,
    });
  };

  const ctaColor = isDark ? 'white' : 'black';
  const toggleColor = isDark ? 'gradient' : 'white';

  const renderCarouselItem: CarouselRenderItem<CarouselCardProps> = ({
    item,
  }) => (
    <CarouselBlockCard
      item={item}
      trackingId={createTrackingId({
        context: `${areTabsVisible ? activeCategory.label : ''} ${item.title}`,
        type: 'link',
      })}
      handleClick={() => handleCardClick(item.title)}
      isDark={isDark}
    />
  );

  return (
    <StyledCarouselBlock isDark={isDark} data-section-id={sliceName}>
      <SliceHeader
        isDark={isDark}
        title={title}
        description={subtitle}
        bottomItems={
          areTabsVisible && (
            <ToggleButton
              color={toggleColor}
              options={categories.map((category) => category.label)}
              selectedIndex={activeCategoryIndex}
              onChange={handleToggleChange}
              createTrackingId={createTrackingId}
            />
          )
        }
      />
      {isCarouselVisible && (
        <Carousel
          key={activeCategoryIndex}
          config={{
            ...swiperConfig,
            loop: categoryItems.length > 4,
            slideWidth,
            spaceBetween: slidesGap,
          }}
          items={categoryItems}
          renderItem={renderCarouselItem}
          isDark={isDark}
        />
      )}
      {shouldShowCtaBtn && !!activeCategory?.url && (
        <StyledCarouselCta>
          <LinkButton
            to={activeCategory.url}
            variant="outlined"
            color={ctaColor}
            data-tracker={createTrackingId({
              context: activeCategory.label,
              type: 'cta',
            })}
            onClick={() => {
              const trackingId = createTrackingId({
                context: activeCategory.label,
                type: 'cta',
              });
              logEvent({
                sliceId,
                eventId: trackingId,
                elementId: `${translations.see_all[lang]} activeCategory.label`,
                elementGroup: activeCategory.label,
              });
            }}
          >
            {translations.see_all[lang]} {''}
            {activeCategory?.label.toLowerCase()}
          </LinkButton>
        </StyledCarouselCta>
      )}
    </StyledCarouselBlock>
  );
};
