import styled from '@emotion/styled';
import { GatsbyLink } from '../GatsbyLink';
import { getVariantStyles, StyledButtonProps } from '@smartproxy-web/ui';

export const StyledLinkButton = styled(GatsbyLink, {
  shouldForwardProp: (prop) => prop !== 'ctaAction',
})<StyledButtonProps>(({ disabled, variant, color }) => {
  const variantStyles = getVariantStyles({ variant, color });

  return {
    ':hover > div': {
      ...(!disabled && variantStyles.hover),
    },
    ':focus > div': {
      outlineWidth: 0,
      ...(!disabled && variantStyles.hover),
    },

    ':disabled > div': {
      cursor: 'not-allowed',
      ...(disabled && { opacity: 0.6 }),
    },
  };
});

export const StyledButtonInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '> svg': {
    height: '16px',
    width: '16px',
  },
  '> div': {
    height: '16px',
    width: '16px',
  },
});
