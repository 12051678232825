import React from 'react';
import { IOption } from './types';

export const getLabelByValue = (
  options: IOption[],
  value?: React.ReactText
) => {
  if (!options.length || !value) {
    return '';
  }

  return (
    options.find(
      (option: IOption) =>
        option.value.toString().toLowerCase() === value.toString().toLowerCase()
    )?.label || ''
  );
};

export const filterOptionsByInputValue = (options: IOption[], value: string) =>
  options.filter((option: IOption) =>
    option.label.toLowerCase().includes(value.toLowerCase())
  );
