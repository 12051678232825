import { BlogPostIntroProps } from '../components';
import { DefaultSlice } from '../types';

export const getAuthor = (slices: DefaultSlice[]) => {
  const authorSlice = slices.find(
    (template) =>
      template.slice_type === 'blog_author' ||
      template.slice_type === 'author_v2'
  );

  if (!authorSlice) return;
  const data = authorSlice.primary;
  if (!data.blog_author?.document) return;

  return {
    image: data.blog_author.document?.data.authors_image,
    name: data.blog_author.document?.data.full_name.text,
  };
};

export const getTopics = (slices: DefaultSlice[]) => {
  const topicsSlice = slices.find(
    (template) => template.slice_type === 'social_buttons_bar'
  );
  if (!topicsSlice) return;

  const data = topicsSlice;

  if (!data?.items) return;

  return data.items.map((item) => ({
    label: item.topic.document?.data.topic_label,
    url: `/${item.topic.document?.tags.join('/')}`,
  }));
};

export const transformBlogPostData = (blogData) => {
  const now = new Date();
  const newUntil = blogData.data.new_until
    ? new Date(blogData.data.new_until)
    : now;

  const topics = getTopics(blogData.data.body);
  const author = getAuthor(blogData.data.body);

  return {
    isNew: newUntil > now,
    topics,
    title: blogData.data.title.text,
    excerpt: blogData.data.description,
    author,
    publishDate: blogData.data.release_date ?? blogData.first_publication_date,
    readingTime: blogData.data.time_spent_reading,
    heroImage: blogData.data.article_image,
    heroVideo: blogData.data.article_video,
    isV2: blogData.data.isV2,
  } satisfies BlogPostIntroProps;
};
