import { slugify } from '../utils';

export type TrackingTypes =
  | 'button'
  | 'cta'
  | 'field'
  | 'link'
  | 'trigger'
  | 'video'
  | 'image';

export interface BuildTrackingIdParams {
  prefix?: string;
  page?: string;
  section?: string;
  context?: string;
  type?: TrackingTypes;
}

export const buildTrackingId = (props: BuildTrackingIdParams) => {
  const {
    prefix = 'x',
    page = '',
    section = '',
    context = '',
    type = '',
  } = props;

  return [prefix, page, section, context, type]
    .filter(Boolean)
    .map(slugify)
    .join('-');
};

export const initBuildTrackingId = (defaultProps: BuildTrackingIdParams) => {
  return (props?: BuildTrackingIdParams) =>
    buildTrackingId({
      ...defaultProps,
      ...props,
    });
};
