import { BoxProps } from './types';
import { StyledBox } from './styledComponents';

export const Box = ({
  as = 'div',
  className,
  children,
  sx,
  ...rest
}: BoxProps) => (
  <StyledBox as={as} className={className} boxStyles={sx} {...rest}>
    {children}
  </StyledBox>
);
