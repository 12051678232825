import Modal from 'features/v2/ImageTextSlice/Modal';
import { enhanceWithLazyLoading } from 'helpers/lazyLoadHelper';
import { IconCloseWhite } from 'images';
import React from 'react';
import { StyledIconCloseWrapper } from './styledComponents';
import { IVideoModal } from './types';

const VideoModal: React.FC<IVideoModal> = ({ video, onClose, isVisible }) => (
  <Modal isVisible={isVisible} onClick={() => onClose(false)}>
    <div
      dangerouslySetInnerHTML={{
        __html: enhanceWithLazyLoading(video as string),
      }}
    />
    <StyledIconCloseWrapper>
      <IconCloseWhite />
    </StyledIconCloseWrapper>
  </Modal>
);

export default VideoModal;
