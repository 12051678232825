import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const StyledCountdownTimer = styled.div({
  padding: `2px ${theme.spacings.XS}`,
  width: 'fit-content',
  alignItems: 'center',
  borderRadius: '24px',
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  color: theme.colors.neutral.N99,
  fontWeight: 500,
  display: 'flex',
  gap: '0 4px',
  position: 'relative',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'center',

  span: {
    fontFamily: theme.fonts.family.additional,
    letterSpacing: 1.2,
  },
  small: {
    fontWeight: 400,
    fontFamily: theme.fonts.family.additional,
  },

  '.hide-on-mobile': {
    display: 'none',
  },
  [mediaQueries.md]: {
    textAlign: 'left',
    flexDirection: 'row',
    justifyContent: 'flex-start',

    '.hide-on-mobile': {
      display: 'block',
    },
  },
});

export const StyledCountdownTimeComponentsWrapper = styled.span({
  display: 'flex',
  gap: '12px',
  justifyContent: 'center',
  span: {
    fontWeight: 700,
  },
});

export const StyledCountdownTimeWrapper = styled.span({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',

  '[data-hidden=true]': {
    visibility: 'hidden',
  },
  '[data-hidden=false]': {
    position: 'absolute',
  },
});

export const StyledCountdownTimeComponent = styled.span({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  flexDirection: 'column',
  justifyContent: 'center',

  span: {
    fontVariantNumeric: 'tabular-nums',
    fontWeight: 700,
    fontSize: theme.fonts.size.p_small,
    lineHeight: '20px',
  },
  small: {
    fontWeight: '300',
  },
});
