import styled from '@emotion/styled';
import { theme } from '@smartproxy-web/ui';
import { MEDIA_QUERIES } from 'globalStyles';
import React, { forwardRef } from 'react';

const Wrapper = styled.section<{ isDark?: boolean; backgroundColor?: string }>(
  ({ isDark, backgroundColor }) => ({
    width: '100%',
    padding: '80px 40px',
    backgroundColor: isDark
      ? theme.colors.neutral.N99
      : backgroundColor || theme.colors.neutral.N00,
    [MEDIA_QUERIES.TABLET]: {
      padding: '60px 40px',
    },

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      padding: '32px 20px',
    },
  })
);

const Content = styled.div({
  width: '100%',
  maxWidth: '1120px',
  margin: '0 auto',
});

export interface SliceProps {
  children: React.ReactNode;
  id?: string;
  isDark?: boolean;
  backgroundColor?: string;
  className?: string;
}

export const Slice = forwardRef<HTMLDivElement, SliceProps>(
  ({ id, children, isDark, backgroundColor, className }, ref) => {
    return (
      <Wrapper
        ref={ref}
        id={id}
        data-section-id={id}
        isDark={isDark}
        backgroundColor={backgroundColor}
        className={className}
      >
        <Content>{children}</Content>
      </Wrapper>
    );
  }
);
