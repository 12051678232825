import styled from '@emotion/styled';
import { theme } from '@smartproxy-web/ui';

export const Card = styled.div({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: '1.5rem',
  padding: '1.5rem',
  height: '100%',
  flexBasis: '0px',
});

export const TitleWrapper = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  marginBottom: '0.75rem',

  strong: {
    color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  },
}));

export const DescriptionWrapper = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',

    p: {
      color: isDark ? theme.colors.neutral.N40 : theme.colors.neutral.N80,
    },
  })
);
