import styled from '@emotion/styled';
import { Slice } from '../Slice';
import { Badge, mediaQueries, theme } from '@smartproxy-web/ui';
import { keyframes } from '@emotion/react';

const FadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});
export const StyledSlice = styled(Slice)({
  paddingTop: theme.spacings.S,
  paddingBottom: theme.spacings.S,
  maxWidth: '1024px',
  display: 'flex',
  paddingLeft: 0,
  paddingRight: 0,
  width: '80%',
});

export const ImageContainer = styled.div({
  borderRadius: '12px',
  maxWidth: '700px',
  boxShadow: '0px 4px 8px rgba(29, 29, 31, 0.1)',
  marginBottom: '24px',
  border: `1px solid ${theme.colors.neutral.N04}`,
  img: {
    borderRadius: '12px',
  },
  [mediaQueries.lg]: {
    height: '548px',
    maxWidth: '900px',
  },
});

const pseudoGradientBorderStyles = {
  position: 'absolute',
  content: '""',
  inset: 0,
  background: theme.colors.gradient.default,
  padding: 1,
  WebkitMask:
    'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  WebkitMaskComposite: 'xor',
  maskComposite: 'exclude',
  borderRadius: '24px',
};
const cardParallax = keyframes({
  to: {
    transform: 'translateY(150px)',
  },
});

export const CardContainer = styled.div({
  transition: 'opacity 0.5s ease-in-out',
  position: 'absolute',
  zIndex: 5,
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '372px',
  height: '296px',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48)),linear-gradient(135deg, rgba(254, 57, 199, 0.42) 0%, rgba(82, 0, 255, 0.42) 52.08%, rgba(0, 240, 211, 0.42) 100%)',

  borderRadius: '24px',
  top: '60px',
  right: '-50px',
  backdropFilter: 'blur(12px)',
  padding: '36px',
  '@supports (animation-timeline: scroll())': {
    animation: `${cardParallax} .5s linear`,
    animationTimeline: 'scroll()',
  },
  '::before': {
    ...pseudoGradientBorderStyles,
  },
  [mediaQueries.lg]: {
    right: 0,
  },
});

export const CardContent = styled.div({
  opacity: 0,
  animation: `${FadeIn} 0.3s forwards`,
});

export const Container = styled.div({
  position: 'relative',
  maxWidth: '1024px',
});

export const DotsContainer = styled.div({
  display: 'flex',
  columnGap: '12px',
});

export const StyledDot = styled.span<{ isActive: boolean }>(({ isActive }) => ({
  width: '8px',
  height: '8px',
  background: isActive ? theme.colors.neutral.N99 : theme.colors.neutral.N00,
  opacity: isActive ? 1 : 0.4,
  borderRadius: '50%',
  cursor: 'pointer',
  zIndex: 4,
  ':hover': {
    background: theme.colors.neutral.N99,
    opacity: 1,
  },
}));

export const Arrow = styled.span<{ isLeft?: boolean }>(
  ({ isLeft = false }) => ({
    position: 'absolute',
    cursor: 'pointer',
    right: isLeft ? 'unset' : '-120px',
    left: isLeft ? '-120px' : 'unset',
    top: '50%',
    width: '48px',
    height: '48px',
    zIndex: 5,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: isLeft ? 'rotate(180deg)' : 'none',
    path: {
      width: '24px',
      height: '24px',
    },
    ':hover': {
      background: theme.colors.neutral.N04,
      transition: '.6s',
    },
  })
);

export const ProgressBarContainer = styled.span<{ progress: number }>(
  ({ progress }) => ({
    width: '228px',
    height: '1px',
    background: theme.colors.neutral.N00,
    opacity: 0.4,
    position: 'relative',
    '::after': {
      content: "''",
      background: theme.colors.neutral.N99,
      position: 'absolute',
      width: `${progress}%`,
      transition: progress === 0 ? 'none' : 'width .2s linear',
      height: '1px',
    },
  })
);

export const CardBottomContainer = styled.div({
  display: 'flex',
  columnGap: '24px',
  alignItems: 'center',
  height: '8px',
  marginTop: '36px',
});

export const StyledBadge = styled(Badge)({
  alignSelf: 'flex-start',
  ':hover': {
    background: theme.colors.neutral.N99,
    color: theme.colors.neutral.N00,
  },
});
