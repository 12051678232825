import { createContext, useContext } from 'react';
export interface LinkConfig {
  nofollow_exclusions: { domain: string }[];
}

interface LinkConfigProviderProps {
  children: any;
  config: LinkConfig | undefined;
}

export const LinkConfigContext = createContext<LinkConfig | undefined>(
  undefined
);

export const useLinkConfig = () => {
  const context = useContext(LinkConfigContext);
  if (!context) {
    return undefined;
  }
  return context;
};
export const LinkConfigProvider = ({
  children,
  config,
}: LinkConfigProviderProps) => {
  return (
    <LinkConfigContext.Provider value={config}>
      {children}
    </LinkConfigContext.Provider>
  );
};
