import { useEffect } from 'react';

export const useKeyPress = (targetKey: string, callback: () => unknown) => {
  function downHandler({ key }: { key: string }) {
    if (key === targetKey) {
      callback();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};
