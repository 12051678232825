import styled from '@emotion/styled';
import { List } from '../List';
import { theme } from 'theme-v2/theme';

export const RichTextList = styled(List)({
  '*': {
    fontFamily: theme.fonts.family.additional,
  },
  paddingLeft: '1rem',
  '>*+*': {
    marginTop: '0.75rem',
  },
  li: {
    paddingBottom: 0,
  },
});
