import {
  BigImageBlockProps,
  PrismicImageTabsProps,
  PrismicBlogImageProps,
} from './types';

export const transformPrismicImageTabsToBigImageProps = ({
  primary,
  items,
}: PrismicImageTabsProps) => {
  const getItems = (items: PrismicImageTabsProps['items']) => {
    const set = new Set<number>();
    const result: BigImageBlockProps['items'] = [];

    for (const item of items) {
      const index = Number(item.selected_tab);
      if (set.has(index)) continue;
      if (!item.image) continue;
      set.add(index);
      result[index] = {
        description: item.description,
        image: item.image,
        category: index,
      };
    }

    return result;
  };

  return {
    title: primary.title,
    subtitle: primary.subtitle,
    background: primary.background,
    cta:
      !!primary.cta_text && !!primary.cta_link?.url
        ? {
            label: primary.cta_text,
            position:
              primary.cta_position?.toLowerCase() === 'top' ? 'top' : 'bottom',
            url: primary.cta_link.url,
          }
        : undefined,
    categories: [primary.first_tab_label, primary.second_tab_label].filter(
      (category): category is string => !!category
    ),
    items: getItems(items),
  } satisfies BigImageBlockProps;
};

export const transformPrismicBlogImagePropsToBigImageProps = ({
  primary,
}: PrismicBlogImageProps) => {
  return {
    title: primary.image_title,
    background: primary.background,
    items: primary.blog_image
      ? [
          {
            category: 0,
            image: primary.blog_image,
          },
        ]
      : [],
    categories: [],
    isExpandable: primary.is_expandable,
  } satisfies BigImageBlockProps;
};
