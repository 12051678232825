import styled from '@emotion/styled';
import { Link, Text, theme } from '@smartproxy-web/ui';

export const ContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1.5rem',
});

export const HeadingLinksWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const HeadingInnerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
});

export const StyledLink = styled(Link)<{ selected?: boolean }>(
  ({ selected = false }) => ({
    color: selected ? theme.colors.neutral.N99 : theme.colors.neutral.N50,
    fontStyle: 'normal',
    lineHeight: '1.5rem',
    fontWeight: 400,
    padding: '0.75rem 1.5rem',
    cursor: 'pointer',
  })
);

export const StyledLine = styled.span<{ selected?: boolean }>(
  ({ selected = false }) => ({
    height: '103%',
    backgroundColor: theme.colors.neutral.N99,
    borderRadius: '5px',
    position: 'absolute',
    top: -1,
    left: 0,

    ...(selected ? { width: '5px', marginLeft: '-2px' } : { width: '1px' }),
  })
);

export const StyledText = styled(Text)({
  marginBottom: '1.5rem',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.075rem',
  color: theme.colors.neutral.N50,
});
