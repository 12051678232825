import styled from '@emotion/styled';
import { richTextParagraphElements } from 'constants/common';
import { MAX_BODY_CONTENT_WIDTH } from 'constants/styles';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';

export const StyledHeroLogosWrapper = styled.div<{ isDarkTheme: boolean }>(
  ({ isDarkTheme }) => ({
    background: isDarkTheme
      ? themev2.colors.neutral.N99
      : themev2.colors.neutral.N00,
    width: '100%',
    overflow: 'hidden',
    padding: `${themev2.spacings.L} 20px`,
    [richTextParagraphElements]: {
      color: isDarkTheme
        ? themev2.colors.neutral.N50
        : themev2.colors.black.default,
    },
    [MEDIA_QUERIES.TABLET]: {
      paddingTop: '32px',
    },
  })
);

export const StyledHeroLogosWrapperInner = styled.div({
  maxWidth: MAX_BODY_CONTENT_WIDTH,
  margin: '0 auto',

  [richTextParagraphElements]: {
    fontSize: themev2.fonts.size.p_small,
    lineHeight: themev2.fonts.lineHeight.p_small,
    fontWeight: 400,
    margin: 0,
    marginBottom: 24,
    fontFamily: themev2.fonts.family.main,
  },
});

export const StyledHeroLogosTitle = styled.div({
  [richTextParagraphElements]: {
    fontSize: themev2.fonts.size.p_small,
  },
});

export const StyledHeroLogos = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '20px',
  [MEDIA_QUERIES.TABLET]: {
    gap: `20px ${themev2.spacings.M}`,
    justifyContent: 'flex-start',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: 'column',
  },
});

export const StyledLogoWrapper = styled.div({
  height: '24px',
  div: {
    height: '100%',
  },
});
