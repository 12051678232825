import {
  StyledTextGridItemHeader,
  StyledTextGrid,
  StyledTextGridItem,
  ImageWrapper,
  StyledTextGridWrapper,
} from './styledComponents';
import { SliceHeader } from '../Slice';
import {
  Box,
  Button,
  GatsbyImg,
  PrismicTextField,
  TGatsbyImage,
} from '@smartproxy-web/ui';
import { useShowMoreLess, useSliceDetails } from '../../hooks';
import { RichText } from '../RichText';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';

export interface TextGridProps {
  title?: PrismicTextField;
  subtitle?: PrismicTextField;
  isDarkTheme: boolean;
  isHeaderCentered: boolean;
  background?: string;
  items: TextGridItemProps[];
}

export interface TextGridItemProps {
  icon?: TGatsbyImage;
  title: PrismicTextField;
  description: PrismicTextField;
}

export const TextGrid: React.FC<TextGridProps> = ({
  title,
  subtitle,
  isDarkTheme,
  isHeaderCentered,
  items,
  background,
}) => {
  const { name: sliceName } = useSliceDetails();

  const getGridColumnsForLgScreens = (itemsCount: number) => {
    if (itemsCount === 2 || itemsCount === 4) return 2;
    return 3;
  };
  const color = isDarkTheme ? 'white' : 'black';

  const columns = getGridColumnsForLgScreens(items.length);
  const { showAll, handleShowMoreLess, buttonText } = useShowMoreLess();
  const slicedItems = items.slice(0, showAll ? items.length : 6);
  const itemsMoreThanSix = items.length > 6;

  const trackingSelector = initBuildTrackingId({
    section: 'text-grid',
  });

  const ButtonShowMoreLess = (
    <Button
      fullWidth
      variant="outlined"
      color={color}
      data-tracker={trackingSelector({ context: 'cta-' + buttonText })}
      onClick={() => {
        handleShowMoreLess();
        logPostHogEvent(trackingSelector({ context: 'cta-' + buttonText }));
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <StyledTextGridWrapper
      isDark={isDarkTheme}
      data-section-id={sliceName}
      backgroundColor={background}
    >
      <SliceHeader
        isDark={isDarkTheme}
        title={title}
        description={subtitle}
        align={isHeaderCentered ? 'center' : 'left'}
      />
      {!!items?.length && (
        <StyledTextGrid columnsCount={columns}>
          {slicedItems.map(({ icon, description, title }, index) => (
            <StyledTextGridItem key={index}>
              <StyledTextGridItemHeader>
                {!!icon?.url && (
                  <ImageWrapper>
                    <GatsbyImg image={icon} />
                  </ImageWrapper>
                )}
                <RichText field={title} />
              </StyledTextGridItemHeader>
              <RichText field={description} />
            </StyledTextGridItem>
          ))}
        </StyledTextGrid>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {itemsMoreThanSix && (
          <Box
            sx={{
              marginTop: { base: '32px', sm: '60px', md: '80px' },
              width: { base: '100%', md: 'fit-content' },
            }}
          >
            {ButtonShowMoreLess}
          </Box>
        )}
      </Box>
    </StyledTextGridWrapper>
  );
};
