import {
  CellCta,
  CellImageContainer,
  CellValue,
  Row,
} from './styledComponents';
import { GatsbyImg } from '@smartproxy-web/ui';
import { TableColumnProps } from './types';
import { RichText } from '../RichText';
import { LinkButton } from '../LinkButton';
import { normalizeButtonColor } from './utils';
import { usePageContext } from 'contexts/PageContext';
import { buildTrackingSelector } from 'utils/page';
import { logPostHogEvent } from '@smartproxy-web/analytics';

export const TableColumn: React.FC<TableColumnProps> = ({
  columnData,
  idx,
  tableTextAlignment,
  numbOfColumns,
  lastArrayElement,
}) => {
  const { tags } = usePageContext();
  const tableCtaTrackingSelector = (context) =>
    buildTrackingSelector({
      prefix: 'cta',
      page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
      context: `column-${context}`,
    });

  return (
    <Row
      idx={idx}
      numbOfColumns={numbOfColumns}
      lastArrayElement={lastArrayElement}
    >
      {columnData.map(
        ({
          cell_icon: cellIcon,
          cell_value: cellValue,
          cta_text: ctaText,
          cta_url: ctaUrl,
          cta_color: ctaColor,
          column,
        }) => (
          <CellValue
            key={Math.random()}
            idx={idx}
            alignToLeft={tableTextAlignment}
            lastElementIndex={lastArrayElement}
          >
            {!!cellIcon.url && (
              <CellImageContainer alignToLeft={tableTextAlignment}>
                <GatsbyImg image={cellIcon} />
              </CellImageContainer>
            )}
            <RichText field={cellValue} />
            {ctaText && (
              <CellCta>
                <LinkButton
                  to={ctaUrl.url}
                  {...normalizeButtonColor(ctaColor)}
                  fullWidth
                  data-tracker={tableCtaTrackingSelector(column)}
                  onClick={() =>
                    logPostHogEvent(tableCtaTrackingSelector(column))
                  }
                >
                  {ctaText}
                </LinkButton>
              </CellCta>
            )}
          </CellValue>
        )
      )}
    </Row>
  );
};
