import styled from '@emotion/styled';
import { StyledButton, mediaQueries, theme } from '@smartproxy-web/ui';
import { LinkButton } from '../LinkButton';
import { Slice } from '../Slice';

export const Wrapper = styled(Slice)(({ backgroundColor, isDark }) => ({
  background: isDark
    ? theme.colors.neutral.N99
    : backgroundColor || theme.colors.neutral.N00,
  [String(StyledTitle)]: {
    color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  },
  [String(StyledDescriptionContainer)]: {
    color: isDark ? theme.colors.neutral.N40 : theme.colors.neutral.N80,
  },
}));

export const BiggerPlansContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '32px 20px',
  position: 'relative',
  borderColor: 'transparent',
  width: '100%',

  ':after': {
    borderRadius: '12px',
    position: 'absolute',
    content: '""',
    inset: -1,
    background: theme.colors.gradient.default,
    padding: 1,
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },

  [mediaQueries.md]: {
    padding: '36px 40px',
  },

  [mediaQueries.lg]: {
    flexDirection: 'row',
    padding: '36px 48px',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1024px',
    margin: '0 auto',
  },
});

export const TextAndIconContainer = styled.div<{ isDescriptionAdded: boolean }>(
  ({ isDescriptionAdded }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px',
    [mediaQueries.md]: {
      alignItems: isDescriptionAdded ? 'start' : 'center',
      marginBottom: '36px',
      flexDirection: 'row',
    },
    [mediaQueries.lg]: {
      marginBottom: 0,
    },
  })
);

export const StyledDescriptionContainer = styled.div({
  marginTop: theme.spacings.XS,
});

export const StyledTitle = styled.p({
  fontSize: theme.fonts.size.p_xlarge,
  lineHeight: theme.fonts.lineHeight.p_medium,
  fontWeight: 700,
  fontFamily: theme.fonts.family.additional,
});

export const StyledLinkButton = styled(LinkButton)({
  zIndex: 1,
  [String(StyledButton)]: {
    width: '100%',
  },
});
