import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import Input from 'components/Input';
import Select from 'components/Select';
import { getGutter, IGutterProps } from 'components/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import { getThemeGutter, ITheme } from 'theme';

export const SelectWrapper = styled.div<IGutterProps & ITheme>((props) => ({
  marign: getGutter(props),
  marginBottom: '28px',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const StyledSelect = styled(Select)({
  marginBottom: '6px',
});

export const TextareaWrapper = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
}));

export const InputWrapper = styled.div<IGutterProps & ITheme>((props) => ({
  margin: getGutter(props),
  marginBottom: '28px',
  width: '100%',
}));

export const StyledInput = styled(Input)({
  marginBottom: '6px',
});
