import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { StyledBadgeProps } from './types';

export const StyledBadge = styled.div<StyledBadgeProps>(
  ({ color, fullWidth, variant }) => ({
    display: 'flex',
    height: '16px',
    alignItems: 'center',
    borderRadius: '20px',
    padding: '0px 6px 0px 8px',
    fontWeight: 600,
    fontSize: '8px',
    lineHeight: '16px',
    letterSpacing: '0.1em',
    backgroundColor:
      variant === 'filled' ? themev2.colors[color].default : 'inherit',
    border: `1px solid ${themev2.colors[color].default}`,
    color:
      variant === 'filled'
        ? themev2.colors.neutral.N99
        : themev2.colors[color].default,
    width: fullWidth ? '100%' : 'max-content',
    textTransform: 'uppercase',
    alignSelf: 'center',
  })
);
