import styled from '@emotion/styled';
import { Button } from 'components/v2/Button';
import { themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../../constants';
import { StyledNavMenuList } from '../styledComponents';

export const StyledRightNavMenuList = styled(StyledNavMenuList)({
  gap: 24,

  a: {
    ':focus': {
      '> div': {
        color: themev2.colors.white.default,
      },
      ':last-of-type > div': {
        backgroundColor: themev2.colors.white.default,
        color: themev2.colors.gray.S900,
      },
    },
  },

  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'none',
  },
});

export const StyledSearchButton = styled(Button)({
  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'none',
  },
});
