import styled from '@emotion/styled';
import { theme } from '@smartproxy-web/ui';

const LEFT_COLUMN_WIDTH = '130px';
const COLUMNS_MIN_WIDTH = '185px';

export const Table = styled.table({
  width: '100%',
  'th, td': {
    background: theme.colors.neutral.N00,
  },
});
export const TableWrapper = styled.div({
  overflowX: 'auto',
});

export const Row = styled.tr<{
  idx: number;
  numbOfColumns: number;
  lastArrayElement: boolean;
}>(({ idx, numbOfColumns, lastArrayElement }) => ({
  display: 'grid',
  gridTemplateColumns: `${LEFT_COLUMN_WIDTH} repeat(${
    numbOfColumns - 1
  }, minmax(${COLUMNS_MIN_WIDTH}, 1fr))`,
  borderTop: idx === 0 ? `1px solid ${theme.colors.neutral.N20}` : 'none',
  borderRight: `1px solid ${theme.colors.neutral.N20}`,
  borderTopLeftRadius: idx === 0 ? '12px' : 'none',
  ':hover': {
    [String(CellValue)]: {
      background: lastArrayElement
        ? theme.colors.neutral.N00
        : theme.colors.neutral.N04,
    },
  },
  ':last-child': {
    borderBottomRightRadius: '12px',
  },
  ':nth-last-of-type(2)': {
    borderBottomLeftRadius: '12px',
    'td:first-of-type': {
      borderBottomLeftRadius: '12px !important',
    },
  },
  ':not(:last-child)': {
    borderBottom: `1px solid ${theme.colors.neutral.N20}`,
  },
  td: {
    ':first-of-type': {
      alignItems: 'flex-start',
      gridColumnStart: lastArrayElement ? 2 : 1,
      borderBottomLeftRadius: lastArrayElement ? '12px' : 'unset',
    },
    ':last-of-type': {
      borderBottomRightRadius: lastArrayElement ? '12px' : 'unset',
    },
    ul: {
      width: '100%',
      li: {
        textAlign: 'left',
        margin: 0,
        color: theme.colors.black.S900,
      },
    },
  },
}));

export const StyledCta = styled.div({
  width: '219px',
});

export const TableHeader = styled.th({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacings.XS,
  padding: `20px ${theme.spacings.S}`,
  height: 'auto',

  p: {
    fontWeight: 700,
    color: theme.colors.neutral.N99,
    fontFamily: theme.fonts.family.additional,
    fontSize: theme.fonts.size.h5,
    lineHeight: theme.fonts.lineHeight.h5,
  },
});

export const ImageContainer = styled.div({
  width: '23px',
  height: '23px',
  div: {
    width: '23px',
    height: '23px',
  },
});

export const CellImageContainer = styled.div<{ alignToLeft: boolean }>(
  ({ alignToLeft }) => ({
    width: '100%',
    height: '21px',
    display: 'flex',
    justifyContent: alignToLeft ? 'flex-start' : 'center',
  })
);

export const CellValue = styled.td<{
  idx: number;
  alignToLeft;
  lastElementIndex: boolean;
}>(({ idx, alignToLeft, lastElementIndex }) => ({
  minHeight: '69px',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '24px',
  borderLeft: `1px solid ${theme.colors.neutral.N20}`,
  textAlign: alignToLeft ? 'left' : 'center',
  borderBottom: lastElementIndex
    ? `1px solid ${theme.colors.neutral.N20}`
    : 'none',
  ':first-of-type': {
    borderTopLeftRadius: idx === 0 ? '12px' : 'none',
    borderBottomLeftRadius: lastElementIndex ? '12px' : 'unset',
    textAlign: 'left',
  },
  p: {
    width: '100%',
    color: theme.colors.neutral.N99,
    bold: {
      fontWeight: 700,
    },
  },
}));

export const CellCta = styled.div({
  width: '100%',
  height: '88px',
  display: 'inline-grid',
  alignItems: 'center',
  div: {
    padding: 0,
  },
});

export const TableCellContainer = styled.tr<{ numbOfColumns: number }>(
  ({ numbOfColumns }) => ({
    width: 'calc(100% - 1px)',
    display: 'grid',
    gridTemplateColumns: `${LEFT_COLUMN_WIDTH} repeat(${
      numbOfColumns - 1
    }, minmax(${COLUMNS_MIN_WIDTH}, 1fr))`,
    th: {
      borderTop: `1px solid ${theme.colors.neutral.N20}`,
      borderLeft: `1px solid ${theme.colors.neutral.N20}`,
      ':first-of-type': {
        gridColumnStart: 2,
        borderTopLeftRadius: '12px',
      },
      ':last-of-type': {
        width: 'calc(100% + 1px)',
        borderTopRightRadius: '12px',
        borderRight: `1px solid ${theme.colors.neutral.N20}`,
      },
    },
  })
);
