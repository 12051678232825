import { IBreadcrumbSchema, IItem } from './types';

type TGetSchema = (items: IItem[]) => IBreadcrumbSchema;

export const getBreadcrumbSchema: TGetSchema = (items: IItem[]) => {
  const itemsLength = items.length;
  const itemListElement = items.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      type: 'WebPage',
      '@id': itemsLength === index + 1 ? undefined : item.link.url,
      name: item.link_label.text,
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement,
  };
};
