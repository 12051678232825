import React, { createContext, useContext } from 'react';

export interface SliceContextValue {
  position: number;
  name: string;
}

export const SliceContext = createContext<SliceContextValue | undefined>(
  undefined
);

export const useSliceContext = () => useContext(SliceContext);

export const SliceContextProvider = ({
  children,
  position,
  name,
}: React.PropsWithChildren<SliceContextValue>) => {
  return (
    <SliceContext.Provider value={{ name, position }}>
      {children}
    </SliceContext.Provider>
  );
};
