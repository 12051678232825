import {
  ArrowContainer,
  ItemDescriptionContainer,
  ItemHeadingContainer,
  ItemTextContainer,
} from './styledComponents';
import { ItemProps } from './types';
import { RichText } from '../RichText';
import { LinkButton } from '../LinkButton';
import { Box, IconDropdown } from '@smartproxy-web/ui';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { useSliceDetails } from '../../hooks';
import { logEvent } from '../../helpers';

export const ItemsContainer = ({
  description,
  itemTitle,
  isOpen = false,
  onClick,
  ctaLink,
  ctaText,
}: ItemProps) => {
  const { id: sliceId, name: sliceName } = useSliceDetails();

  const buildTrackingId = initBuildTrackingId({
    section: sliceName,
  });

  const itemTrackingId = buildTrackingId({
    context: itemTitle.text,
    section: 'dynamic-text-image',
  });

  const itemCtaTrackingId = buildTrackingId({
    context: itemTitle.text + '-' + ctaText,
    section: 'dynamic-text-image',
    type: 'cta',
  });

  const handleClick = () => {
    onClick();
    logEvent({
      eventId: itemTrackingId,
      elementId: itemTitle.text,
      sliceId,
    });
  };

  return (
    <ItemTextContainer onClick={handleClick} data-tracker={itemTrackingId}>
      <ItemHeadingContainer>
        <RichText field={itemTitle} />
        <ArrowContainer selected={isOpen}>
          <IconDropdown />
        </ArrowContainer>
      </ItemHeadingContainer>
      <ItemDescriptionContainer selected={isOpen}>
        <RichText field={description} />
        {!!ctaLink!.url && (
          <Box
            sx={{
              marginTop: { base: '32px', md: '36px' },
              width: { base: '100%', lg: 'fit-content' },
            }}
          >
            <LinkButton
              to={ctaLink!.url}
              variant="outlined"
              color="blue"
              fullWidth
              data-tracker={itemCtaTrackingId}
              onClick={() =>
                logEvent({
                  sliceId,
                  elementId: ctaText,
                  eventId: itemCtaTrackingId,
                })
              }
            >
              {ctaText}
            </LinkButton>
          </Box>
        )}
      </ItemDescriptionContainer>
    </ItemTextContainer>
  );
};
