import styled from '@emotion/styled';
import { mediaQueries, theme as themev2 } from '@smartproxy-web/ui';

interface StyleProps {
  isExpanded?: boolean;
  isDark?: boolean;
}

export const FaqItemContainer = styled.div<StyleProps>(
  ({ isExpanded, isDark }) => ({
    maxWidth: '1024px',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    ':nth-child(2)': {
      borderTop: isDark
        ? `1px solid ${themev2.colors.neutral.N80}`
        : `1px solid ${themev2.colors.neutral.N10}`,
    },
    display: 'inline-block',
    cursor: 'pointer',
    maxHeight: isExpanded ? '1000px' : '157px',
    transition: '0.6s ease-in-out all',
    borderBottom: isDark
      ? `1px solid ${themev2.colors.neutral.N80}`
      : `1px solid ${themev2.colors.neutral.N10}`,
    [mediaQueries.sm]: {
      maxHeight: isExpanded ? '1000px' : '131px',
    },
    [mediaQueries.md]: {
      maxHeight: isExpanded ? '1000px' : '105px',
    },
  })
);

export const FaqQuestionContainer = styled.div<StyleProps>(
  ({ isExpanded, isDark }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '40px',
    position: 'relative',
    svg: {
      position: 'absolute',
      path: {
        fill: isDark ? themev2.colors.neutral.N00 : themev2.colors.neutral.N50,
      },
      top: '32px',
      right: 0,
      bottom: 0,
      transform: isExpanded
        ? 'translate3d(0px, 0px, 0px)  rotateX(180deg)'
        : 'translate3d(0px, 0px, 0px)  rotateX(180deg) rotateY(0deg) rotateZ(180deg)',
      transformStyle: 'preserve-3d',
      marginRight: '6px',
      transition: '0.4s  transform',
      [mediaQueries.md]: {
        top: '36px',
      },
    },
  })
);

export const StyledQuestion = styled.div<StyleProps>(({ isDark }) => ({
  'h2, h3, h4': {
    whiteSpace: 'break-spaces',
    fontFamily: themev2.fonts.family.additional,
    margin: '20px 0',
    fontWeight: '700',
    fontSize: '20px',
    color: isDark ? themev2.colors.neutral.N00 : themev2.colors.neutral.N80,
  },
  [mediaQueries.md]: {
    'h2, h3, h4': {
      margin: `${themev2.spacings.S} 0`,
    },
  },
}));

export const StyledAnswer = styled.div<StyleProps>(({ isDark }) => ({
  marginBottom: '40px',
  p: {
    position: 'static',
    whiteSpace: 'break-spaces',
    margin: `0 auto ${themev2.spacings.S}`,
    color: isDark ? themev2.colors.neutral.N20 : themev2.colors.neutral.N80,
    fontSize: themev2.fonts.size.p_medium,
    fontWeight: '400',
    a: {
      color: themev2.colors.primary.P99,
    },
  },
  'ol, ul': {
    paddingLeft: '40px',
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  },
  'a, strong, em': {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
    fontFamily: themev2.fonts.family.additional,
    color: isDark ? themev2.colors.neutral.N20 : themev2.colors.neutral.N80,
  },
  li: {
    margin: '0 auto',
    maxWidth: '1024px',
    width: '100%',
    fontFamily: themev2.fonts.family.additional,
    fontSize: themev2.fonts.size.p_medium,
    color: themev2.colors.neutral.N80,
    fontWeight: 400,
    padding: 0,
    ':last-child': {
      marginBottom: '27px',
    },
  },
  [mediaQueries.sm]: {
    marginBottom: '21px',
  },
  [mediaQueries.md]: {
    marginBottom: themev2.spacings.S,
  },
}));

export const FaqContainer = styled.div({
  width: '100%',
  color: themev2.colors.neutral.N99,
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'left',
  [mediaQueries.md]: {
    alignItems: 'center',
  },
});

export const FaqAnswerContainer = styled.div<StyleProps>(({ isExpanded }) => ({
  display: isExpanded ? 'block' : 'none',
}));
