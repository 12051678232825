import { CarouselAutoplay, CarouselNavigation } from '@smartproxy-web/ui';
import { SwiperOptions } from 'swiper/types';

export const slidesGap = 50;

export const swiperConfig = {
  modules: [CarouselAutoplay, CarouselNavigation],
  navigation: true,
  spaceBetween: slidesGap,
  slidesPerView: 1,
  allowTouchMove: false,
  freeMode: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: true,
  },
  speed: 800,
} satisfies SwiperOptions;

export const LeftArrowTracker = 'hero-carousel-left-arrow';
export const RightArrowTracker = 'hero-carousel-right-arrow';
export const CARD_ANIMATION_DURATION = 65; //progress bar will be loaded in 6500ms - this is the duration of image block animation
