import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../../constants';
import { NAV_PAGE_TRANSITION_DURATION } from '../constants';
import {
  StyledNavMenuItemTrigger,
  StyledNavMenuItemTriggerLabel,
} from '../NavMenuItem/styledComponents';
import { StyledNavMenuList } from '../styledComponents';

export const StyledPagedNav = styled.div({
  display: 'none',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',

  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-start',
  },
});

export const StyledPagedNavInner = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexBasis: '100%',
  flexGrow: 1,

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    flexGrow: 1,

    ':nth-of-type(n+2)': {
      display: 'none',
    },
  },

  [COMPACT_HEADER_MEDIA_QUERY]: {
    transform: `translateX(calc(var(--current-page-index) * -100%))`,
    transition: `transform ${NAV_PAGE_TRANSITION_DURATION / 1000}s ease-in-out`,

    '> div': {
      ':nth-of-type(n+2)': {
        display: 'flex',
      },
      ':nth-of-type(2)': {
        [String(StyledNavMenuItemTrigger)]: {
          marginBottom: '8px',
        },
        [String(StyledNavMenuItemTriggerLabel)]: {
          fontSize: themev2.fonts.size.p_medium,
          lineHeight: themev2.fonts.lineHeight.p_medium,
        },
      },
    },
  },
});

export const StyledNavItemPage = styled.div({
  flexGrow: 1,
  width: '100%',
  div: {
    ':nth-child(2)': {
      padding: '9px 20px 0',
      marginBottom: '8px',
      fontSize: themev2.fonts.size.p_medium,
      lineHeight: themev2.fonts.lineHeight.p_medium,
    },
  },

  ul: {
    ':nth-child(3)': {
      paddingTop: themev2.spacings.XS,
      [String(StyledNavMenuItemTrigger)]: {
        marginBottom: '8px',
        span: {
          div: {
            span: {
              backgroundColor: themev2.colors.red.default,
              border: `1px solid ${themev2.colors.red.default}`,
              color: themev2.colors.gray.S900,
            },
          },
        },
      },
      [String(StyledNavMenuItemTriggerLabel)]: {
        fontSize: themev2.fonts.size.p_medium,
        lineHeight: themev2.fonts.lineHeight.p_medium,
      },
    },
  },
});

const StyledNavItemPageSection = styled.div({
  padding: `${themev2.spacings.M} 20px 0 `,
  button: {
    div: {
      span: {
        fontSize: themev2.fonts.size.p_medium,
        fontFamily: themev2.fonts.family.additional,
        color: themev2.colors.neutral.N00,
      },
      svg: {
        path: {
          fill: themev2.colors.neutral.N00,
        },
      },
    },
  },
});

export const StyledNavItemPageHeader = styled(StyledNavItemPageSection)({
  'button span': {
    fontWeight: 300,
  },
});

export const StyledNavItemPageTitleWrapper = styled(StyledNavItemPageSection)({
  marginTop: 18,
  marginBottom: 8,
  p: {
    color: themev2.colors.neutral.N40,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: themev2.fonts.size.p_small,
    textTransform: 'uppercase',
    letterSpacing: 1.2,
  },
});

export const StyledNavItemPageMenu = styled(StyledNavMenuList)({
  width: '100%',
  gap: 30,
  display: 'none',

  'a:focus > div': {
    color: themev2.colors.white.default,
  },

  [COMPACT_HEADER_MEDIA_QUERY]: {
    overflowY: 'auto',
    display: 'flex',

    '[data-disabled=true] span': {
      color: themev2.colors.blue.default,
      cursor: 'default',
    },
  },
});
