export const ipCheckerMsgs = {
  ipCheckerLocation: {
    id: 'ipCheckerMsgs.ipCheckerLocation',
    defaultMessage: 'Location',
  },
  ipCheckerCity: {
    id: 'ipCheckerMsgs.ipCheckerCity',
    defaultMessage: 'City: ',
  },
  ipCheckerCityCode: {
    id: 'ipCheckerMsgs.ipCheckerCityCode',
    defaultMessage: 'Code: ',
  },
  ipCheckerCityState: {
    id: 'ipCheckerMsgs.ipCheckerCityState',
    defaultMessage: 'State: ',
  },
  ipCheckerCityTimeZone: {
    id: 'ipCheckerMsgs.ipCheckerCityTimeZone',
    defaultMessage: 'Time zone: ',
  },
  ipCheckerIsp: {
    id: 'ipCheckerMsgs.ipCheckerisp',
    defaultMessage: 'ISP',
  },
  ipCheckerIspProvider: {
    id: 'ipCheckerMsgs.ipCheckerispProvider',
    defaultMessage: 'Provider: ',
  },
  ipCheckerIspAsn: {
    id: 'ipCheckerMsgs.ipCheckerispAsn',
    defaultMessage: 'ASN: ',
  },
  ipCheckerIspOrganization: {
    id: 'ipCheckerMsgs.ipCheckerispOrganization',
    defaultMessage: 'Organization: ',
  },
  ipCheckerPlatform: {
    id: 'ipCheckerMsgs.ipCheckerPlatform',
    defaultMessage: 'Platform',
  },
  ipCheckerPlatformName: {
    id: 'ipCheckerMsgs.ipCheckerPlatformName',
    defaultMessage: 'Name: ',
  },
  ipCheckerPlatformOs: {
    id: 'ipCheckerMsgs.ipCheckerPlatformOs',
    defaultMessage: 'OS: ',
  },
  ipCheckerBrowser: {
    id: 'ipCheckerMsgs.ipCheckerBrowser',
    defaultMessage: 'Browser',
  },
  ipCheckerBrowserName: {
    id: 'ipCheckerMsgs.ipCheckerBrowserName',
    defaultMessage: 'Name: ',
  },
  ipCheckerBrowserVersion: {
    id: 'ipCheckerMsgs.ipCheckerBrowserVersion',
    defaultMessage: 'Version: ',
  },
};
