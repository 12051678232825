export const getQuestions = (nodes) =>
  nodes.map(({ data: { question_title: title }, id, tags, type }) => {
    return {
      id,
      tags,
      type,
      data: {
        title,
      },
    };
  });
