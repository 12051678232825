import { StyledBaseSection } from './styledComponents';
import { IBaseSectionProps } from './types';

const BaseSection = ({
  children,
  shouldContentBleed = false,
  ...rest
}: IBaseSectionProps) => (
  <StyledBaseSection shouldContentBleed={shouldContentBleed} {...rest}>
    <div>{children}</div>
  </StyledBaseSection>
);

export default BaseSection;
