import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../../constants';

export const StyledNavMenuItemTrigger = styled.button<{ opened?: boolean }>(
  ({ opened }) => ({
    background: 'transparent',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: opened ? themev2.colors.white.default : themev2.colors.neutral.N40,
    transition: '0.3s ease-in-out',
    svg: {
      transform: !opened
        ? 'translate3d(0px, 0px, 0px)  rotateX(180deg) rotateY(0deg) rotateZ(-180deg)'
        : 'translate3d(0px, 0px, 0px)  rotateX(180deg)',
      transformStyle: 'preserve-3d',
      transition: '0.4s  transform',
      path: {
        fill: opened ? themev2.colors.white.default : themev2.colors.gray.S300,
      },
    },

    '> span span': {
      transition: '0.3s ease-in-out',
      backgroundColor: opened ? themev2.colors.red.default : 'transparent',
      color: opened ? themev2.colors.black.default : themev2.colors.red.default,
    },

    ':hover': {
      color: themev2.colors.white.default,
      svg: {
        path: {
          fill: themev2.colors.white.default,
        },
      },
    },

    [COMPACT_HEADER_MEDIA_QUERY]: {
      width: '100%',
      justifyContent: 'space-between',
      padding: '4px 0',
      color: themev2.colors.white.default,
      marginBottom: '16px',

      '> svg:last-of-type': {
        transform: 'rotate(-90deg)',
      },
    },
  })
);

export const StyledNavMenuItemTriggerLabel = styled.span({
  fontWeight: 300,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  lineHeight: themev2.fonts.lineHeight.p_small,
  fontSize: themev2.fonts.size.p_medium,
  fontFamily: themev2.fonts.family.additional,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    lineHeight: themev2.fonts.lineHeight.p_medium,
    svg: {
      display: 'block',
      path: {
        fill: themev2.colors.white.default,
      },
    },
  },
});

export const CategoryPricingNav = styled.p({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginTop: `-${themev2.spacings.XS}`,
  color: themev2.colors.white.default,
  fontWeight: 400,
  fontSize: themev2.fonts.size.p_medium,
  lineHeight: '15px',
  paddingBottom: themev2.spacings.XS,
  fontFamily: themev2.fonts.family.additional,
  span: {
    color: themev2.colors.neutral.N40,
    fontSize: themev2.fonts.size.p_xsmall,
    fontWeight: 300,
    lineHeight: '15px',
    paddingRight: '0.5ch',
    fontFamily: themev2.fonts.family.additional,
    ':last-of-type': {
      paddingLeft: '0.5ch',
    },
  },
});
