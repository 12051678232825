import {
  Button,
  DynamicToggle,
  GatsbyImg,
  IconSearch,
  Lightbox,
} from '@smartproxy-web/ui';
import { SliceHeader } from '../Slice';
import {
  StyledBigImageBlock,
  StyledBigImageWrapper,
  StyledCarouselCtaWrapper,
  StyledExpandImageTriggerWrapper,
} from './styledComponents';
import { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { LinkButton } from '../LinkButton';
import { useSliceDetails } from '../../hooks';
import {
  TrackingTypes,
  initBuildTrackingId,
} from '@smartproxy-web/shared/helpers';
import { logEvent } from '../../helpers';
import { BigImageBlockProps } from './types';

export const BigImageBlock = ({
  title,
  subtitle,
  cta,
  categories,
  items,
  isExpandable,
  background,
}: BigImageBlockProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isLightboxExpanded, setIsLightboxExpanded] = useState(false);
  const { id: sliceId, name: sliceName } = useSliceDetails();
  const buildTrackingId = initBuildTrackingId({
    section: sliceName,
  });

  const activeItem = items[activeTabIndex];

  const hasMultiCategories = categories.length > 1;
  const hasCta = !!cta;
  const hasBottomItems = hasMultiCategories || hasCta;
  const ctaTrackingId = buildTrackingId({ context: cta?.label, type: 'cta' });

  const handleToggleTrack = (key: string, type?: TrackingTypes) => {
    const eventId = buildTrackingId({ context: key, type });
    logEvent({
      eventId,
      elementId: key,
      sliceId,
    });
  };

  const handleTrackCta = () => {
    if (!cta) return;
    logEvent({
      eventId: ctaTrackingId,
      elementId: cta.label,
      sliceId,
    });
  };

  const handleExpandImage = () => {
    setIsLightboxExpanded(true);
    const eventId = buildTrackingId({
      context: 'expand-image',
      type: 'trigger',
    });
    logEvent({
      eventId,
      elementId: 'expand-image-trigger',
      sliceId,
    });
  };

  return (
    <StyledBigImageBlock
      id={sliceId}
      data-section-id={sliceId}
      backgroundColor={background}
    >
      <SliceHeader
        title={title}
        description={subtitle}
        bottomItems={
          hasBottomItems && (
            <>
              {hasCta && cta.position === 'top' && (
                <StyledCarouselCtaWrapper position={cta.position}>
                  <LinkButton
                    to={cta.url}
                    data-tracker={ctaTrackingId}
                    variant="outlined"
                    color="blue"
                    onClick={handleTrackCta}
                  >
                    {cta.label}
                  </LinkButton>
                </StyledCarouselCtaWrapper>
              )}
              {hasMultiCategories && (
                <DynamicToggle
                  tabs={categories.map((category) => ({
                    key: category,
                    label: category,
                  }))}
                  onSelect={(_, i) => setActiveTabIndex(i)}
                  onTrack={handleToggleTrack}
                  trackSelector={(key, type) =>
                    buildTrackingId({
                      context: key,
                      type,
                    })
                  }
                />
              )}
            </>
          )
        }
      />
      <StyledBigImageWrapper>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={activeTabIndex}
            mountOnEnter={false}
            unmountOnExit={false}
            timeout={150}
            className="big-image"
            classNames="big-image"
          >
            <GatsbyImg image={activeItem.image} />
          </CSSTransition>
        </SwitchTransition>
        {isExpandable && (
          <StyledExpandImageTriggerWrapper>
            <Button
              variant="text"
              color="white"
              data-tracker={buildTrackingId({
                context: 'expand-image',
                type: 'trigger',
              })}
              onClick={handleExpandImage}
            >
              <IconSearch />
            </Button>
          </StyledExpandImageTriggerWrapper>
        )}
      </StyledBigImageWrapper>
      {isExpandable && (
        <Lightbox
          handleClose={() => setIsLightboxExpanded(false)}
          isOpen={isLightboxExpanded}
        >
          <GatsbyImg image={activeItem.image} />
        </Lightbox>
      )}
      {hasCta && cta.position === 'bottom' && (
        <StyledCarouselCtaWrapper position={cta.position}>
          <LinkButton to={cta.url} variant="outlined" color="blue">
            {cta.label}
          </LinkButton>
        </StyledCarouselCtaWrapper>
      )}
    </StyledBigImageBlock>
  );
};
