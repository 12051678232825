import {
  StyledCodeBlockLanguagesWrapper,
  StyledCodeBlockLanguageWrapper,
} from './styledComponents';
import { CodeBlockLanguage } from './types';
import { BuildTrackingIdParams } from '@smartproxy-web/shared/helpers';
import { logEvent } from '../../helpers';

interface TabbedCodeBlockLanguagesProps {
  sliceId?: string;
  languages: CodeBlockLanguage[];
  activeLanguage: CodeBlockLanguage;
  handleLanguageChange: (language: CodeBlockLanguage) => void;
  buildTrackingId: (props?: BuildTrackingIdParams) => string;
}

export const TabbedCodeBlockLanguages = ({
  sliceId,
  languages,
  activeLanguage,
  handleLanguageChange,
  buildTrackingId,
}: TabbedCodeBlockLanguagesProps) => (
  <StyledCodeBlockLanguagesWrapper>
    {languages.map((language) => (
      <StyledCodeBlockLanguageWrapper
        key={language.id}
        data-tracker={buildTrackingId({
          context: `language ${language.label}`,
          type: 'trigger',
        })}
      >
        <input
          type="radio"
          name={sliceId ? `${sliceId}-language` : `language`}
          className="sr-only"
          onChange={() => {
            const eventId = buildTrackingId({
              context: `language ${language.label}`,
              type: 'trigger',
            });
            logEvent({
              eventId,
              elementId: `language ${language.label}`,
              sliceId,
            });
            handleLanguageChange(language);
          }}
          value={language.id}
          checked={language.id === activeLanguage.id}
        />
        <span>{language.label}</span>
      </StyledCodeBlockLanguageWrapper>
    ))}
  </StyledCodeBlockLanguagesWrapper>
);
