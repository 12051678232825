export const normalizeTopicPath = (topic) =>
  topic.toLowerCase().replaceAll(' ', '-');

export const getMostPopularData = (items) =>
  items.map(
    ({
      blog_post: {
        url,
        document: {
          data: {
            article_image: image,
            release_date: date,
            time_spent_reading: minutes,
            title,
            body,
          },
        },
      },
    }) => {
      const blogAuthor = getBlogAuthor(body);
      const blogTopics = transformBlogTopics(getBlogTopics(body));

      return { image, date, minutes, blogAuthor, blogTopics, title, url };
    }
  );

export const getBlogAuthor = (body) =>
  body
    .filter(
      (item) =>
        item.slice_type === 'blog_author' || item.slice_type === 'author_v2'
    )
    .map(
      ({
        primary: {
          blog_author: {
            document: {
              data: { full_name: name, authors_image: image },
            },
          },
        },
      }) => {
        return { name, image };
      }
    );

export const transformBlogTopics = (topics) =>
  topics.map((item) =>
    item.map(
      ({
        topic: {
          document: {
            data: { topic_label: label },
          },
        },
      }) => label
    )
  )[0];

export const getBlogTopics = (body) =>
  body
    .filter((item) => item.slice_type === 'social_buttons_bar')
    .map((topics) => topics.items);
