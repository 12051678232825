import { SPLogoIconv2 } from 'images';
import { LogoContainer } from '../styledComponents';

const FooterLogo = () => (
  <LogoContainer>
    <SPLogoIconv2 />
  </LogoContainer>
);

export default FooterLogo;
