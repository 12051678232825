import { useEffect, useState } from 'react';
import { COMPACT_HEADER_WIDTH } from '../constants';

export function useScreenSize() {
  const [screen, setScreen] = useState<'mobile' | 'tablet' | 'desktop'>(
    'desktop'
  );

  function handleWindowSizeChange() {
    const screens = {
      desktop: 1440,
      tablet: 768,
      mobile: 480,
    };

    const width = window.innerWidth;
    if (width <= screens.mobile) {
      setScreen('mobile');
    } else if (width <= screens.tablet) {
      setScreen('tablet');
    } else {
      setScreen('desktop');
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return {
    isMobile: screen === 'mobile',
    isTablet: screen === 'tablet',
    isDesktop: screen === 'desktop',
    notMountedScreen: !screen,
  };
}

export function useHeaderScreenSize() {
  const [screen, setScreen] = useState<'mobile' | 'desktop'>('desktop');

  function handleWindowSizeChange() {
    const screens = {
      headerDesktopSize: COMPACT_HEADER_WIDTH,
    };

    const width = window.innerWidth;
    if (width >= screens.headerDesktopSize) {
      setScreen('desktop');
    } else {
      setScreen('mobile');
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return {
    isMobile: screen === 'mobile',
    isDesktop: screen === 'desktop',
    notMountedScreen: !screen,
  };
}
