import { useEffect, useState } from 'react';

type UseFlexWrapDetectorProps = {
  parentRef: React.RefObject<HTMLElement>;
};

export default function useFlexWrapDetector({
  parentRef,
}: UseFlexWrapDetectorProps) {
  const [hasWrapped, setHasWrapped] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      if (!Boolean(parentRef)) return;

      const parentElement = parentRef.current;

      if (parentElement) {
        const children = Array.from(parentElement.children) as HTMLElement[];

        if (children.length - 1 > 1) {
          const firstChildTop = children[0].offsetTop;
          const lastChildTop = children[children.length - 2].offsetTop;

          setHasWrapped(firstChildTop !== lastChildTop);
        }
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentRef]);

  return hasWrapped;
}
