import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';
import { badgesHeight, trustpilotBadgeSize } from './constants';
import { keyframes } from '@emotion/react';

export const StyledHeroBadgesWrapper = styled.div<{ isCentered: boolean }>(
  ({ isCentered }) => ({
    display: 'flex',
    width: '100%',
    flex: '1 1 auto',
    justifyContent: 'flex-start',
    marginBottom: theme.spacings.S,
    marginTop: '36px',
    [mediaQueries.md]: {
      justifyContent: isCentered ? 'center' : 'fleex-start',
      marginTop: '10px',
    },
  })
);

const fadeInKeyframes = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const StyledHeroBadge = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    position: 'relative',
    height: badgesHeight,
    '> svg': {
      height: '100%',
    },
    '&+&': {
      borderLeft: isDark
        ? `1px solid ${theme.colors.neutral.N50}`
        : `1px solid ${theme.colors.neutral.N10}`,
      paddingLeft: '12px',
      [mediaQueries.md]: {
        paddingLeft: '24px',
      },
    },
    '&:not(div:last-of-type)': {
      paddingRight: '12px',
      [mediaQueries.md]: {
        paddingRight: '24px',
      },
    },
  })
);

export const StyledTrustpilotBadgeWrapper = styled.div({
  position: 'absolute !important' as 'absolute',
  top: 0,
  left: 0,
  opacity: 0,
  animation: `${fadeInKeyframes} 0.6s linear 0.1s forwards`,
});

const pulseOpacityKeyframes = keyframes({
  '0%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0.65,
  },
  '100%': {
    opacity: 1,
  },
});

export const StyledTrustpilotBadgePlaceholderWrapper = styled.div<{
  isDark: boolean;
}>(({ isDark }) => ({
  width: trustpilotBadgeSize.width,
  animation: `${pulseOpacityKeyframes} 1.5s linear infinite`,
  color: isDark ? theme.colors.white.default : theme.colors.black.default,
  '&, svg': {
    height: '100%',
  },
}));
