import React from 'react';
import { openDialog } from 'utils/dialog';
import hideable from 'utils/hideable';
import { SHARE_LINKS } from 'constants/common';
import { StyledWrapper } from './styledComponents';
import { ISocialButtonProps } from './types';

const onClick = (shareLink: SHARE_LINKS) => {
  const url = shareLink + window.location.href;
  openDialog(url);
};

const SocialButton: React.FC<ISocialButtonProps> = ({ type, children }) => (
  <StyledWrapper onClick={() => onClick(SHARE_LINKS[type])}>
    {children}
  </StyledWrapper>
);

export default hideable(SocialButton);
