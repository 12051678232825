import {
  PrismicLinkField,
  PrismicTextField,
  TGatsbyImg,
} from '@smartproxy-web/ui';
import {
  CarouselBlock,
  CarouselCardProps,
  CarouselCategory,
  CarouselProps,
} from './CarouselBlock';

interface PrismicCarouselBlockProps {
  primary: {
    title?: PrismicTextField;
    subtitle?: PrismicTextField;
    cta_label?: string;
    cta_url?: PrismicLinkField;
    show_cta?: boolean;
    show_tabs?: boolean;
    is_dark: boolean;
  };
  items: {
    category?: string;
    category_url?: PrismicLinkField;
    icon?: TGatsbyImg;
    itemTitle?: string;
    action_label?: string;
    action_url?: PrismicLinkField;
  }[];
}

export const PrismicCarouselBlock = (props: PrismicCarouselBlockProps) => (
  <CarouselBlock {...transformCarouselProps(props)} />
);

const transformCarouselProps = (prismicData: PrismicCarouselBlockProps) => {
  const getUniqueCategories = (items: PrismicCarouselBlockProps['items']) => {
    const map = new Map<string, CarouselCategory>();

    for (const item of items) {
      if (!item.category) continue;
      const categoryInMap = map.get(item.category);
      const categoryInMapHasUrl = !!categoryInMap?.url;
      if (categoryInMap && categoryInMapHasUrl) continue;
      map.set(item.category, {
        url: item.category_url?.url,
        label: item.category,
      });
    }

    return Array(...map.values());
  };

  const groupItemsByCategories = (
    items: PrismicCarouselBlockProps['items']
  ) => {
    const map = new Map<string, CarouselCardProps[]>();

    for (const item of items) {
      if (!item.category || !item.itemTitle) continue;
      let categoryItemsInMap = map.get(item.category);
      if (!categoryItemsInMap) {
        map.set(item.category, []);
      }
      categoryItemsInMap = map.get(item.category);

      map.set(item.category, [
        ...categoryItemsInMap,
        {
          categoryUrl: item.category_url,
          category: item.category,
          icon: item.icon,
          title: item.itemTitle,
          actionUrl: item.action_url?.url,
          actionLabel:
            !!item.action_url && !!item.action_label
              ? item.action_label
              : 'Learn more',
        },
      ]);
    }

    return Array(...map.values());
  };

  return {
    title: prismicData.primary.title,
    subtitle: prismicData.primary.subtitle,
    categories: getUniqueCategories(prismicData.items),
    items: groupItemsByCategories(prismicData.items),
    shouldShowCtaBtn: prismicData.primary.show_cta ?? false,
    shouldShowTabs: prismicData.primary.show_tabs ?? true,
    isDark: prismicData.primary.is_dark ?? true,
  } satisfies CarouselProps;
};
