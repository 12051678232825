import React from 'react';
import styled from '@emotion/styled';
import { Box } from '../Box';
import { mediaQueries, theme } from '../../theme';
import { useFlexWrapDetector } from '@smartproxy-web/shared/hooks';
import { TrackingTypes } from '@smartproxy-web/shared/helpers';

export type ColorsProps = 'default' | 'gradiant';

export type TabProps = {
  key: string;
  label: string;
};

type DynamicToggleProps = {
  onSelect?: (key: string, index: number) => void;
  tabs: TabProps[];
  onTrack?: (trackId: string, type?: TrackingTypes) => void;
  trackSelector?: (key: string, type?: TrackingTypes) => string;
  color?: ColorsProps;
};

// TODO will be renamed, improved and moved to new repo with nextjs. Including extracting tracking
export function DynamicToggle({
  onSelect,
  tabs,
  onTrack,
  trackSelector,
  color,
}: DynamicToggleProps) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
  const [activeTabElement, setActiveTabElement] =
    React.useState<HTMLDivElement | null>(null);
  const tabsContainerRef = React.useRef<HTMLDivElement | null>(null);
  const isWrapped = useFlexWrapDetector({
    parentRef: tabsContainerRef,
    offsetEnd: 1,
  });

  const offset = () => {
    let cumulativeWidth = 0;

    if (!tabsContainerRef.current) {
      return cumulativeWidth;
    }

    const tabs = tabsContainerRef.current.children;
    for (let i = 0; i < selectedTabIndex; i++) {
      cumulativeWidth += tabs[i].clientWidth;
    }

    return cumulativeWidth;
  };

  function handleSelectTab(key: string, index: number) {
    if (tabs.length === 2 && index === selectedTabIndex) {
      index === 0 ? selectTab(tabs[1].key, 1) : selectTab(tabs[0].key, 0);
      onTrack?.(`toggle:${tabs[index].key}`, 'trigger');
      return;
    }
    if (index === selectedTabIndex) return;

    selectTab(key, index);
    onTrack?.(`toggle:${tabs[index].key}`, 'trigger');
  }

  function selectTab(key: string, index: number) {
    setSelectedTabIndex(index);
    onSelect?.(key, index);
  }

  return (
    <DynamicToggleButtonContainer
      className="dynamic-toggle"
      ref={tabsContainerRef}
      showBorder={!isWrapped}
      color={color}
    >
      {tabs.map((tab, index) => (
        <DynamicTab
          key={tab.key}
          isActive={selectedTabIndex === index}
          ref={(el) => selectedTabIndex === index && setActiveTabElement(el)}
          onClick={() => handleSelectTab(tab.key, index)}
          showBorder={isWrapped}
          color={color}
          data-tracker={trackSelector?.(`toggle-${tab.key}`, 'trigger')}
        >
          {tab.label}
        </DynamicTab>
      ))}
      <DynamicSelectedTabBackground
        sx={{
          minWidth: `${activeTabElement?.clientWidth}px !important`,
          minHeight: `${activeTabElement?.clientHeight}px !important`,
          visibility: !isWrapped ? 'visible' : 'hidden',
          transform: { sm: `translateX(calc(${offset()}px + 2px))` },
        }}
        color={color}
      />
    </DynamicToggleButtonContainer>
  );
}

export default DynamicToggle;

export const DynamicToggleButtonContainer = styled.div<{
  showBorder: boolean;
  color?: ColorsProps;
}>(({ showBorder, color }) => ({
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  borderRadius: '24px',
  padding: '2px',
  border: showBorder ? `1px solid ${theme.colors.neutral.N04}` : 'none',
  display: 'flex',
  rowGap: '20px',
  backgroundColor: theme.colors.neutral.N00,
  margin: '0 auto',
  ':hover': {
    ...(showBorder
      ? {
          transition: 'all 0.1s ease-in-out 0s',
          background: theme.colors.neutral.N04,
        }
      : {}),
  },
  [mediaQueries.md]: {
    margin: 'none',
  },

  ...(color === 'gradiant' && {
    backgroundColor: theme.colors.neutral.N99,
    color: theme.colors.neutral.N00,
    border: showBorder ? `1px solid ${theme.colors.neutral.N90}` : 'none',

    ':hover': {
      ...(showBorder && {
        background: theme.colors.neutral.N94,
      }),
    },
  }),
}));

export const DynamicTab = styled.div<{
  isActive: boolean;
  showBorder: boolean;
  color?: ColorsProps;
}>(({ isActive, showBorder, color }) => ({
  padding: '0.75rem 1.5rem',
  minWidth: '160px',
  borderRadius: '24px',
  cursor: 'pointer',
  textAlign: 'center',
  color: isActive ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  backgroundColor: isActive && showBorder ? theme.colors.neutral.N99 : 'none',

  border: showBorder ? `1px solid ${theme.colors.neutral.N10}` : 'none',

  width: '100%',
  [mediaQueries.sm]: {
    width: 'fit-content',
  },

  ':hover': {
    ...(showBorder && !isActive
      ? {
          transition: 'all 0.1s ease-in-out 0s',
          background: theme.colors.neutral.N04,
        }
      : {}),
  },

  ...(color === 'gradiant' && {
    color: theme.colors.neutral.N00,

    ...(showBorder &&
      isActive && {
        backgroundColor: theme.colors.neutral.N99,
        border: '2px double transparent',
        backgroundImage: `linear-gradient(${theme.colors.neutral.N99}, ${theme.colors.neutral.N99}), ${theme.colors.gradient.default}`,
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
      }),
    ...(showBorder &&
      !isActive && {
        border: `1px solid ${theme.colors.neutral.N80}`,
      }),

    ':hover': {
      ...(showBorder &&
        !isActive && {
          background: 'inherit',
        }),
    },
  }),

  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: 1,
  fontWeight: 500,
  zIndex: 2,
  transition: '0.3s',
}));

export const DynamicSelectedTabBackground = styled(Box)<{
  color?: ColorsProps;
}>(({ color }) => ({
  position: 'absolute',
  minWidth: '160px',
  minHeight: '40px',
  top: '2px',
  left: 0,
  padding: '12px 24px',
  borderRadius: '24px',
  backgroundColor: theme.colors.neutral.N99,
  zIndex: 1,
  transition: '0.3s',

  ...(color === 'gradiant' && {
    backgroundColor: theme.colors.neutral.N99,
    border: '2px double transparent',
    backgroundImage: `linear-gradient(${theme.colors.neutral.N99}, ${theme.colors.neutral.N99}), ${theme.colors.gradient.default}`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',
  }),
}));
