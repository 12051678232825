import { debounce } from '@smartproxy-web/shared/utils';
import { useEffect, useState } from 'react';

type Direction = 'x' | 'y';

const directionsPropertyMap: Record<
  Direction,
  {
    scrollSize: string;
    scrollOffset: string;
    frameSize: string;
  }
> = {
  x: {
    scrollSize: 'scrollWidth',
    scrollOffset: 'scrollLeft',
    frameSize: 'clientWidth',
  },
  y: {
    scrollSize: 'scrollHeight',
    scrollOffset: 'scrollTop',
    frameSize: 'clientHeight',
  },
};

/**
 * Determines how much of an element is scrolled in an horizontal or vertical direction
 * Returns a number in range 0 - 1 for the scroll ratio
 * Returns -1 if the element is not scrollable
 */
export const useScrollRatio = <T extends HTMLElement>({
  el,
  direction,
  debounceTime = 0,
}: {
  el: T | null;
  direction: Direction;
  debounceTime?: number;
}) => {
  const [scrollRatio, setScrollRatio] = useState(-1);

  useEffect(() => {
    const handleTableScroll = () => {
      if (!el) return;

      const scrollSize = el[directionsPropertyMap[direction].scrollSize];
      const scrollOffset = el[directionsPropertyMap[direction].scrollOffset];
      const elementSize = el[directionsPropertyMap[direction].frameSize];

      if (!elementSize || !scrollSize) {
        return setScrollRatio(-1);
      }
      const availableScrollDistance = scrollSize - elementSize;

      if (availableScrollDistance === 0) {
        return setScrollRatio(-1);
      }

      setScrollRatio(scrollOffset / availableScrollDistance);
    };

    const debouncedTableScrollHandler = debounce(
      handleTableScroll,
      debounceTime
    );

    if (el) {
      debouncedTableScrollHandler();
      el.addEventListener('scroll', debouncedTableScrollHandler);
      window.addEventListener('resize', debouncedTableScrollHandler);
    }
    return () => {
      if (el) {
        el.removeEventListener('scroll', debouncedTableScrollHandler);
        window.removeEventListener('resize', debouncedTableScrollHandler);
      }
    };
  }, [el]);

  return scrollRatio;
};
