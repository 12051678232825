import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const HeadingWrapper = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
}));

export const ToggleWrapper = styled.div({
  marginTop: '32px',
  width: '100%',
  [mediaQueries.sm]: {
    width: 'fit-content',
    marginTop: '36px',
  },
});

export const CardsWrapper = styled.div({
  maxWidth: '64rem',
  width: '100%',
});

export const ListWrapper = styled.ul({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(324px, max-content))',
  gap: '1.25rem',
  justifyContent: 'center',
  padding: 'inherit',

  [mediaQueries.sm]: {
    gap: '1.5rem',
  },
});

export const ListItem = styled.li({
  listStyleType: 'none',
  minWidth: '20.25rem',
  padding: 0,
});
