import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const StyledSlice = styled.div({
  padding: '0 20px 32px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [mediaQueries.sm]: {
    padding: '12px 40px 36px',
  },
  [mediaQueries.md]: {
    padding: '60px 40px',
  },
});

export const UpperHeroPart = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '1024px',
  h1: {
    fontSize: theme.fonts.size.h2,
    lineHeight: '48px',
    textAlign: 'left',
    marginBottom: theme.spacings.S,
  },
  [mediaQueries.sm]: {
    h1: {
      fontSize: theme.fonts.size.h1,
      lineHeight: '54px',
    },
  },
  [mediaQueries.md]: {
    h1: {
      fontSize: '64px',
      lineHeight: '88px',
      textAlign: 'center',
    },
  },
});

export const CtaBlockContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '36px 0',
  rowGap: '16px',
  alignItems: 'flex-start',
  width: '100%',
  [mediaQueries.md]: {
    alignItems: 'center',
  },
});

export const Ctas = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  columnGap: theme.spacings.S,
  rowGap: '20px',
  [mediaQueries.md]: {
    flexDirection: 'row',
    margin: '0 auto',
    justifyContent: 'center',
  },
});

export const CtaContainer = styled.div({
  width: '100%',
  span: {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
  },
  [mediaQueries.md]: {
    width: '240px',
  },
});

export const MoneyBack = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '8px',
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontFamily: theme.fonts.family.additional,
  svg: {
    width: '12px',
    height: '12px',
  },
});

export const Features = styled.ul({
  display: 'flex',
  listStyle: 'none',
  padding: 0,
  flexWrap: 'wrap',
  rowGap: '20px',
  columnGap: '16px',
  maxWidth: '1024px',
  width: '100%',
  [mediaQueries.md]: {
    justifyContent: 'space-between',
  },
});

export const ListItem = styled.li({
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: theme.spacings.XS,
  padding: 0,
  width: '158px',
  span: {
    fontFamily: theme.fonts.family.additional,
  },
});
