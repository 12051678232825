import { Highlight, themes } from 'prism-react-renderer';
import { StyledCodeBlockLine } from './styledComponents';

interface CodeBlockProps {
  code?: string;
  language: 'jsx' | 'tsx' | 'go' | 'cpp';
  showLineNumbers?: boolean;
  fontSize?: number;
}

export const CodeBlock = ({
  code = '',
  language,
  fontSize,
  showLineNumbers = false,
}: CodeBlockProps) => {
  const updatedTheme = {
    ...themes.palenight,
    plain: {
      ...themes.palenight.plain,
      backgroundColor: 'transparent',
    },
  };

  return (
    <div>
      <Highlight code={code} theme={updatedTheme} language={language}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre style={style} className={className}>
            {tokens.map((line, i) => (
              <StyledCodeBlockLine
                key={i}
                {...getLineProps({ line })}
                fontSize={fontSize}
                maxLineNumberCharSize={`${tokens.length}`.length}
              >
                {showLineNumbers && (
                  <span className="token-line-number">{i + 1}</span>
                )}
                <div>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </div>
              </StyledCodeBlockLine>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  );
};
