import { CarouselAutoplay, CarouselNavigation } from '@smartproxy-web/ui';
import { SwiperOptions } from 'swiper/types';

export const slidesGap = 25;

export const swiperConfig = {
  modules: [CarouselAutoplay, CarouselNavigation],
  navigation: true,
  spaceBetween: slidesGap,
  slidesPerView: 5,
  freeMode: true,
  allowTouchMove: false,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
  speed: 4000,
  breakpoints: {
    768: {
      slidesPerView: 8,
      spaceBetween: 0,
    },
  },
} satisfies SwiperOptions;
