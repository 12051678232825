import { theme } from '@smartproxy-web/ui';
import { GroupedDataProps, ItemProps } from './types';

export function groupDataByColumn(items: ItemProps[]): GroupedDataProps {
  const rows: ItemProps[][] = [];
  const rowCountByColumn = {};

  for (let i = 0; i < items.length; i++) {
    const cell = items[i];
    const cellColumn = cell.column;

    if (rowCountByColumn[cellColumn] === undefined) {
      rowCountByColumn[cellColumn] = 0;
    }
    if (rows[rowCountByColumn[cellColumn]] === undefined) {
      rows[rowCountByColumn[cellColumn]] = [];
    }

    rows[rowCountByColumn[cellColumn]][+cellColumn - 1] = cell;
    rowCountByColumn[cellColumn] = rowCountByColumn[cellColumn] + 1;
  }

  let maxColumnCount = 0;

  for (const row of rows) {
    if (row.length > maxColumnCount) {
      maxColumnCount = row.length;
    }
  }

  return { rows, maxColumnCount };
}

export const normalizeButtonColor = (color) => {
  const normalizedColor = color.toLowerCase();
  switch (normalizedColor) {
    case 'blue border':
      return { color: 'blue', variant: 'outlined' };
    case 'black':
      return { color: 'black', variant: 'filled' };
    case 'black border':
      return { color: 'black', variant: 'outlined' };
    default:
      return { color: 'blue', variant: 'filled' };
  }
};

export const getBackgroundColor = (color) =>
  color && color.toLowerCase() === 'gray'
    ? theme.colors.neutral.N02
    : theme.colors.neutral.N00;
