import styled from '@emotion/styled';
import { richTextParagraphElements } from '@smartproxy-web/shared/constants';
import { mediaQueries, theme, toRem } from '@smartproxy-web/ui';

const lineHeightRatio = 1.5;
const fontSize = 16;

export const lineHeight = fontSize * lineHeightRatio;

export const StyledTabbedCodeBlockWrapper = styled.section<{
  isDarkTheme: boolean;
  compact: boolean;
}>(({ compact, isDarkTheme }) => ({
  padding: '32px 20px',
  width: '100%',
  fontSize: theme.fonts.size.p_medium,
  overflow: 'hidden',

  [mediaQueries.md]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },

  '& > div': {
    margin: '0 auto',
    maxWidth: '1120px',
  },

  '[data-theme=dark]': {
    display: 'none',
  },
  '[data-theme=light]': {
    display: 'flex',
  },

  ...(isDarkTheme && {
    background: theme.colors.neutral.N99,

    [String(StyledTabbedCodeBlockTitleWrapper)]: {
      color: theme.colors.neutral.N00,
    },
    '[data-theme=dark]': {
      display: 'flex',
    },
    '[data-theme=light]': {
      display: 'none',
    },
  }),

  ...(!compact && {
    [String(StyledCodeWindowWrapper)]: {
      [mediaQueries.md]: {
        gap: toRem(24),
      },
    },
    [String(StyledCodeBlockLanguagesWrapper)]: {
      [mediaQueries.md]: {
        paddingRight: toRem(48),
      },
    },
  }),
}));

export const StyledTabbedCodeBlockHeaderWrapper = styled.div({
  margin: '0 auto',
  maxWidth: '1024px',
  display: 'block',
  marginBottom: '32px',

  [mediaQueries.md]: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacings.L,
  },
});

export const StyledTabbedCodeBlockTitleWrapper = styled.div({
  marginBottom: theme.spacings.S,
});

export const StyledTabbedCodeBlockSubtitleWrapper = styled.div({
  marginBottom: theme.spacings.S,

  [richTextParagraphElements]: {
    textAlign: 'left',
    color: theme.colors.neutral.N50,

    [mediaQueries.md]: {
      textAlign: 'center',
    },
  },
});

export const StyledTabbedCodeBlockActions = styled.div({
  display: 'flex',
  gap: theme.spacings.S,
  flexDirection: 'column',
  width: '100%',
  '> a > div': {
    width: '100%',
  },

  [mediaQueries.md]: {
    justifyContent: 'center',
    flexDirection: 'row',
    '> a > div': {
      minWidth: '200px',
    },
  },
});

export const StyledCodeWindowWrapper = styled.div({
  maxWidth: '1120px',
  padding: '24px 0',
  background: theme.colors.neutral.N99,
  border: `1px solid ${theme.colors.neutral.N80}`,
  borderRadius: '12px',
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: '24px',

  [mediaQueries.md]: {
    gap: 0,
    padding: '36px 0',
    flexDirection: 'row',
  },
});

export const StyledCodeBlockLanguagesWrapper = styled.ul({
  listStyle: 'none',
  display: 'flex',
  gap: '24px',
  flexShrink: 0,
  justifyContent: 'flex-start',
  borderBottom: `1px solid ${theme.colors.neutral.N80}`,
  flexWrap: 'wrap',
  margin: '0 24px',
  padding: '0 0 24px 0',

  [mediaQueries.md]: {
    padding: '0 24px',
    margin: 0,
    flexDirection: 'column',
    borderRight: `1px solid ${theme.colors.neutral.N80}`,
    borderBottom: 'none',
    flexWrap: 'initial',
  },
});

export const StyledCodeBlockLanguageWrapper = styled.label({
  padding: 0,
  color: theme.colors.neutral.N40,

  'input[type=radio] + span': {
    fontFamily: theme.fonts.family.mono,
    lineHeight: toRem(20),
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
  },
  'input[type=radio] + span:hover, input[type=radio]:focus + span': {
    textDecoration: 'underline',
  },
  'input[type=radio]:checked + span': {
    color: theme.colors.neutral.N00,
    textDecoration: 'underline',
  },
});

const CODE_AREA_MAX_HEIGHT = 400;
const COPY_BUTTON_SIZE = 24;

export const StyledCodeAreaWrapper = styled.div<{
  height: number;
}>(({ height }) => ({
  flexGrow: '1',
  fontFamily: theme.fonts.family.mono,
  minHeight: Math.max(COPY_BUTTON_SIZE, height),
  height,
  maxHeight: CODE_AREA_MAX_HEIGHT,
  overflow: 'hidden',
  position: 'relative',

  '&::after': {
    position: 'absolute',
    ...(height > CODE_AREA_MAX_HEIGHT && { content: '""' }),
    bottom: 0,
    left: 0,
    width: '100%',
    height: COPY_BUTTON_SIZE,
    background: `linear-gradient(180deg,transparent,${theme.colors.neutral.N99} 100%)`,
  },
  [String(StyledCodeBlockWrapper)]: {
    ...(height <= CODE_AREA_MAX_HEIGHT && {
      paddingRight: toRem(COPY_BUTTON_SIZE + 32),
    }),
    ...(height > CODE_AREA_MAX_HEIGHT && {
      paddingBottom: toRem(48),
    }),
  },
  [mediaQueries.md]: {
    [String(StyledCodeBlockWrapper)]: {
      ...(height <= CODE_AREA_MAX_HEIGHT && {
        paddingRight: toRem(COPY_BUTTON_SIZE + 48),
      }),
    },
  },
}));

export const StyledCodeBlockWrapper = styled.div({
  overflowY: 'auto',
  height: '100%',
  padding: `0 24px`,
});

export const StyledCodeBlockCopyWrapper = styled.div({
  position: 'absolute',
  right: toRem(24),
  bottom: toRem(36),
  zIndex: 1,

  button: {
    height: COPY_BUTTON_SIZE,
    minWidth: COPY_BUTTON_SIZE,
    width: COPY_BUTTON_SIZE,
    paddingLeft: 0,
    paddingRight: 0,

    '&:disabled': {
      opacity: 1,
    },
  },
});
