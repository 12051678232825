import { IProxyCell } from '.';

export const groupTableData = (data: IProxyCell[]) =>
  data.reduce((itemsByKeyValue, item) => {
    const value = item.column_name.text.trim();
    itemsByKeyValue[value] = (itemsByKeyValue[value] || []).concat(item);
    return itemsByKeyValue;
  }, {});

const getUniqueItems = (value: string, index: number, self: string[]) =>
  self.findIndex((x) => x === value) === index;

export const getTableHeader = (data: IProxyCell[]) => {
  const newTrimedArr = data.map((a) => a.column_name.text.trim());
  return newTrimedArr.map((item) => item).filter(getUniqueItems);
};
