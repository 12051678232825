import {
  StyledDescription,
  StyledReadMoreContainer,
} from '../styledComponents';
import { IconChevronRight, shortenText } from '@smartproxy-web/ui';
import { useBuildContext } from '../../../contexts';
import { translations } from '../../BlogPostIntro/constants';
import { RichText } from '../../RichText';
import { DescriptionProps } from '../types';

export const Description = ({ description, link }: DescriptionProps) => {
  const { lang } = useBuildContext();

  const isReadMoreNeeded =
    lang === 'cn' ? description.text.length > 50 : description.text.length > 85;

  const shortenedText =
    lang === 'cn'
      ? shortenText(description.text, 50)
      : shortenText(description.text, 85);

  return (
    <StyledDescription>
      {!!link && isReadMoreNeeded ? (
        <>
          {shortenedText}{' '}
          <StyledReadMoreContainer>
            {translations.read_more[lang]} <IconChevronRight />
          </StyledReadMoreContainer>
        </>
      ) : (
        <RichText field={description} />
      )}
    </StyledDescription>
  );
};
