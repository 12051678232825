import { IArticle } from 'pageUtils/blog/types';
import { BLOG_SLICES } from 'templates/Blog/constants';

export const getArticleUrl = (type: string, tags: string[]) =>
  `/${type}/${tags.join('/')}`;

export const getFilteredArticles = (articles: IArticle[], topicLabel: string) =>
  articles.filter(({ data: { body } }) => {
    const topics = body.filter(
      (bodyItem) =>
        bodyItem.slice_type === BLOG_SLICES.SocialButtonsBar &&
        bodyItem.items.some(
          ({ topic }) =>
            !!topic?.document && topic?.document.data.topic_label === topicLabel
        )
    );

    return !!topics.length;
  });
