import { Slice, SliceMap } from 'types';

export const getTemplates = <T extends string, E extends Slice>(
  slices: Array<E>,
  sliceMap: SliceMap<T>
) =>
  slices
    .filter(
      (slice) => slice && Object.keys(sliceMap).includes(slice.slice_type)
    )
    .map((slice) => ({
      slice: slice,
      Component: sliceMap[slice.slice_type],
    }));
