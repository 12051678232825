import {
  StyledCountdownTimeComponent,
  StyledCountdownTimeComponentsWrapper,
  StyledCountdownTimeWrapper,
  StyledCountdownTimer,
} from './styledComponents';
import { getCountForm } from './utils';

interface CountdownTimerProps {
  label?: string;
  timeComponents: {
    days: string;
    mins: string;
    hours: string;
    secs: string;
  };
  isTimerVisible?: boolean;
  lang: string;
}

export const CountdownTimer = ({
  label,
  timeComponents,
  isTimerVisible = true,
  lang,
}: CountdownTimerProps) => (
  <StyledCountdownTimer>
    <span>
      {!!label && label}
      {isTimerVisible && !!label && <span className="hide-on-mobile"> -</span>}
    </span>
    {isTimerVisible && (
      <StyledCountdownTimeComponentsWrapper data-hidden="false">
        <CountdownTimeComponent
          time={timeComponents.days}
          unit={
            lang === 'cn'
              ? '天'
              : getCountForm(Number(timeComponents.days), 'DAY', 'DAYS')
          }
        />
        <span>:</span>
        <CountdownTimeComponent
          time={timeComponents.hours}
          unit={
            lang === 'cn'
              ? '小时'
              : getCountForm(Number(timeComponents.hours), 'HR', 'HRS')
          }
        />
        <span>:</span>
        <CountdownTimeComponent
          time={timeComponents.mins}
          unit={lang === 'cn' ? '分' : 'MIN'}
        />
        :
        <CountdownTimeComponent
          time={timeComponents.secs}
          unit={lang === 'cn' ? '秒' : 'SEC'}
        />
      </StyledCountdownTimeComponentsWrapper>
    )}
  </StyledCountdownTimer>
);

const CountdownTimeComponent = ({
  time,
  unit,
}: {
  time: string;
  unit: string;
}) => {
  const MIN_PLACEHOLDER_CHAR_LENGTH = 1;

  const getPlaceholderCharacters = (length: number) => {
    return ''.padStart(Math.max(MIN_PLACEHOLDER_CHAR_LENGTH, length), '0');
  };

  return (
    <StyledCountdownTimeComponent>
      <StyledCountdownTimeWrapper>
        <span data-hidden="false">{time}</span>
        <span data-hidden="true">{getPlaceholderCharacters(time.length)}</span>
      </StyledCountdownTimeWrapper>
      <small>{unit}</small>
    </StyledCountdownTimeComponent>
  );
};
