import React from 'react';

const hideable =
  (Component: React.ElementType | string) =>
  ({ isVisible, ...props }: any) => {
    if (isVisible) {
      return <Component {...props} />;
    }
    return null;
  };

export default hideable;
