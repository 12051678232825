import { IconQuote, PrismicTextField } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { QuoteComponentWrapper, QuoteContent } from './styledComponents';

export interface QuoteProps {
  primary: {
    text: PrismicTextField;
    background?: string;
  };
}

export const QuoteComponent = ({
  primary: { text, background },
}: QuoteProps) => {
  return (
    <QuoteComponentWrapper backgroundColor={background}>
      <QuoteContent>
        <IconQuote />
        <RichText field={text} />
      </QuoteContent>
    </QuoteComponentWrapper>
  );
};

export default QuoteComponent;
