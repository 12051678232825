import { IconCheckMarkGreenv2 } from 'images';
import React from 'react';
import { PrismicTextField } from 'types';
import {
  StyledCheckmarkListItemWrapper,
  StyledFeaturesWrapper,
} from '../styledComponents';

interface FeaturesProps {
  features?: PrismicTextField;
}

export const Features: React.FC<FeaturesProps> = ({ features }) => (
  <StyledFeaturesWrapper>
    {features?.richText?.map((feature) => (
      <StyledCheckmarkListItemWrapper key={feature.text}>
        <IconCheckMarkGreenv2 />
        <p>{feature.text}</p>
      </StyledCheckmarkListItemWrapper>
    ))}
  </StyledFeaturesWrapper>
);
