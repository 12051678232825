import { ThemeColors, colors } from './colors';
import { ThemeFonts, fonts } from './fonts';
import { ThemeSpacings, spacings } from './spacings';

export interface Theme {
  colors: ThemeColors;
  fonts: ThemeFonts;
  spacings: ThemeSpacings;
}

export const theme: Theme = {
  colors,
  fonts,
  spacings,
};
