import styled from '@emotion/styled';
import {
  Box,
  Text,
  generateMediaQuery,
  mediaQueries,
  theme,
  toRem,
} from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';
import { Slice } from '../Slice';

export const StyledCarouselBlock = styled(Slice)({
  '[data-slice-header] h2': {
    [`${generateMediaQuery('md', 'max-width')}`]: {
      fontSize: toRem(24),
    },
  },
});

export const StyledCarouselItemActionWrapper = styled(Text)({
  marginTop: toRem(18),
  color: theme.colors.primary.P99,

  '& svg': {
    transition: '0.16s ease-in-out',
  },
});

export const StyledCarouselItem = styled(GatsbyLink)<{ isDark: boolean }>(
  ({ isDark }) => ({
    width: '100%',
    flexShrink: 0,
    height: '100%',
    padding: '24px',
    paddingBottom: '16px',
    borderRadius: '24px',
    border: isDark
      ? `1px solid ${theme.colors.neutral.N80}`
      : `1px solid ${theme.colors.neutral.N10}`,
    color: theme.colors.neutral.N00,
    display: 'inline-flex',
    flexDirection: 'column',

    '&:hover': {
      background: isDark ? theme.colors.neutral.N94 : theme.colors.neutral.N02,
    },

    [`&:hover ${StyledCarouselItemActionWrapper} svg`]: {
      transform: 'translateX(4px)',
    },
    [String(StyledCarouselItemTitleWrapper)]: {
      color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
    },
  })
);

export const StyledCarouselItemImage = styled(Box)({
  height: '48px',
  width: '48px',

  '&>div, img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

export const StyledCarouselItemTitleWrapper = styled(Box)({
  flexGrow: 1,
  marginTop: '16px',

  p: {
    fontSize: theme.fonts.size.h4,
    fontFamily: theme.fonts.family.additional,
    fontWeight: '700',
  },
});

export const StyledCarouselCta = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 32,
  [mediaQueries.md]: {
    marginTop: 48,
  },

  '& a, & a > div': {
    width: '100%',
    [mediaQueries.md]: {
      width: 'initial',
    },
  },

  button: {
    width: '100%',

    [mediaQueries.md]: {
      width: 'initial',
    },
  },
});
