import React from 'react';
import {
  BiggerPlansContainer,
  Wrapper,
  StyledTitle,
  TextAndIconContainer,
  StyledDescriptionContainer,
} from './styledComponents';
import { IBiggerPlansBanner } from './types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from '../../hooks';
import { Box, GatsbyImg, theme } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { LinkButton } from '../LinkButton';

export const BiggerPlansBanner: React.FC<IBiggerPlansBanner> = ({
  primary: {
    icon,
    title,
    description,
    cta_label: ctaLabel,
    cta_link: ctaLink,
    background,
    is_dark: isDark,
  },
}) => {
  const { id: sliceId } = useSliceDetails();
  const trackingSelector = initBuildTrackingId({
    type: 'cta',
    section: 'simple-banner',
  });

  const ctaColor = isDark ? 'white' : 'black';
  const isDescriptionAdded = !!description?.richText[0]?.text;

  return (
    <Wrapper backgroundColor={background} isDark={isDark} id="simple-banner">
      <BiggerPlansContainer>
        <TextAndIconContainer isDescriptionAdded={isDescriptionAdded}>
          <Box
            sx={{
              width: '36px',
              height: '36px',
              marginBottom: { base: '20px', md: 0 },
              marginRight: { base: 0, md: theme.spacings.S },
            }}
          >
            <GatsbyImg image={icon} />
          </Box>
          <div>
            <StyledTitle>{title.text}</StyledTitle>
            {isDescriptionAdded && (
              <StyledDescriptionContainer>
                <RichText field={description} />
              </StyledDescriptionContainer>
            )}
          </div>
        </TextAndIconContainer>
        <Box
          sx={{
            zIndex: 1,
            marginLeft: {
              base: 0,
              lg: '48px',
            },
          }}
        >
          <LinkButton
            fullWidth
            color={ctaColor}
            variant="outlined"
            data-tracker={trackingSelector({ context: ctaLabel?.text })}
            onClick={() => {
              logPostHogEvent(trackingSelector({ context: ctaLabel?.text }));
              if (sliceId) {
                logGAElementClickEvent({
                  elementId: ctaLabel!.text,
                  sectionId: sliceId,
                });
              }
            }}
            to={ctaLink?.url}
          >
            {ctaLabel?.text}
          </LinkButton>
        </Box>
      </BiggerPlansContainer>
    </Wrapper>
  );
};
