import styled from '@emotion/styled';
import { COMPACT_HEADER_MEDIA_QUERY } from '../../constants';
import { StyledNavMenuList } from '../styledComponents';

export const StyledLeftNavMenuList = styled(StyledNavMenuList)({
  width: '100%',
  gap: 24,

  '.show-on-mobile': {
    display: 'none',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      display: 'flex',
    },
  },
  [COMPACT_HEADER_MEDIA_QUERY]: {
    display: 'none',
  },
});
