import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconDone,
  IconFacebook,
  IconLink,
  IconLinkedIn,
  IconShare,
  IconTwitter,
  Text,
  theme,
} from '@smartproxy-web/ui';
import { useState } from 'react';
import {
  StyledPostShareButton,
  StyledShareOptionButton,
  StyledShareOptionLink,
  copySuccessVisibleTimeMs,
} from './styledComponents';
import { useSliceDetails } from '../../hooks';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { useBuildContext } from '../../contexts';
import { buildShareLinks } from '@smartproxy-web/shared/utils';
import { translations } from './constants';

interface PostShareMenuProps {
  url: string;
  title?: string;
  excerpt?: string;
}

export const PostShareMenu = ({ url, title, excerpt }: PostShareMenuProps) => {
  const { lang } = useBuildContext();
  const { id: sliceId, name: sliceName } = useSliceDetails();
  const createTrackingID = initBuildTrackingId({
    section: sliceName,
  });
  const shareBtnTrackingID = createTrackingID({
    context: 'share-post',
    type: 'button',
  });
  const copyLinkBtnTrackingID = createTrackingID({
    context: 'copy-link',
    type: 'button',
  });

  const [showingCopyMessage, setShowingCopyMessage] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const shareLinks = buildShareLinks({ url, title: title ?? '' });

  const shareData: ShareData = {
    title,
    text: excerpt,
    url,
  };

  const logEvents = (trackingId: string) => {
    logPostHogEvent(trackingId);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: trackingId,
        sectionId: sliceId,
      });
    }
  };

  const shareArticle = async () => {
    logEvents(shareBtnTrackingID);
    if (!navigator.canShare?.(shareData)) {
      return setIsDropdownOpen(true);
    }

    try {
      await navigator.share(shareData);
    } catch (error) {
      console.error(error);
    }
  };

  const copyLink = async () => {
    if (showingCopyMessage) return;

    logEvents(copyLinkBtnTrackingID);
    setShowingCopyMessage(true);

    try {
      await navigator.clipboard.writeText(url);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsDropdownOpen(false);
        setShowingCopyMessage(false);
      }, copySuccessVisibleTimeMs);
    }
  };

  const socialShareOptions = [
    {
      id: createTrackingID({
        context: 'linkedin-share',
        type: 'link',
      }),
      Icon: IconLinkedIn,
      label: 'LinkedIn',
      url: shareLinks.linkedin,
    },
    {
      id: createTrackingID({
        context: 'twitter-share',
        type: 'link',
      }),
      Icon: IconTwitter,
      label: 'Twitter',
      url: shareLinks.twitter,
    },
    {
      id: createTrackingID({
        context: 'facebook-share',
        type: 'link',
      }),
      Icon: IconFacebook,
      label: 'Facebook',
      url: shareLinks.facebook,
    },
  ];

  return (
    <DropdownMenu
      open={isDropdownOpen}
      onOpenChange={(open) => setIsDropdownOpen(open)}
    >
      <DropdownMenuTrigger asChild>
        <StyledPostShareButton
          size="sm"
          variant="text"
          color="gray"
          aria-label="Share Blog Post"
          onClick={shareArticle}
          data-tracker={shareBtnTrackingID}
          compact
        >
          <IconShare />
        </StyledPostShareButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        onFocusOutside={() => setIsDropdownOpen(false)}
      >
        <Box
          sx={{
            border: `1px solid ${theme.colors.neutral.N20}`,
            borderRadius: 8,
            boxShadow: '0px 4px 16px 0px rgba(29, 29, 31, 0.08)',
            background: theme.colors.neutral.N00,
            minWidth: '8rem',
            overflow: 'hidden',
          }}
        >
          {socialShareOptions.map(({ id, Icon, label, url }) => (
            <DropdownMenuItem key={id}>
              <StyledShareOptionLink
                data-tracker={id}
                onClick={() => logEvents(id)}
                to={url}
              >
                <Icon />
                <Text>{label}</Text>
              </StyledShareOptionLink>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <StyledShareOptionButton
              variant="text"
              color="gray"
              leftIcon={showingCopyMessage ? <IconDone /> : <IconLink />}
              onClick={copyLink}
              showingCopyMessage={showingCopyMessage}
              data-tracker={copyLinkBtnTrackingID}
            >
              <Text>
                {showingCopyMessage
                  ? translations.copied_link[lang]
                  : translations.copy_link[lang]}
              </Text>
            </StyledShareOptionButton>
          </DropdownMenuItem>
        </Box>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
