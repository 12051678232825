import { useEffect, useState } from 'react';
import { ItemsContainer } from './styledComponents';
import { CardComponentProps, CardsProps } from './types';
import { useShowMoreLess, useSliceDetails } from '../../hooks';
import { Box, Button } from '@smartproxy-web/ui';
import { BottomText } from './components/BottomText';
import { Card } from './components/Card';
import { logEvent } from '../../helpers';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';

export const Tabs = ({
  bottom_logo: bottomLogo,
  bottom_text: bottomText,
  repeatable_zone: repeatableZone,
  tab_name: tabName,
  isDark,
}: CardsProps) => {
  const { id: sliceId } = useSliceDetails();
  const { showAll, handleShowMoreLess, buttonText } = useShowMoreLess();
  const [tabData, setTabData] = useState<CardComponentProps[]>([]);
  const color = isDark ? 'white' : 'black';

  // checking window width to know how many cards to display
  const getWindowWidth = () =>
    typeof window !== 'undefined' ? window.innerWidth : 0;

  const getInitialColumnsNumber = () => (getWindowWidth() < 768 ? 2 : 3);

  const isButtonVisible = (repeatableZoneLength, columnsNumber) =>
    (getWindowWidth() < 768 && repeatableZoneLength > 2) ||
    (getWindowWidth() >= 768 && repeatableZoneLength > columnsNumber);

  const [isShowMoreButtonVisible, setIsShowMoreButtonVisible] = useState(() =>
    isButtonVisible(repeatableZone.length, getInitialColumnsNumber())
  );
  const [columnsNumber, setColumnsNumber] = useState(getInitialColumnsNumber);

  useEffect(() => {
    const handleResize = () => {
      const newColumnsNumber = getWindowWidth() < 768 ? 2 : 3;
      setColumnsNumber(newColumnsNumber);
      setIsShowMoreButtonVisible(
        isButtonVisible(repeatableZone.length, newColumnsNumber)
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [repeatableZone.length]);

  useEffect(() => {
    setTabData(
      showAll ? repeatableZone : repeatableZone.slice(0, columnsNumber)
    );
  }, [showAll, repeatableZone, columnsNumber]);

  const buildTrackingId = initBuildTrackingId({
    section: 'social-proof',
  });

  const ctaTrackingId = buildTrackingId({
    context: tabName.text + '-' + buttonText,
    type: 'cta',
  });

  const ButtonShowMoreLess = (
    <Button
      fullWidth
      variant="outlined"
      color={color}
      data-tracker={ctaTrackingId}
      onClick={() => {
        handleShowMoreLess();
        logEvent({
          sliceId,
          eventId: ctaTrackingId,
          elementId: tabName.text,
        });
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: { base: 'flex-start', md: 'center' },
        width: '100%',
      }}
    >
      <ItemsContainer>
        {tabData.map((cardProps, index) => (
          <Card
            key={index}
            {...cardProps}
            isDark={isDark}
            tabName={tabName}
            index={index}
          />
        ))}
      </ItemsContainer>
      {!!bottomText?.text && (
        <BottomText
          bottomLogo={bottomLogo}
          bottomText={bottomText}
          isDark={isDark}
        />
      )}
      {isShowMoreButtonVisible && (
        <Box
          sx={{
            marginTop: { base: '32px', sm: '60px', md: '80px' },
            width: { base: '100%', sm: 'fit-content' },
          }}
        >
          {ButtonShowMoreLess}
        </Box>
      )}
    </Box>
  );
};
