import styled from '@emotion/styled';

interface IBackground {
  background: string;
  height: number;
}

export const SpacingBackgroundWrapper = styled.div<IBackground>(
  ({ background, height }) => ({
    background,
    width: '100%',
    height: `${height}px`,
  })
);
