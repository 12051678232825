import styled from '@emotion/styled';
import { mediaQueries, theme } from '@smartproxy-web/ui';
import { COMPACT_HEADER_MEDIA_QUERY } from './constants';

export const BannerContainer = styled.div<{ offset: number }>(({ offset }) => ({
  display: 'flex',
  width: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 6,
  transformstyle: 'preserve-3d',
  transform: `translateY(${offset}px)`,
  transition: 'transform 1s linear',
  padding: '44px 20px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px hsla(0,0%,100%,.2)',
  boxShadow: '1px 0 24px 0 rgb(0 0 0 / 12%)',
  backgroundImage: 'linear-gradient(to right, #01F0D3, #00D1FF, #9A4BFF)',
  height: 'auto',
  minHeight: '52px',
  h5: {
    fontFamily: theme.fonts.family.additional,
  },
  [mediaQueries.md]: {
    top: '10px',
    padding: ' 0 40px',
  },
}));

export const ContentContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  rowGap: '20px',
  flex: 'none',
  height: 'auto',
  marginTop: '8px',
  justifyContent: 'center',
  [mediaQueries.md]: {
    height: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 0,
  },
});

export const CtaContainer = styled.div({
  width: '100%',
  [mediaQueries.md]: {
    width: '128px',
  },
});

export const Arrow = styled.div<{ isRight?: boolean }>(({ isRight }) => ({
  display: 'flex',
  justifyContent: 'center',
  transform: isRight ? 'rotate(-90deg)' : 'rotate(90deg)',
  cursor: 'pointer',
  width: '16px',
  height: '16px',
  svg: {
    path: {
      fill: theme.colors.neutral.N99,
    },
  },
}));

export const Banners = styled.div({
  display: 'flex',
  maxWidth: '1024px',
  width: '100%',
  margin: '0 12px',
  [mediaQueries.sm]: {
    margin: '0 24px',
  },
});

export const BannerWrapper = styled.div({
  maxWidth: '1440px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [COMPACT_HEADER_MEDIA_QUERY]: {
    width: '85%',
    justifyContent: 'center',
  },
});

export const ContentText = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  div: {
    width: '16px',
    height: '16px',
  },
});

export const CloseButton = styled.div({
  cursor: 'pointer',
  marginLeft: '110px',
  position: 'absolute',
  right: '20px',
  top: '20px',

  [COMPACT_HEADER_MEDIA_QUERY]: {
    marginLeft: '20px',
  },
  [mediaQueries.md]: {
    position: 'initial',
  },
});
