import React from 'react';
import { CategoryItemLink } from './CategoryItemLink';
import { ICategoriesContainer } from './types';
import { Box } from 'components/v2/Box';
import { CategoryBanner } from './CategoryBanner';

export const CategoriesAdditionalItems: React.FC<ICategoriesContainer> = ({
  category,
  handleClose,
  buildTrackingId,
}) => {
  const isAdditionalItemsVisible =
    !!category.additionalFeaturesItems?.length ||
    !!category.additionalTargetsItems?.length ||
    !!category.additionalBannerTitle;

  if (!isAdditionalItemsVisible) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '36px' }}>
      {!!category.additionalFeaturesItems?.length && (
        <CategoryItemLink
          link={category.additionalFeaturesUrl}
          title={category.additionalFeaturesTitle}
          features={category.additionalFeaturesItems}
          handleClose={handleClose}
          buildTrackingId={buildTrackingId}
        />
      )}
      {!!category.additionalTargetsItems?.length && (
        <CategoryItemLink
          link={category.additionalTargetsUrl}
          title={category.additionalTargetsTitle}
          features={category.additionalTargetsItems}
          handleClose={handleClose}
          buildTrackingId={buildTrackingId}
        />
      )}
      {!!category.additionalBannerTitle && (
        <CategoryBanner
          title={category.additionalBannerFieldTitle}
          handleClose={handleClose}
          buildTrackingId={buildTrackingId}
          {...category}
        />
      )}
    </Box>
  );
};
