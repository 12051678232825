import styled from '@emotion/styled';
import { BadgeSize, StyledBadgeProps } from './types';
import { theme } from '../../theme';
import { sizeStyles } from './constants';

const hovers = ({ color, variant }) => {
  if (variant === 'filled') {
    return {
      backgroundColor: 'transparent',
      color: theme.colors[color].default,
    };
  }

  return {
    backgroundColor: theme.colors[color].default,
    color: theme.colors.neutral.N99,
  };
};

export const getSizeStyles = ({ size }: { size: BadgeSize }) =>
  sizeStyles[size];

const outlinedGradientBadge = {
  position: 'relative',
  borderColor: 'transparent',

  '&::after': {
    position: 'absolute',
    content: '""',
    inset: -1,
    background: theme.colors.gradient.default,
    padding: 1,
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
};

export const StyledBadge = styled.div<StyledBadgeProps>(
  ({ color, fullWidth, variant, size, forcedEvent }) => {
    const sizeStyles = getSizeStyles({ size });

    return {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      lineHeight: '1',
      fontFamily: theme.fonts.family.additional,
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      alignSelf: 'center',
      width: fullWidth ? '100%' : 'max-content',
      ...(variant === 'outlined' && {
        border: '1px solid',
        borderColor: theme.colors[color].default,
        color: theme.colors[color].default,
        ...(color === 'gray' && {
          borderColor: theme.colors.neutral.N20,
          color: theme.colors.neutral.N99,
        }),
        ...(color === 'gradient' && {
          ...outlinedGradientBadge,
          '&::after': {
            ...outlinedGradientBadge['&::after'],
            borderRadius: sizeStyles.borderRadius,
          },
        }),
      }),

      ...(variant === 'filled' && {
        color: theme.colors.neutral.N00,
        background: theme.colors[color].default,
      }),

      ...(forcedEvent === 'hover' && {
        ...hovers({ color, variant }),
      }),

      ...sizeStyles,
    };
  }
);
