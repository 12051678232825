export const defaultNofollowExclusions = [
  {
    domain: 'smartproxy.com',
  },
  {
    domain: 'dashboard.smartproxy.com',
  },
  {
    domain: 'help.smartproxy.com',
  },
];

export const sameTabDomains = [
  'dashboard.smartproxy.com',
  'dashboard.smartdaili-china.com',
];
