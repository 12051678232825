import {
  StyledCarouselItem,
  StyledCarouselItemActionWrapper,
  StyledCarouselItemImage,
  StyledCarouselItemTitleWrapper,
} from './styledComponents';
import {
  GatsbyImg,
  IconChevronRight,
  TGatsbyImg,
  Text,
} from '@smartproxy-web/ui';

export interface CarouselCardProps {
  category: string;
  categoryUrl: string;
  icon: TGatsbyImg;
  title: string;
  actionLabel: string;
  actionUrl: string;
}

interface CarouselBlockCardProps {
  item: CarouselCardProps;
  trackingId: string;
  handleClick: React.MouseEventHandler;
  isDark: boolean;
}

export const CarouselBlockCard = ({
  item,
  trackingId,
  handleClick,
  isDark,
}: CarouselBlockCardProps) => {
  return (
    <StyledCarouselItem
      isDark={isDark}
      aria-label={`${item.actionLabel} - ${item.title}`}
      to={item.actionUrl}
      data-tracker={trackingId}
      onClick={handleClick}
    >
      <StyledCarouselItemImage>
        <GatsbyImg image={item.icon} />
      </StyledCarouselItemImage>
      <StyledCarouselItemTitleWrapper>
        <Text>{item.title}</Text>
      </StyledCarouselItemTitleWrapper>
      {!!item.actionUrl && (
        <StyledCarouselItemActionWrapper>
          {item.actionLabel}
          <IconChevronRight />
        </StyledCarouselItemActionWrapper>
      )}
    </StyledCarouselItem>
  );
};
