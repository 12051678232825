import { useState } from 'react';
import {
  BadgesContainer,
  ShowMoreBadge,
  StyledBadge,
} from './styledComponents';
import { GatsbyLink } from '../GatsbyLink';
import { normalizeTopicPath } from '../BlogParent/utils';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { TopicsProps } from './types';

export const Topics = ({
  topics,
  handleTopicData,
  activeTopic,
  setActiveTopic,
}: TopicsProps) => {
  const [topicsCount, setTopicsCount] = useState<number>(9);
  const showMoreTopics = () => {
    setTopicsCount((prevState) => prevState + (topics.length - prevState));
  };
  const isShowMoreTopicsVisible =
    topics.length > 10 && !(topicsCount >= topics.length);

  const isActiveTopic = (topic) => {
    handleTopicData(topic);
    history.pushState({}, '', `?=${normalizeTopicPath(topic)}`);
    setActiveTopic(topic);
    logPostHogEvent(`topic-${topic}`);
  };

  return (
    <BadgesContainer>
      <StyledBadge
        size="lg"
        variant="filled"
        color="black"
        as={GatsbyLink}
        isActiveTopic={activeTopic === 'all' || activeTopic === ''}
        onClick={() => isActiveTopic('all')}
        data-tracker="topic-all"
      >
        all
      </StyledBadge>
      {!!topics &&
        topics.slice(0, topicsCount).map((topic) => (
          <StyledBadge
            size="lg"
            variant="outlined"
            color="gray"
            as={GatsbyLink}
            key={topic}
            isActiveTopic={activeTopic === topic.toLowerCase()}
            onClick={() => isActiveTopic(topic.toLowerCase())}
            data-tracker={`topic-${normalizeTopicPath(topic)}`}
          >
            {topic}
          </StyledBadge>
        ))}
      {isShowMoreTopicsVisible && (
        <ShowMoreBadge variant="outlined" color="blue" onClick={showMoreTopics}>
          ...
        </ShowMoreBadge>
      )}
    </BadgesContainer>
  );
};
