export const QUERY_CLEANUP_REGEX = /(?:\?=|-)\s?/g;

export const getStructuredPostData = (array) =>
  array.map(
    ({
      url,
      data: {
        article_image: image,
        description,
        release_date: date,
        time_spent_reading: timeToRead,
        title,
        new_until: newUntil,
        body,
      },
    }) => {
      const author = getPostAuthor(body);
      const topics = transformPostTopics(getPostTopics(body));
      const blogData = {
        image,
        description,
        date,
        timeToRead,
        title,
        url,
        author,
        topics,
        newUntil,
      };

      return blogData;
    }
  );

export const getPostAuthor = (body) =>
  body
    .filter(
      (item) =>
        item.slice_type === 'author_v2' || item.slice_type === 'blog_author'
    )
    .map(
      ({
        primary: {
          blog_author: {
            document: {
              data: { full_name: name, authors_image: image },
            },
          },
        },
      }) => {
        return { name, image };
      }
    )[0];

export const getPostTopics = (body) =>
  body
    .filter((item) => item.slice_type === 'social_buttons_bar')
    .map((topics) => topics.items);

export const transformPostTopics = (topics) =>
  topics.map((item) =>
    item.map(
      ({ topic }) =>
        topic.document && topic.document.data?.topic_label.toLowerCase()
    )
  )[0];

export const getFilteredArticlesByTopic = (articles, topicLabel) => {
  const filteredArticles = articles.filter(
    (item) => item.topics.includes(topicLabel) && item
  );
  return filteredArticles;
};

export const checkSearchText = (text: string) => {
  const punctuationRegex = /[^a-zA-Z0-9-]+/g;
  const checkedText = text
    .trim()
    .toLowerCase()
    .split('-')
    .join()
    .replaceAll(punctuationRegex, ' ');
  return checkedText;
};

export const matchTitle = (text: string, value: string) => {
  const checkedTitle = checkSearchText(text);
  const checkedValue = checkSearchText(value);
  const matchedTitle = checkedTitle.includes(checkedValue);
  return matchedTitle;
};

export const getFilteredDataByTitle = (data, inputValue) => {
  const filteredArticles = data.filter(
    ({ title }) => matchTitle(title.text, inputValue) && data
  );
  return filteredArticles;
};

export const selectedTopics = (items) =>
  items.map(
    ({
      selected_topics: {
        document: {
          data: { topic_label },
        },
      },
    }) => topic_label.toLowerCase()
  );
