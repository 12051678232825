export const products = [
  'datacenter',
  'datacenter_ip',
  'dedicated_datacenter',
  'mobile',
  'residential',
  'serp',
  'ecommerce',
  'web',
  'no_code',
  'social_media',
  'isp',
  'dedicated_isp',
  'site_unblocker',
  'mixed',
  'resi_trial',
  'disp',
] as const;

export type Product = (typeof products)[number];
