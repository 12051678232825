import {
  QUERY_CLEANUP_REGEX,
  getFilteredArticlesByTopic,
  getFilteredDataByTitle,
  getStructuredPostData,
  selectedTopics,
} from './utils';
import { Topics } from './Topics';
import { isBrowser } from 'utils/build';
import { Box, Button, IconSearch } from '@smartproxy-web/ui';
import { ChangeEvent, useState, useEffect, SyntheticEvent } from 'react';
import { ArticleBox } from '../ArticleBox';
import {
  AllArticlesWrapper,
  FilterContainer,
  SearchButton,
  SearchContainer,
} from './styledComponents';
import { Slice } from '../Slice';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { ArticlesFound } from './ArticlesFound';
import { normalizeTopicPath } from '../BlogParent/utils';
import { PostProps } from './types';
import { PRISMIC_VALUES } from 'constants/common';
import { useBuildContext } from '../../contexts';
import { translations } from '../BlogPostIntro/constants';
import { LatestArticles } from './LatestArticles';

export const KHArticles = ({ articles, items }) => {
  const { lang } = useBuildContext();
  const structuredData = getStructuredPostData(articles);
  const [postsCount, setPostsCount] = useState<number>(10);
  const [activeTopic, setActiveTopic] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<PostProps[]>(structuredData);
  const [isFilteredByTitle, setIsFilteredByTitle] = useState<boolean>(false);
  const topics = lang !== 'cn' && ['all', ...selectedTopics(items)];
  const pathQuery =
    isBrowser && location.search.replace(QUERY_CLEANUP_REGEX, ' ').trim();
  const ctaTrackingSelector = initBuildTrackingId({
    section: 'all-articles',
    context: 'show-more',
  });

  const handleTopicData = (data: string) => setActiveTopic(data);

  const handleCtaClick = () => {
    setPostsCount((prevState) => prevState + 10);
    logPostHogEvent(ctaTrackingSelector());
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputValue(e.target.value);
    if (e.target.value === '') {
      setFilteredData(structuredData);
      setIsFilteredByTitle(false);
    }
  };

  const handleSearch = (e: SyntheticEvent) => {
    e?.preventDefault();
    if (!inputValue) return;
    setFilteredData(getFilteredDataByTitle(structuredData, inputValue));
    history.pushState({}, '', `?=${normalizeTopicPath(inputValue.trim())}`);
    setIsFilteredByTitle(true);
    setActiveTopic('');
    logPostHogEvent('blog-search-submited');
  };

  const isAllPostsLoaded = !!filteredData && filteredData.length <= postsCount;

  useEffect(() => {
    if (!pathQuery) return;
    if (topics.includes(pathQuery)) {
      setActiveTopic(pathQuery);
      setIsFilteredByTitle(false);
      activeTopic === 'all'
        ? setFilteredData(structuredData)
        : setFilteredData(
            getFilteredArticlesByTopic(structuredData, pathQuery)
          );
    } else {
      setInputValue(pathQuery);
      setFilteredData(getFilteredDataByTitle(structuredData, pathQuery));
      setIsFilteredByTitle(true);
    }
  }, [pathQuery]);

  return (
    <>
      <Slice>
        {lang !== 'cn' && (
          <FilterContainer>
            {!!items && (
              <Topics
                handleTopicData={handleTopicData}
                topics={selectedTopics(items)}
                activeTopic={activeTopic}
                setActiveTopic={setActiveTopic}
              />
            )}
            <SearchContainer onSubmit={handleSearch}>
              <input
                type="text"
                placeholder={PRISMIC_VALUES.SEARCH_BLOG}
                value={inputValue}
                onChange={handleChange}
                data-tracker="blog-search-input"
              />
              <SearchButton
                onClick={handleSearch}
                data-tracker="blog-search-button"
              >
                <IconSearch />
              </SearchButton>
            </SearchContainer>
          </FilterContainer>
        )}
        {isFilteredByTitle && (
          <ArticlesFound inputValue={inputValue} count={filteredData.length} />
        )}
        <AllArticlesWrapper>
          {!!filteredData &&
            filteredData
              .slice(0, postsCount)
              .map((item) => <ArticleBox {...item} key={item.title.text} />)}
        </AllArticlesWrapper>
        {!isAllPostsLoaded && (
          <Box sx={{ margin: '48px auto 0', width: { md: '167px' } }}>
            <Button
              color="black"
              variant="outlined"
              fullWidth
              data-tracker={ctaTrackingSelector()}
              onClick={handleCtaClick}
            >
              {translations.show_more[lang]}
            </Button>
          </Box>
        )}
      </Slice>
      {!filteredData.length && <LatestArticles articles={structuredData} />}
    </>
  );
};
