import styled from '@emotion/styled';
import { List, theme } from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';

export const RichTextList = styled(List)({
  '*': {
    fontFamily: theme.fonts.family.additional,
  },
  paddingLeft: '1rem',
  '>*+*': {
    marginTop: '0.75rem',
  },
  li: {
    paddingBottom: 0,
  },
});

export const RichTextHyperlink = styled(GatsbyLink)({
  ':hover': {
    textDecoration: 'underline',
  },
  '&&': {
    fontFamily: theme.fonts.family.additional,
  },
});
