import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';
import { Box } from '../Box';

export const ToggleButtonContainer = styled.div({
  position: 'relative',
  width: 'fit-content',
  borderRadius: '24px',
  padding: '2px',
  border: `1px solid ${themev2.colors.neutral.N10}`,
  display: 'flex',
  backgroundColor: theme.colors.neutral.N00,
  ':hover': {
    transition: 'all 0.1s ease-in-out 0s',
    background: themev2.colors.neutral.N04,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: '0 auto',
  },
});

export const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  padding: '12px 24px',
  borderRadius: '24px',
  minWidth: '160px',
  cursor: 'pointer',
  textAlign: 'center',
  color: isActive ? theme.colors.neutral.N00 : theme.colors.neutral.N99,

  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: 1,
  fontWeight: 400,
  zIndex: 2,
  transition: '0.3s',
}));

export const SelectedTabBackground = styled(Box)({
  position: 'absolute',
  minWidth: '160px',
  top: '2px',
  padding: '12px 24px',
  borderRadius: '24px',
  height: 'calc(100% - 4px)',
  backgroundColor: theme.colors.neutral.N99,
  zIndex: 1,
  transition: '0.3s',
});

export const DynamicToggleButtonContainer = styled.div<{ showBorder: boolean }>(
  ({ showBorder }) => ({
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderRadius: '24px',
    padding: '2px',
    border: showBorder ? `1px solid ${themev2.colors.neutral.N04}` : 'none',
    display: 'flex',
    rowGap: '20px',
    backgroundColor: theme.colors.neutral.N00,
    width: 'fit-content',
    ':hover': {
      ...(showBorder
        ? {
            transition: 'all 0.1s ease-in-out 0s',
            background: themev2.colors.neutral.N04,
          }
        : {}),
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      margin: '0 auto',
    },
  })
);

export const DynamicTab = styled.div<{
  isActive: boolean;
  showBorder: boolean;
}>(({ isActive, showBorder }) => ({
  padding: '0.75rem 1.5rem',
  minWidth: '160px',
  borderRadius: '24px',
  cursor: 'pointer',
  textAlign: 'center',
  color: isActive ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  backgroundColor: isActive && showBorder ? theme.colors.neutral.N99 : 'none',

  border: showBorder ? `1px solid ${themev2.colors.neutral.N10}` : 'none',

  ':hover': {
    ...(showBorder && !isActive
      ? {
          transition: 'all 0.1s ease-in-out 0s',
          background: themev2.colors.neutral.N04,
        }
      : {}),
  },

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '100%',
  },

  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: 1,
  fontWeight: 400,
  zIndex: 2,
  transition: '0.3s',
}));

export const DynamicSelectedTabBackground = styled(Box)({
  position: 'absolute',
  minWidth: '160px',
  minHeight: '40px',
  top: '2px',
  left: 0,
  padding: '12px 24px',
  borderRadius: '24px',
  backgroundColor: theme.colors.neutral.N99,
  zIndex: 1,
  transition: '0.3s',
});
