export interface ThemeSpacings {
  /** 80px */
  sectionSpacing: string;
  /** 60px */
  L: string;
  /** 30px */
  M: string;
  /** 24px */
  S: string;
  /** 12px */
  XS: string;
}

export const spacings: ThemeSpacings = {
  sectionSpacing: '80px',
  L: '60px',
  M: '30px',
  S: '24px',
  XS: '12px',
};
