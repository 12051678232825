import styled from '@emotion/styled';
import { theme } from '../../theme';
import { hexToRgba } from '@smartproxy-web/shared/utils';

export const StyledCodeBlockLine = styled.div<{
  maxLineNumberCharSize: number;
  fontSize?: number;
}>(({ fontSize = 16, maxLineNumberCharSize }) => ({
  display: 'flex',
  lineHeight: '1.5',
  fontSize,

  '*': {
    fontWeight: '300',
    fontFamily: theme.fonts.family.mono,
  },
  '.token-line-number': {
    textAlign: 'right',
    color: hexToRgba(theme.colors.neutral.N50, 0.5),
    display: 'inline-block',
    minWidth: `${maxLineNumberCharSize}em`,
    marginRight: fontSize,
  },
}));
