import React from 'react';
import { CountdownTimer } from '../CountdownTimer/CountdownTimer';
import { splitToTimeComponents } from '../../helpers/time';
import { useCountdown } from '../../hooks/useCountdown';

export const OfferCountdownStatus: React.FC<{
  expiry: string | null;
  lang: string;
}> = ({ expiry, lang }) => {
  const currentMs = Date.now();
  const expiryMs = expiry ? new Date(expiry).getTime() : currentMs;

  const duration = useCountdown({
    time: expiryMs - currentMs,
    rateOfChange: 1000,
  });

  const splitTime = splitToTimeComponents(duration);

  return <CountdownTimer timeComponents={splitTime} lang={lang} />;
};
