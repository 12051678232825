import { Link, Text } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { PrismicTextField } from '@smartproxy-web/ui/src/types';
import { LinkProps } from './GettingStarted';
import { TrackingTypes } from '@smartproxy-web/shared/helpers';
import {
  Card,
  DescriptionWrapper,
  TitleWrapper,
} from './InfoCardStyledComponent';

export type KnowledgeHubInfoCardProps = {
  card_title: string;
  cardDescription: PrismicTextField;
  button_link?: LinkProps;
  button_text?: string;
  isDark: boolean;
  card_toggle_type: string;
};

export default function KnowledgeHubInfoCard({
  card_title,
  cardDescription,
  card_toggle_type,
  button_link,
  button_text,
  onTrack,
  trackSelector,
  isDark,
}: KnowledgeHubInfoCardProps & {
  onTrack?: ({
    cardTitle,
    key,
    type,
  }: {
    cardTitle: string;
    key: string;
    type?: TrackingTypes;
  }) => void;
  trackSelector?: (key: string, type?: TrackingTypes) => string;
}) {
  return (
    <Card>
      <TitleWrapper isDark={isDark}>
        <Text as="strong" size="lg">
          {card_title}
        </Text>
      </TitleWrapper>
      <DescriptionWrapper isDark={isDark}>
        <RichText field={cardDescription} />
        {!!button_link?.url && !!button_text && (
          <Link
            href={button_link.url}
            target="_blank"
            rel="noopener noreferrer"
            data-tracker={trackSelector?.(
              `${card_toggle_type}-${card_title}-${button_text}`,
              'link'
            )}
            onClick={() =>
              onTrack?.({
                cardTitle: card_title,
                key: button_text,
                type: 'link',
              })
            }
          >
            {button_text}
          </Link>
        )}
      </DescriptionWrapper>
    </Card>
  );
}
