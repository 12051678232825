import { useEffect, useState } from 'react';

type UseFlexWrapDetectorProps = {
  parentRef: React.RefObject<HTMLElement>;
  offsetStart?: number;
  offsetEnd?: number;
};

export function useFlexWrapDetector({
  parentRef,
  offsetStart = 0,
  offsetEnd = 0,
}: UseFlexWrapDetectorProps) {
  const [hasWrapped, setHasWrapped] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      if (!Boolean(parentRef)) return;

      const parentElement = parentRef.current;

      if (parentElement) {
        const children = Array.from(parentElement.children) as HTMLElement[];

        if (children.length > 1) {
          const firstChildTop = children[0 + offsetStart].offsetTop;
          const lastChildTop =
            children[children.length - 1 - offsetEnd].offsetTop;

          setHasWrapped(firstChildTop !== lastChildTop);
        }
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentRef]);

  return hasWrapped;
}
