import { Heading } from '@smartproxy-web/ui';
import { ArticleBox } from '../ArticleBox';
import {
  AllArticlesWrapper,
  LatestArticlesContainer,
} from './styledComponents';
import { LatestArticlesProps } from './types';

export const LatestArticles = ({ articles }: LatestArticlesProps) => {
  const latestArticles = articles.slice(0, 2);
  return (
    <LatestArticlesContainer>
      <Heading level={2}>Newest articles</Heading>
      <AllArticlesWrapper>
        {latestArticles.map((item, index) => (
          <ArticleBox key={index} {...item} />
        ))}
      </AllArticlesWrapper>
    </LatestArticlesContainer>
  );
};
