import { Box, Heading } from '@smartproxy-web/ui';
import Card, { KnowledgeHubCardProps } from './Card';
import DynamicToggle from '@smartproxy-web/ui/src/components/ToggleV2';
import React from 'react';
import KnowledgeHubInfoCard from './InfoCard';
import { Slice } from '../Slice';
import { useSliceDetails } from '../../hooks';
import {
  TrackingTypes,
  initBuildTrackingId,
} from '@smartproxy-web/shared/helpers';
import {
  CardsWrapper,
  HeadingWrapper,
  ListItem,
  ListWrapper,
  ToggleWrapper,
  Wrapper,
} from './GettingStartedStyledComponents';
import { logEvent } from '../../helpers';
import { normalizeString } from '@smartproxy-web/shared/utils';

export type CardToggleType = 'proxy' | 'scrape';
export type CardType = 'info' | 'content';
export type LinkProps = { url: string; target: string };

type GettingStartedProps = {
  primary: {
    title_component: string;
    toggle_proxy_label: string;
    toggle_scrape_label: string;
    is_dark: boolean;
  };
  items: (KnowledgeHubCardProps & {
    card_type: CardType;
    card_toggle_type: CardToggleType;
  })[];
};

export function GettingStarted({ primary, items }: GettingStartedProps) {
  const [activeTab, setActiveTab] = React.useState<CardToggleType>('proxy');

  const { id: sliceId, name: sliceName } = useSliceDetails();
  const createTrackingID = initBuildTrackingId({
    section: sliceName,
  });

  if (!Boolean(primary)) {
    return <></>;
  }
  const {
    title_component,
    toggle_proxy_label,
    toggle_scrape_label,
    is_dark: isDark,
  } = primary;
  const cards = items.filter((item) => item.card_toggle_type === activeTab);

  function handleTrack({
    cardTitle,
    key,
    type,
  }: {
    cardTitle?: string;
    key: string;
    type?: TrackingTypes;
  }) {
    const elementId = normalizeString(key);

    logEvent({
      eventId: createTrackingID({
        context: elementId,
        type,
      }),
      elementId: `${elementId}-${type}`,
      sliceId,
      elementGroup: normalizeString(activeTab),
      ...(!!cardTitle && { elementSubGroup: normalizeString(cardTitle) }),
    });
  }

  function handleToggleTrack(key: string, type?: TrackingTypes) {
    const elementId = normalizeString(key);
    logEvent({
      eventId: createTrackingID({
        context: elementId,
        type,
      }),
      elementId: `${elementId}-${type}`,
      sliceId,
    });
  }

  function createTracking(key: string, type?: TrackingTypes) {
    return createTrackingID({
      context: key,
      type,
    });
  }
  const showToggleButton = !!toggle_proxy_label || !!toggle_scrape_label;

  return (
    <Slice isDark={isDark}>
      <Wrapper>
        <HeadingWrapper isDark={isDark}>
          <Heading level={2}>{title_component}</Heading>
        </HeadingWrapper>
        <Box
          sx={{
            marginBottom: { base: '32px', md: '60px', lg: '80px' },
          }}
        >
          {showToggleButton && (
            <ToggleWrapper>
              <DynamicToggle
                tabs={[
                  {
                    key: 'proxy',
                    label: toggle_proxy_label,
                  },
                  {
                    key: 'scrape',
                    label: toggle_scrape_label,
                  },
                ]}
                onSelect={(key) => setActiveTab(key as 'proxy' | 'scrape')}
                color={isDark ? 'gradiant' : undefined}
                onTrack={handleToggleTrack}
                trackSelector={createTracking}
              />
            </ToggleWrapper>
          )}
        </Box>
        <CardsWrapper>
          <ListWrapper>
            {cards.map((card) => (
              <ListItem key={card.card_title}>
                {card.card_type === 'info' ? (
                  <KnowledgeHubInfoCard
                    {...card}
                    isDark={isDark}
                    onTrack={handleTrack}
                    trackSelector={createTracking}
                  />
                ) : null}
                {card.card_type === 'content' ? (
                  <Card
                    {...card}
                    isDark={isDark}
                    onTrack={handleTrack}
                    trackSelector={createTracking}
                  />
                ) : null}
              </ListItem>
            ))}
          </ListWrapper>
        </CardsWrapper>
      </Wrapper>
    </Slice>
  );
}
