import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';

export const responsiveInputStyle = {
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
    maxWidth: '100%',
  },
};

export const FakeLink = styled.div({
  cursor: 'pointer',
  textDecoration: 'underline',
  display: 'inline-block',
});
