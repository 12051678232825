import { graphql, useStaticQuery } from 'gatsby';
import { PrismicHeaderV2 } from './types';

export const headerQuery = (): PrismicHeaderV2 => {
  return useStaticQuery(
    graphql`
      query HeaderV2 {
        prismicHeaderV2 {
          data {
            logo {
              alt
              url
              gatsbyImageData(placeholder: BLURRED)
            }
            nav_categories {
              category_label
              badge_label
              category_item {
                id
                document {
                  ... on PrismicHeaderNavCategory {
                    id
                    data {
                      name
                      nav_item_name {
                        nav_item {
                          document {
                            ... on PrismicHeaderNavCategoryItem {
                              id
                              data {
                                category_item_list_title
                                category_item_name
                                category_item_title_url {
                                  url
                                }
                                category_items_list {
                                  category_item_description {
                                    richText
                                    text
                                  }
                                  category_item_label
                                  category_item_link {
                                    url
                                  }
                                  category_item_title
                                  price
                                  price_prefix
                                  badge_color
                                }
                                badge_label
                              }
                            }
                          }
                        }
                      }
                      additional_item {
                        document {
                          ... on PrismicHeaderNavCategoryAdditional {
                            id
                            data {
                              features
                              features_url {
                                url
                              }
                              targets
                              targets_url {
                                url
                              }
                              features_items {
                                item_label
                                item_link {
                                  url
                                }
                              }
                              targets_items {
                                item_label
                                item_link {
                                  url
                                }
                              }
                              banner
                              banner_cta_label {
                                richText
                                text
                              }
                              banner_cta_link {
                                url
                              }
                              banner_description {
                                richText
                                text
                              }
                              banner_icon {
                                alt
                                url
                                gatsbyImageData(placeholder: BLURRED)
                              }
                              banner_title
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            languages {
              key
              label
              link {
                url
              }
            }
            nav_links {
              label
              link {
                url
              }
            }
          }
        }
      }
    `
  );
};
