import { useEffect, useRef } from 'react';
import {
  IconG2Badge,
  IconG2Black,
  IconProxywayBadge,
  IconProxywayBlack,
  IconTrustpilotBadgePlaceholder,
} from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';
import { trustpilotBadgeSize } from './constants';
import {
  StyledTrustpilotBadgePlaceholderWrapper,
  StyledHeroBadge,
  StyledHeroBadgesWrapper,
  StyledTrustpilotBadgeWrapper,
} from './styledComponents';

declare const window: Window &
  typeof globalThis & {
    Trustpilot?: {
      loadFromElement: (el: HTMLElement, state: boolean) => void;
    };
  };

type BadgeType = 'trustpilot' | 'g2' | 'proxyway';

interface HeroBadgesProps {
  handleBadgeClick?: (badgeType: BadgeType) => void;
  trackingSelectors: Partial<Record<BadgeType, string>>;
  isDark?: boolean;
  isCentered?: boolean;
}

export const HeroBadges = ({
  handleBadgeClick = () => {},
  trackingSelectors,
  isDark = true,
  isCentered = false,
}: HeroBadgesProps) => {
  const truspilotWrapperElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window.Trustpilot && truspilotWrapperElRef.current) {
      window.Trustpilot.loadFromElement(truspilotWrapperElRef.current, true);
    }
  }, []);

  return (
    <StyledHeroBadgesWrapper isCentered={isCentered}>
      <StyledHeroBadge isDark={isDark}>
        <StyledTrustpilotBadgePlaceholderWrapper isDark={isDark}>
          <IconTrustpilotBadgePlaceholder />
        </StyledTrustpilotBadgePlaceholderWrapper>
        <StyledTrustpilotBadgeWrapper
          data-tracker={trackingSelectors.trustpilot}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="5d89308558ce38000114f334"
          data-style-height={`${trustpilotBadgeSize.height}px`}
          data-style-width={`${trustpilotBadgeSize.width}px`}
          data-theme={isDark ? 'dark' : 'light'}
          ref={truspilotWrapperElRef}
        >
          <GatsbyLink
            to="https://trustpilot.com/review/smartproxy.com"
            rel="noopener noreferrer nofollow"
            onClick={() => handleBadgeClick('trustpilot')}
          />
        </StyledTrustpilotBadgeWrapper>
      </StyledHeroBadge>
      <StyledHeroBadge isDark={isDark}>
        <GatsbyLink
          to="https://www.g2.com/products/smartproxy/reviews"
          rel="noopener noreferrer nofollow"
          onClick={() => handleBadgeClick('g2')}
          data-tracker={trackingSelectors.g2}
        >
          {isDark ? <IconG2Badge /> : <IconG2Black />}
        </GatsbyLink>
      </StyledHeroBadge>
      <StyledHeroBadge isDark={isDark}>
        <GatsbyLink
          to="https://proxyway.com/reviews/smartproxy-proxies"
          rel="noopener noreferrer nofollow"
          onClick={() => handleBadgeClick('proxyway')}
          data-tracker={trackingSelectors.proxyway}
        >
          {isDark ? <IconProxywayBadge /> : <IconProxywayBlack />}
        </GatsbyLink>
      </StyledHeroBadge>
    </StyledHeroBadgesWrapper>
  );
};
