import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  Feature,
  FeaturesContainer,
  FeaturesList,
  FeatureTitle,
} from '../styledComponents';
import { IPricingCard } from '../types';

export const Features: React.FC<IPricingCard> = ({
  data: { card_title: title, checkmark, features },
  itemsLength,
}) => (
  <FeaturesContainer itemsLength={itemsLength || 4}>
    <FeatureTitle>{title}</FeatureTitle>
    <FeaturesList itemsLength={itemsLength || 4}>
      {features.map(({ feature }) => (
        <Feature key={feature}>
          <div>
            <GatsbyImg image={checkmark} />
          </div>
          <p>{feature}</p>
        </Feature>
      ))}
    </FeaturesList>
  </FeaturesContainer>
);
