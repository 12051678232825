import { RichText } from 'components/v2/RichText';
import React from 'react';
import { PrismicTextField } from 'types';
import {
  StyledReviewContainer,
  StyledReviewsWrapper,
  StyledReviewTitle,
} from '../styledComponents';

interface ReviewsProps {
  reviewsTitle: PrismicTextField;
  items: { review?: PrismicTextField }[];
}

export const Reviews: React.FC<ReviewsProps> = ({ reviewsTitle, items }) => (
  <StyledReviewsWrapper>
    <StyledReviewTitle>
      <RichText field={reviewsTitle} />
    </StyledReviewTitle>
    <StyledReviewContainer>
      {items.map((item, index) => (
        <RichText field={item.review} key={index} />
      ))}
    </StyledReviewContainer>
  </StyledReviewsWrapper>
);
