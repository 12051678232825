export type ThemeColor =
  | 'brand'
  | 'neutral'
  | 'primary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'red'
  | 'blue'
  | 'gray'
  | 'green'
  | 'purple'
  | 'white'
  | 'black'
  | 'yellow';

type ThemeColorMap = Record<string, string>;

export type ThemeColors = Record<ThemeColor, ThemeColorMap>;

export const colors: ThemeColors = {
  white: {
    default: '#FFFFFF',
    transparent: '#ffffff0a',
  },
  black: {
    default: '#000000',
    S900: '#0D0F10',
    S600: '#1D1D1F',
    transparent: '#0d0f10e6',
  },
  red: {
    default: '#F44862',
  },
  blue: {
    default: '#2997FF',
    S600: '#59BAFF',
  },
  gray: {
    S400: '#D0D0D0',
    default: '#86868B',
    S900: '#0D0F10',
    S600: '#626264',
    S500: '#A1A1A1',
    S300: '#9E9F9F',
    S200: '#3D3F40',
    S100: '#CFCFCF',
    S700: '#565758',
  },
  green: {
    default: '#00F0D3',
    S600: '#00B7A6',
  },
  purple: {
    default: '#9F75FF',
  },
  brand: {
    B06: '#FFF5F7',
    B50: '#F9A3B0',
    B99: '#F44862',
  },
  neutral: {
    N00: '#FFFFFF',
    N02: '#FBFBFB',
    N04: '#F5F5F5',
    N10: '#E7E7E7',
    N20: '#CFCFCF',
    N40: '#9E9F9F',
    N50: '#86868B',
    N80: '#3D3F40',
    N90: '#252728',
    N94: '#1D1D1F',
    N99: '#0D0F10',
  },
  primary: {
    P15: '#DFEFFF',
    P50: '#94CBFF',
    P99: '#2997FF',
  },
  success: {
    S06: '#EDFFE5',
    S50: '#9DE380',
    S99: '#3CC700',
  },
  error: {
    E06: '#FFF0F0',
    E50: '#FF8080',
    E99: '#FF0000',
  },
  warning: {
    W06: '#FFFCF1',
    W50: '#FDE58A',
    W99: '#FACC15',
  },
  info: {
    I99: '#00F0D3',
  },
  yellow: {
    default: '#F5BF4B',
  },
};
