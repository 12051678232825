import { logPostHogEvent } from '@smartproxy-web/analytics';
import { buildTrackingId } from '@smartproxy-web/shared/helpers';
import {
  Box,
  GatsbyImg,
  PrismicTextField,
  TGatsbyImage,
} from '@smartproxy-web/ui';
import { Link } from 'prismic-reactjs';
import { RichText } from '../RichText';
import {
  SideBannerWrapper,
  Heading,
  SideBannerTitle,
  SideBannerDescription,
  SideBannerContainer,
  StyledButtonContainer,
} from './styledComponents';
import { useBuildContext } from '../../contexts';
import { translations } from '../BlogPostIntro/constants';
import { LinkButton } from '../LinkButton';

export interface SideBannerProps {
  primary: {
    background?: string;
    side_banner_heading?: PrismicTextField;
    title: PrismicTextField;
    subtitle?: PrismicTextField;
    icon?: TGatsbyImage;
    primary_cta_label: PrismicTextField;
    primary_cta_link: Link;
    primary_cta_icon: TGatsbyImage;
    primary_cta_icon_position: 'left' | 'right';
    secondary_cta_label: PrismicTextField;
    secondary_cta_link: Link;
    secondary_cta_icon: TGatsbyImage;
    secondary_cta_icon_position: 'left' | 'right';
  };
}

export const SideBannerComponent = ({
  primary: {
    background,
    side_banner_heading: sideBannerHeading,
    title,
    subtitle,
    icon,
    primary_cta_label: primaryCtaLabel,
    primary_cta_link: primaryCtaLink,
    primary_cta_icon: primaryCtaIcon,
    primary_cta_icon_position: primaryCtaIconPosition = 'left',
    secondary_cta_label: secondaryCtaLabel,
    secondary_cta_link: secondaryCtaLink,
    secondary_cta_icon: secondaryCtaIcon,
    secondary_cta_icon_position: secondaryCtaIconPosition = 'left',
  },
}: SideBannerProps) => {
  const { lang } = useBuildContext();

  const hasValidCta = !!primaryCtaLink.url || !!secondaryCtaLink.url;

  return (
    <SideBannerWrapper backgroundColor={background}>
      <Heading>
        <RichText field={sideBannerHeading} />
      </Heading>
      <SideBannerContainer>
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            alignItems: 'start',
          }}
        >
          {!!icon?.url && (
            <Box
              sx={{
                paddingTop: '5px',
                height: '36px',
                width: '36px',
              }}
            >
              <GatsbyImg image={icon} />
            </Box>
          )}
          <SideBannerTitle>
            <RichText field={title} />
          </SideBannerTitle>
        </Box>
        <SideBannerDescription>
          <RichText field={subtitle} />
        </SideBannerDescription>
        {hasValidCta && (
          <StyledButtonContainer>
            {!!primaryCtaLabel?.text && !!primaryCtaLink?.url && (
              <LinkButton
                to={primaryCtaLink.url}
                variant="filled"
                color="black"
                leftIcon={
                  primaryCtaIconPosition === 'left' && (
                    <GatsbyImg image={primaryCtaIcon!} />
                  )
                }
                rightIcon={
                  primaryCtaIconPosition === 'right' && (
                    <GatsbyImg image={primaryCtaIcon!} />
                  )
                }
                data-tracker={buildTrackingId({
                  context: 'cta-' + primaryCtaLabel?.text,
                })}
                onClick={() =>
                  logPostHogEvent(
                    buildTrackingId({
                      context: 'cta-' + primaryCtaLabel?.text,
                    })
                  )
                }
              >
                {primaryCtaLabel.text}
              </LinkButton>
            )}
            {!!secondaryCtaLabel?.text && !!secondaryCtaLink?.url && (
              <>
                <p> {translations.or[lang]}</p>
                <LinkButton
                  to={secondaryCtaLink.url}
                  variant="outlined"
                  color="black"
                  leftIcon={
                    secondaryCtaIconPosition === 'left' && (
                      <GatsbyImg image={secondaryCtaIcon!} />
                    )
                  }
                  rightIcon={
                    secondaryCtaIconPosition === 'right' && (
                      <GatsbyImg image={secondaryCtaIcon!} />
                    )
                  }
                  data-tracker={buildTrackingId({
                    context: 'cta-' + secondaryCtaLabel?.text,
                  })}
                  onClick={() =>
                    logPostHogEvent(
                      buildTrackingId({
                        context: 'cta-' + secondaryCtaLabel?.text,
                      })
                    )
                  }
                >
                  {secondaryCtaLabel.text}
                </LinkButton>
              </>
            )}
          </StyledButtonContainer>
        )}
      </SideBannerContainer>
    </SideBannerWrapper>
  );
};

export default SideBannerComponent;
