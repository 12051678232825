import { useEffect, useState } from 'react';

interface UseCountDownParams {
  time: number;
  rateOfChange: number;
}

export const useCountdown = ({ time, rateOfChange }: UseCountDownParams) => {
  const [duration, setDuration] = useState(Math.max(0, time));

  const startTimer = () =>
    setInterval(() => {
      setDuration((prevState) => Math.max(0, prevState - 1000));
    }, rateOfChange);

  const stopTimer = (timerId) => clearInterval(timerId);

  const isTimerComplete = duration <= 0;

  useEffect(() => {
    const timerId = startTimer();

    if (isTimerComplete) stopTimer(timerId);

    return () => {
      stopTimer(timerId);
    };
  }, [isTimerComplete]);

  return duration;
};
