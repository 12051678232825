import { theme } from '../../theme';

export const BUTTON_SIZES = {
  sm: 32,
  md: 40,
  lg: 48,
};

export const sizeStyles = {
  sm: {
    height: BUTTON_SIZES.sm,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: '0.875rem',
  },
  md: {
    height: BUTTON_SIZES.md,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: '1rem',
  },
  lg: {
    height: BUTTON_SIZES.lg,
    paddingLeft: 24,
    paddingRight: 24,
    fontSize: '1.125rem',
  },
};

export const variantStyles = {
  filled: {
    red: {
      base: {
        color: theme.colors.neutral.N00,
        backgroundColor: theme.colors.red.default,
      },
      hover: {
        backgroundColor: theme.colors.red.S400,
      },
    },
    blue: {
      base: {
        color: theme.colors.neutral.N00,
        backgroundColor: theme.colors.blue.default,
      },
      hover: {
        backgroundColor: theme.colors.blue.S600,
      },
    },
    gray: {
      base: {
        color: theme.colors.neutral.N20,
        backgroundColor: theme.colors.neutral.N50,
      },
      hover: {
        color: theme.colors.neutral.N00,
      },
    },
    green: {
      base: {
        color: theme.colors.neutral.N99,
        backgroundColor: theme.colors.green.default,
      },
      hover: {
        background: theme.colors.green.S600,
      },
    },
    white: {
      base: {
        color: theme.colors.neutral.N99,
        backgroundColor: theme.colors.neutral.N00,
      },
      hover: {
        backgroundColor: theme.colors.neutral.N10,
      },
    },
    black: {
      base: {
        color: theme.colors.neutral.N00,
        backgroundColor: theme.colors.neutral.N99,
      },
      hover: {
        color: theme.colors.neutral.N00,
        opacity: 0.7,
      },
    },
  },
  outlined: {
    red: {
      base: {
        color: theme.colors.red.default,
        border: `1px solid ${theme.colors.red.default}`,
      },
      hover: {
        backgroundColor: theme.colors.red.default,
      },
    },
    blue: {
      base: {
        color: theme.colors.blue.default,
        border: `1px solid ${theme.colors.blue.default}`,
        transition: 'none',
      },
      hover: {
        boxShadow: `0px 0px 0px 1px ${theme.colors.blue.default} inset`,
        transition: 'none',
      },
    },
    gray: { base: {}, hover: {} },
    white: {
      base: {
        color: theme.colors.neutral.N00,
        border: `1px solid ${theme.colors.neutral.N00}`,
        transition: 'none',
      },
      hover: {
        boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.N00} inset`,
        transition: 'none',
      },
    },
    black: {
      base: {
        color: theme.colors.neutral.N99,
        border: `1px solid ${theme.colors.neutral.N99}`,
        transition: 'none',
        background: 'transparent',
      },
      hover: {
        color: theme.colors.neutral.N99,
        boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.N99} inset`,
      },
    },
    gradient: {
      base: {
        backgroundColor: theme.colors.neutral.N00,
        border: '1px double transparent',
        backgroundImage: `linear-gradient(white, white), ${theme.colors.gradient.default}`,
        backgroundOrigin: 'border-box',
        backgroundClip: 'padding-box, border-box',
      },
      hover: {
        borderWidth: '2px',
      },
    },
  },
  text: {
    red: {
      base: {
        color: theme.colors.red.default,
      },
      hover: {
        color: theme.colors.red.S400,
      },
    },
    blue: {
      base: {
        color: theme.colors.blue.default,
      },
      hover: {
        color: theme.colors.blue.S400,
      },
    },
    gray: {
      base: {
        color: theme.colors.neutral.N40,
      },
      hover: {
        color: theme.colors.neutral.N80,
      },
    },
    white: {
      base: {
        color: theme.colors.neutral.N00,
      },
      hover: {
        color: theme.colors.neutral.N50,
      },
    },
    black: { base: {}, hover: {} },
  },
} as const;
