import styled from '@emotion/styled';
import { Slice } from '../Slice';
import {
  Badge,
  Box,
  Button,
  mediaQueries,
  theme,
  toRem,
} from '@smartproxy-web/ui';
import { GatsbyLink } from '../GatsbyLink';

export const StyledBlogIntroWrapper = styled(Slice)({
  paddingTop: 0,
  '*': {
    fontFamily: theme.fonts.family.additional,
  },
  '> div': {
    borderBottom: `1px solid ${theme.colors.neutral.N20}`,
  },
});

export const StyledExcerptWrapper = styled(Box)({
  marginTop: toRem(20),
  'p,strong,a,em': {
    fontSize: toRem(16),
    [mediaQueries.md]: {
      fontSize: toRem(20),
    },
  },
});

export const StyledAvatarWrapper = styled.div({
  display: 'flex',
  flexBasis: '100%',
  alignItems: 'center',
  gap: 8,

  [mediaQueries.md]: {
    flexBasis: 'initial',
  },
});

export const StyledPostShareButton = styled(Button)({
  minWidth: toRem(32),
  minHeight: toRem(32),
  marginRight: toRem(-8),
  borderRadius: toRem(32),
  '& svg': {
    color: theme.colors.neutral.N99,
  },
  '&:hover': {
    backgroundColor: theme.colors.neutral.N04,
  },
});

export const StyledPostTag = styled(Badge)({
  transitionDuration: '0.6s',
  '&:hover': {
    borderColor: 'transparent',
    backgroundColor: theme.colors.neutral.N99,
    color: theme.colors.neutral.N00,
  },
});

const avatarSize = 36;
export const StyledAuthorAvatar = styled.div({
  height: toRem(avatarSize),
  width: toRem(avatarSize),
  borderRadius: avatarSize,
  overflow: 'hidden',
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

export const StyledConstrainedWidthBlock = styled(Box)({});

export const StyledShareOptionLink = styled(GatsbyLink)({
  display: 'flex',
  alignItems: 'center',
  gap: toRem(8),
  padding: `${toRem(6)} ${toRem(12)}`,

  p: {
    color: theme.colors.neutral.N99,
  },

  svg: {
    height: toRem(16),
  },

  ':hover': {
    backgroundColor: theme.colors.neutral.N04,
  },
});

export const copySuccessVisibleTimeMs = 2000;

export const StyledShareOptionButton = styled(Button)<{
  showingCopyMessage: boolean;
}>(({ showingCopyMessage }) => ({
  position: 'relative',
  width: '100%',
  justifyContent: 'flex-start',
  height: toRem(36),
  paddingLeft: toRem(12),
  paddingRight: toRem(12),
  p: {
    fontWeight: '400',
    color: theme.colors.neutral.N99,
  },
  '> *': {
    zIndex: 1,
  },
  ':hover': {
    backgroundColor: theme.colors.neutral.N04,
  },
  '::before': {
    position: 'absolute',
    content: '""',
    inset: 0,
    transform: `scaleX(${Number(showingCopyMessage)})`,
    transformOrigin: 'left',
    transition: `${copySuccessVisibleTimeMs}ms linear`,
    backgroundColor: theme.colors.success.S06,
  },
  ...(showingCopyMessage && {
    'p, svg': {
      color: theme.colors.success.S99,
    },
    pointerEvents: 'none',
  }),
}));

export const VideoContainer = styled.div({
  width: '100%',
  height: '300px',
  iframe: {
    width: '100%',
    height: '100%',
  },
});
