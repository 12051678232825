import styled from '@emotion/styled';
import { Box, mediaQueries, theme, toRem } from '@smartproxy-web/ui';
import { Slice } from '../Slice';

export const StyledBigImageBlock = styled(Slice)({
  'header[data-slice-header]': {
    alignItems: 'flex-start',
    [mediaQueries.lg]: {
      alignItems: 'center',
    },
  },
  '[data-slice-bottom-items]': {
    alignItems: 'flex-start',
    [mediaQueries.lg]: {
      alignItems: 'center',
    },
  },
  '.dynamic-toggle': {
    margin: 0,
    width: '100%',
    [mediaQueries.sm]: {
      width: 'unset',
    },
  },
});

export const StyledBigImageWrapper = styled.div({
  position: 'relative',
  '.gatsby-image-wrapper': {
    width: '100%',
  },
  ' .gatsby-image-wrapper div': {
    maxWidth: 'unset !important',
  },
  '.big-image-enter': {
    transform: 'scale(0.99)',
  },
  '.big-image-enter-active': {
    transform: 'scale(1)',
  },
  '.big-image-exit': {
    transform: 'scale(1)',
  },
  '.big-image-exit-active': {
    transform: 'scale(0.99)',
  },
  '.big-image-enter-active, .big-image-exit-active': {
    transition: 'transform 150ms',
  },
});

export const StyledExpandImageTriggerWrapper = styled.div({
  position: 'absolute',
  top: toRem(20),
  left: toRem(20),
  zIndex: 1,

  [mediaQueries.md]: {
    top: toRem(24),
    left: toRem(24),
  },

  button: {
    padding: 0,
    backgroundColor: theme.colors.neutral.N40,
    borderRadius: toRem(48),
    height: toRem(48),
    width: toRem(48),

    '&:hover': {
      color: theme.colors.neutral.N00,
      backgroundColor: theme.colors.neutral.N50,
    },
  },
});

export const StyledCarouselCtaWrapper = styled(Box)<{
  position: 'top' | 'bottom';
}>(({ position }) => ({
  display: 'flex',
  justifyContent: 'center',
  ...(position === 'bottom' && {
    marginTop: 32,
    [mediaQueries.md]: {
      marginTop: 48,
    },
  }),

  width: '100%',

  '& a, & a > div': {
    width: '100%',
    [mediaQueries.lg]: {
      width: 'initial',
    },
  },
}));
