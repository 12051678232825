import { useRef, useState, useEffect } from 'react';
import {
  SocialProofTab,
  SocialProofSelectedTabBackground,
  SocialProofToggleButtonContainer,
} from '../styledComponents';
import { useSliceDetails } from '../../../hooks';
import { useFlexWrapDetector } from '@smartproxy-web/shared/hooks';
import { ToggleProps } from '../types';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { logEvent } from '../../../helpers';

export const SocialProofToggle = ({
  tabNames,
  selectedTabIndex,
  setSelectedTabIndex,
  color,
}: ToggleProps) => {
  const { id: sliceId } = useSliceDetails();
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const [activeTabElement, setActiveTabElement] =
    useState<HTMLDivElement | null>(null);
  const [elementWidth, setElementWidth] = useState<number>();
  const [elementHeight, setElementHeight] = useState<number>();
  const isWrapped = useFlexWrapDetector({
    parentRef: tabsContainerRef,
    offsetEnd: 1,
  });

  const buildTrackingId = initBuildTrackingId({
    section: 'social-proof',
  });

  useEffect(() => {
    let cumulativeWidth = 0;
    let cumulativeHeight = 0;

    if (!tabsContainerRef.current) {
      setElementWidth(cumulativeWidth);
      setElementHeight(cumulativeHeight);
    }

    const tabs = tabsContainerRef.current!.children;
    for (let i = 0; i < selectedTabIndex; i++) {
      cumulativeWidth += tabs[i].clientWidth;
      cumulativeHeight += tabs[i].clientHeight + 26;
    }

    setElementWidth(cumulativeWidth);
    setElementHeight(cumulativeHeight);
  }, [selectedTabIndex]);

  const handleOnClick = (index, text) => {
    setSelectedTabIndex(index);
    const trackingId = buildTrackingId({
      context: text,
      type: 'button',
    });
    logEvent({
      sliceId,
      eventId: trackingId,
      elementId: text,
    });
  };

  return (
    <SocialProofToggleButtonContainer
      ref={tabsContainerRef}
      color={color}
      showBorder={!isWrapped}
    >
      {tabNames.map((tab, index) => (
        <SocialProofTab
          data-tracker={buildTrackingId({
            context: tab.text,
            type: 'button',
          })}
          key={tab.text}
          isActive={selectedTabIndex === index}
          onClick={() => handleOnClick(index, tab.text)}
          ref={(el) => selectedTabIndex === index && setActiveTabElement(el)}
          color={color}
          showBorder={isWrapped}
        >
          {tab.text}
        </SocialProofTab>
      ))}
      <SocialProofSelectedTabBackground
        tabsNumber={tabNames.length}
        color={color}
        sx={{
          minWidth: `${activeTabElement?.clientWidth}px !important`,
          transform: {
            base: `translateY(${elementHeight}px)`,
            sm: `translateX(${elementWidth}px)`,
          },
        }}
      />
    </SocialProofToggleButtonContainer>
  );
};
