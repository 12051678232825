export enum GOOGLE_SCHEMA {
  schemaMarkup = 'schema-markup',
  entity = 'mainEntity',
  answers = 'acceptedAnswer',
  name = 'name',
  text = 'text',
}

export enum SCHEMA_LINKS {
  FAQ = 'http://schema.org/FAQPage',
  QUESTION = 'http://schema.org/Question',
  ANSWER = 'http://schema.org/Answer',
}

export const COMPACT_HEADER_WIDTH = 1200;
