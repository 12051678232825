import styled from '@emotion/styled';
import { ItemsStylingProps } from './types';
import { mediaQueries, theme } from '@smartproxy-web/ui';

export const ArrowContainer = styled.div<ItemsStylingProps>(({ selected }) => ({
  svg: {
    position: 'absolute',
    right: 0,
    transform: selected
      ? 'translate3d(0px, 0px, 0px)  rotateX(180deg) rotateY(0deg) rotateZ(0deg)'
      : 'translate3d(0px, 0px, 0px)  rotateX(180deg) rotateZ(-180deg)',
    transformStyle: 'preserve-3d',
    marginRight: '4px',
    transition: '0.4s  transform',
    path: {
      fill: theme.colors.neutral.N80,
    },
  },
}));

export const ItemHeadingContainer = styled.div({
  display: 'flex',
  position: 'relative',
  'h3, h4': {
    fontSize: theme.fonts.size.h4,
    fontWeight: 700,
    textAlign: 'left',
    color: theme.colors.black.S900,
    alignSelf: 'center',
    paddingTop: 0,
    fontFamily: theme.fonts.family.additional,
  },
});

export const ItemImageContainer = styled.div<ItemsStylingProps>(
  ({ selected }) => ({
    display: 'none',
    [mediaQueries.lg]: {
      display: selected ? 'inline-block' : 'none',
      width: '460px',
      height: 'auto',
      div: {
        borderRadius: '12px',
        width: '460px',
        height: 'auto',
      },
    },
  })
);

export const ItemTextContainer = styled.li({
  maxWidth: '1024px',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  display: 'inline-block',
  cursor: 'pointer',
  padding: '16px 0 20px',
  transition: '0.6s ease-in-out all',
  background: theme.colors.white.default,
  ':nth-child(1)': {
    borderTop: `1px solid ${theme.colors.neutral.N10}`,
  },
  borderBottom: `1px solid ${theme.colors.neutral.N10}`,
  [mediaQueries.sm]: {
    padding: '20px 0 24px',
  },
});

export const ItemDescriptionContainer = styled.div<ItemsStylingProps>(
  ({ selected }) => ({
    display: selected ? 'block' : 'none',
    marginTop: theme.spacings.S,
    'p, strong, em, a': {
      color: theme.colors.neutral.N80,
      marginBottom: 0,
      fontFamily: theme.fonts.family.additional,
    },
    a: {
      color: theme.colors.blue.default,
      fontFamily: theme.fonts.family.additional,
    },
    'ol, ul': {
      paddingLeft: '15px !important',
      marginBottom: '25px',
      li: {
        lineHeight: '26px',
      },
    },
  })
);

export const TextItemsList = styled.ul<ItemsStylingProps>(
  ({ isImageOnLeft, background }) => ({
    padding: 0,
    width: '100%',
    order: isImageOnLeft ? 1 : 0,
    margin: 0,
    li: {
      background: !!background ? background : theme.colors.neutral.N00,
    },
  })
);
