import {
  GatsbyImageProps,
  getImage,
  GatsbyImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image';

interface GatsbyImgProps extends Partial<GatsbyImageProps> {
  image: IGatsbyImageData & {
    alt?: string;
  };
  fetchPriority?: 'high' | 'low' | 'auto';
}

export type TGatsbyImg = GatsbyImgProps['image'];

export const GatsbyImg = ({ image, ...rest }: GatsbyImgProps) => {
  const img = getImage(image);
  return (
    <GatsbyImage
      image={img!}
      objectFit={'contain'}
      {...rest}
      alt={image?.alt || 'smartproxy'}
    />
  );
};
