import { useState } from 'react';
import { buildRootNavStack } from './helpers';
import { NavProps } from './types';

export const useNavStack = (categories: NavProps['categories']) => {
  const rootNavStack = buildRootNavStack(categories);

  const [navStack, setNavStack] = useState<NavProps['categories']>(
    () => rootNavStack
  );

  const push = (category: NavProps['categories'][number]) => {
    setNavStack((prevState) => {
      const lastCategory = prevState[prevState.length - 1];
      if (lastCategory.key === category.key) return prevState;
      return [...prevState, category];
    });
  };

  const pop = () => {
    setNavStack((prevState) => {
      if (prevState.length <= 1) return prevState;
      return prevState.slice(0, -1);
    });
  };

  const reset = () => {
    setNavStack(rootNavStack);
  };

  return {
    navStack,
    push,
    pop,
    reset,
  };
};
