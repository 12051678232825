import styled from '@emotion/styled';
import { Badge, mediaQueries, theme } from '@smartproxy-web/ui';

export const AllArticlesWrapper = styled.div({
  maxWidth: '1024px',
  margin: '0 auto',
  display: 'grid',
  columnGap: '36px',
  rowGap: '48px',
  gridTemplateRows: 'auto',
  [mediaQueries.md]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

export const BadgesContainer = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  marginBottom: '48px',
  paddingLeft: 0,
  width: '100%',
});

export const StyledBadge = styled(Badge)<{
  isActiveTopic: boolean;
}>(({ isActiveTopic }) => ({
  height: '22px',
  cursor: 'pointer',
  background: isActiveTopic
    ? theme.colors.neutral.N99
    : theme.colors.neutral.N00,
  color: isActiveTopic ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  border: isActiveTopic
    ? '1px solid transparent'
    : `1px solid ${theme.colors.neutral.N20}`,
  ':hover': {
    background: theme.colors.neutral.N99,
    color: theme.colors.neutral.N00,
    transition: '0.3s',
    borderColor: 'transparent',
  },
}));

export const ShowMoreBadge = styled(Badge)({
  height: '22px',
  cursor: 'pointer',
  ':hover': {
    background: theme.colors.primary.P99,
    color: theme.colors.neutral.N00,
    transition: '0.3s',
    borderColor: 'transparent',
  },
});

export const FilterContainer = styled.div({
  maxWidth: '1024px',
  display: 'grid',
  columnGap: '36px',
  margin: '0 auto',
  [mediaQueries.md]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
});

export const SearchContainer = styled.form({
  width: '100%',
  position: 'relative',
  marginBottom: '48px',
  input: {
    border: `1px solid ${theme.colors.neutral.N40}`,
    height: '40px',
    width: '100%',
    borderRadius: '6px',
    paddingLeft: '16px',
    color: theme.colors.neutral.N99,
    '::placeholder': {
      color: theme.colors.neutral.N40,
    },
  },
});

export const SearchButton = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.colors.neutral.N99,
  cursor: 'pointer',
  border: 'none',
  width: '40px',
  height: '40px',
  borderTopRightRadius: '6px',
  borderBottomRightRadius: '6px',
  position: 'absolute',
  padding: 0,
  right: 0,
  top: 0,
  svg: {
    width: '24px',
    height: '24px',
  },
  path: {
    fill: theme.colors.neutral.N00,
  },
});

export const ArticlesFoundContainer = styled.div<{ count: number }>(
  ({ count }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
    maxWidth: '1024px',
    margin: '0 auto',
    marginBottom: count === 0 ? 0 : '48px',
    p: {
      color: theme.colors.neutral.N80,
      fontWeight: 400,
      fontFamily: theme.fonts.family.additional,
    },
    h2: {
      fontSize: '32px',
      fontWeight: 600,
    },
  })
);

export const LatestArticlesContainer = styled.div({
  width: '100%',
  background: theme.colors.neutral.N02,
  padding: `${theme.spacings.sectionSpacing} 20px`,
  h2: {
    textAlign: 'center',
    marginBottom: theme.spacings.sectionSpacing,
  },
});
