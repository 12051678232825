export const BADGE_SIZES = {
  md: 16,
  lg: 21,
};

export const sizeStyles = {
  md: {
    height: BADGE_SIZES.md,
    borderRadius: BADGE_SIZES.md,
    fontSize: '8px',
    padding: '0px 8px',
  },
  lg: {
    height: BADGE_SIZES.lg,
    borderRadius: 24,
    fontSize: '10px',
    padding: '4px 8px 3.5px',
  },
};
