import * as BaseDropdownMenu from '@radix-ui/react-dropdown-menu';
import { forwardRef } from 'react';

const DropdownMenu = BaseDropdownMenu.Root;
const DropdownMenuTrigger = BaseDropdownMenu.Trigger;

const DropdownMenuContent = forwardRef<
  React.ElementRef<typeof BaseDropdownMenu.Content>,
  React.ComponentPropsWithoutRef<typeof BaseDropdownMenu.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <BaseDropdownMenu.Portal>
    <BaseDropdownMenu.Content
      ref={ref}
      sideOffset={sideOffset}
      className={className}
      {...props}
    />
  </BaseDropdownMenu.Portal>
));

const DropdownMenuItem = forwardRef<
  React.ElementRef<typeof BaseDropdownMenu.Item>,
  React.ComponentPropsWithoutRef<typeof BaseDropdownMenu.Item> & {
    inset?: boolean;
  }
>(({ className, ...props }, ref) => (
  <BaseDropdownMenu.Item ref={ref} className={className} {...props} />
));

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
};
