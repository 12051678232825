import {
  FaqAnswerContainer,
  FaqItemContainer,
  FaqQuestionContainer,
  StyledAnswer,
  StyledQuestion,
} from './styledComponents';
import { IconArrowFAQ, PrismicTextField } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { GOOGLE_SCHEMA, SCHEMA_LINKS } from '@smartproxy-web/shared/constants';

export interface FaqItemProps {
  answer: PrismicTextField;
  question: PrismicTextField;
  isOpen: boolean;
  isDark?: boolean;
  onToggle: () => void;
  selector: string;
}

export const FaqItem = ({
  question,
  answer,
  isOpen = false,
  isDark,
  onToggle,
  selector,
}: FaqItemProps) => (
  <FaqItemContainer
    isExpanded={isOpen}
    itemScope
    itemProp={GOOGLE_SCHEMA.entity}
    itemType={SCHEMA_LINKS.QUESTION}
    data-tracker={selector}
    isDark={isDark}
  >
    <FaqQuestionContainer
      onClick={onToggle}
      isExpanded={isOpen}
      isDark={isDark}
    >
      <StyledQuestion itemProp={GOOGLE_SCHEMA.name} isDark={isDark}>
        <RichText field={question} />
      </StyledQuestion>
      <IconArrowFAQ />
    </FaqQuestionContainer>
    <FaqAnswerContainer
      isExpanded={isOpen}
      itemType={SCHEMA_LINKS.ANSWER}
      itemScope
      itemProp={GOOGLE_SCHEMA.answers}
    >
      <StyledAnswer itemProp={GOOGLE_SCHEMA.text} isDark={isDark}>
        <RichText field={answer} />
      </StyledAnswer>
    </FaqAnswerContainer>
  </FaqItemContainer>
);
