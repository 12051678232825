import { isBrowser } from '@smartproxy-web/ui';
import { useEffect, useState } from 'react';
import {
  ContentWrapper,
  HeadingInnerWrapper,
  HeadingLinksWrapper,
  StyledLine,
  StyledLink,
  StyledText,
} from './styledComponents';
import { debounce } from '@smartproxy-web/shared/utils';

export type ArticlesShortcutsProps = {
  title: string;
  data: {
    slice_type: string;
    slice_label: string;
    primary: {
      text: {
        richText: {
          type: string;
          text: string;
        }[];
      };
    };
  }[];
};

export default function ArticlesShortcuts({
  title,
  data,
}: ArticlesShortcutsProps) {
  const hash = isBrowser() ? window.location.hash : '';

  const [stateHash, setHash] = useState(hash);
  const [isScrolling, setIsScrolling] = useState(false);
  const [h2Elements, setH2Elements] = useState<HTMLHeadingElement[]>([]);

  function headingsText() {
    const headingsText: string[] = [];

    const doesHaveLabels = data.some((slice) => Boolean(slice?.slice_label));

    function checkIfCanAdd(
      slice: ArticlesShortcutsProps['data'][number],
      headingsText: string[]
    ) {
      if (!!!slice?.primary) return;

      const objectKeys = Object.keys(slice.primary);
      objectKeys.forEach((key) => {
        slice.primary[key]?.richText?.map((content) => {
          if (content.type === 'heading2' && content.text) {
            headingsText.push(content.text);
          }
        });
      });
    }

    if (doesHaveLabels) {
      data.forEach((slice) => {
        if (slice.slice_label !== 'main') return;

        checkIfCanAdd(slice, headingsText);
      });
    } else {
      data.forEach((slice) => {
        checkIfCanAdd(slice, headingsText);
      });
    }

    return headingsText;
  }
  const headings = headingsText();

  function scrollToPosition(x, y) {
    window.scrollTo(x, y);
    let cancelAnimationFrame = false;

    // we check if scroll event is completed
    function checkScroll() {
      // rounding to nearest 10 because some browsers are not precise
      function roundToNearestTen(num) {
        return Math.round(num / 10) * 10;
      }

      // pageOffset is for safari, scrollY is for chrome and firefox
      if (
        roundToNearestTen(window.pageYOffset) === roundToNearestTen(y) ||
        roundToNearestTen(window.scrollY) === roundToNearestTen(y)
      ) {
        setIsScrolling(false);
      } else if (!cancelAnimationFrame) {
        setIsScrolling(true);
        requestAnimationFrame(checkScroll);
      }
    }

    checkScroll();

    // we want to prevent infinite loop (which can happen user clicks quick many different links)
    setTimeout(() => {
      cancelAnimationFrame = true;
      setIsScrolling(false);
    }, 900);
  }

  function getAndSetH2Elements() {
    const mainContentElement = document.getElementById('main-content');
    if (!mainContentElement) return;

    const h2Elements = mainContentElement.querySelectorAll('h2');

    setH2Elements(Array.from(h2Elements));
    return Array.from(h2Elements);
  }

  function handleScroll(h2Elements: HTMLHeadingElement[]) {
    if (!h2Elements.length || !isBrowser() || isScrolling) return;

    let targetElement: HTMLHeadingElement | null = null;

    function checkIfElementHidden(element: HTMLHeadingElement) {
      const { top, bottom } = element.getBoundingClientRect();

      return top === 0 && bottom === 0;
    }

    // when video block is getting out of viewpoint it becomes hidden and shows incorrect top value
    const allH2ElementsVisible = h2Elements.every(
      (element) => !checkIfElementHidden(element)
    );

    let tempH2Elements = h2Elements;
    if (!allH2ElementsVisible) tempH2Elements = getAndSetH2Elements() || [];

    // we want to find the nearest h2 element to the top of the screen
    for (let i = 0; i < tempH2Elements.length; i++) {
      const { top } = tempH2Elements[i].getBoundingClientRect();
      const { innerHeight } = window;

      const percentageFromTop = 0.12;
      if (top > innerHeight * percentageFromTop) continue;

      targetElement = tempH2Elements[i];
    }

    // when nearest is found we always put the hash of it in the url
    if (targetElement) {
      const text = trasnformTextToUrlHash(
        (targetElement as HTMLElement).innerText
      );
      window.history.replaceState('', document.title, text);
      setHash(text);
    } else {
      window.history.replaceState('', document.title, '');
      setHash('');
    }
  }

  useEffect(() => {
    const debouncedScroll = debounce(() => handleScroll(h2Elements), 20);

    if (isBrowser()) {
      window.addEventListener('scroll', debouncedScroll);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('scroll', debouncedScroll);
      }
    };
  }, [stateHash, h2Elements.length, isScrolling]);

  useEffect(() => {
    const tempH2Elements = getAndSetH2Elements();
    if (tempH2Elements) {
      const tempHash = window.location.hash;
      if (tempHash) {
        handleScroll(tempH2Elements);
        handleHashChange(tempH2Elements);
      } else {
        scrollToPosition(0, 0);
      }
    }
  }, []);

  function handleHashChange(h2ElementsInner?: HTMLHeadingElement[]) {
    const newHash = window.location.hash;

    if (newHash.includes('h2-')) {
      const text = transformUrlHashToText(newHash);

      scrollToH2Element(text, h2ElementsInner || h2Elements);
    }

    setHash(newHash);
  }

  function cleanupLanguageText(text: string) {
    // we want cleanup for all languages
    return text.replace(/\s/g, '');
  }

  function scrollToH2Element(text: string, h2Elements: HTMLHeadingElement[]) {
    let targetElement: HTMLHeadingElement | null = null;

    h2Elements.forEach((element) => {
      if (
        cleanupLanguageText(element.innerText.toLowerCase()) ===
        cleanupLanguageText(text)
      ) {
        targetElement = element as HTMLHeadingElement;
      }
    });

    if (targetElement) {
      // we want to scroll to the top of the element (10% from top of the screen)
      const offset =
        (targetElement as HTMLHeadingElement).offsetTop -
        window.innerHeight * 0.1;

      scrollToPosition(0, offset);
    }
  }

  function trasnformTextToUrlHash(text: string) {
    return `#h2-${decodeURIComponent(text).replaceAll(' ', '_').toLowerCase()}`;
  }

  function transformUrlHashToText(dirtyText: string) {
    const preCleanText = dirtyText.slice(4);
    return decodeURIComponent(preCleanText.replaceAll('_', ' ').toLowerCase());
  }

  function isSelected(text: string) {
    return (
      !isScrolling &&
      !!stateHash &&
      transformUrlHashToText(stateHash) == text.trim().toLowerCase()
    );
  }

  function navigateTo(text: string) {
    history.replaceState('', document.title, trasnformTextToUrlHash(text));
    const tempH2Elements = getAndSetH2Elements() || [];
    handleHashChange(tempH2Elements);
  }

  return (
    <div>
      <StyledText>{title}</StyledText>

      <HeadingLinksWrapper>
        {headings.map((text) => (
          <HeadingInnerWrapper key={text}>
            <StyledLine selected={isSelected(text)} />
            <StyledLink
              onClick={() => navigateTo(text)}
              selected={isSelected(text)}
            >
              {text}
            </StyledLink>
          </HeadingInnerWrapper>
        ))}
      </HeadingLinksWrapper>

      <ContentWrapper></ContentWrapper>
    </div>
  );
}
