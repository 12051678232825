import { TabbedCodeBlockProps } from './types';

type TabbedCodeBlockAction = { url: string; label: string };

export const transformTabbedCodeBlockProps = ({
  primary = {},
  items = [],
}: TabbedCodeBlockProps) => {
  const { cta_link_1, cta_label_1, cta_link_2, cta_label_2 } = primary;
  const actions = [
    { url: cta_link_1?.url, label: cta_label_1 },
    { url: cta_link_2?.url, label: cta_label_2 },
  ].filter(
    (action): action is TabbedCodeBlockAction => !!action.url && !!action.label
  );

  const languages = items
    .map((item) => ({
      id: item?.language?.toLowerCase(),
      label: item?.language,
      code: item?.code,
    }))
    .filter((language) => language.code);

  return {
    isDarkTheme: primary.is_dark_theme,
    title: primary.title,
    subtitle: primary.subtitle,
    actions,
    languages,
    showCopyButton: primary.show_copy_button ?? true,
  };
};

export const getMaxLinesOfCodeCount = (codes: string[]) => {
  let max = 0;

  for (const code of codes) {
    const lines = code.split('\n');
    if (lines.length > max) {
      max = lines.length;
    }
  }

  return max;
};
