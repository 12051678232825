import { useState } from 'react';
import { TABS } from './constants';
import { SocialProofToggle } from './components/SocialProofToggle';
import { Tabs } from './Tabs';
import { SocialProofProps } from './types';
import { Slice, SliceHeader } from '../Slice';
import { buildTrackingId } from '@smartproxy-web/shared/helpers';

export const SocialProofBlock = ({
  primary: { title, subtitle, is_dark: isDark, background },
  items,
}: SocialProofProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const tabNames = items.map(
    ({
      tab_document: {
        document: {
          data: { tab_name: tabName },
        },
      },
    }) => tabName
  );
  const tabContent = items.map(({ tab_document: { document: data } }) => data);
  const toggleColor = isDark ? 'gradiant' : 'default';

  const renderTabContent = (data, index) => {
    const isActiveTab =
      Object.values(TABS).includes(data.tab) && selectedTabIndex === index;

    return (
      isActiveTab && (
        <div key={data.tab_name.text}>
          <Tabs
            {...data}
            repeatable_zone={data.repeatable_zone}
            isDark={isDark}
          />
        </div>
      )
    );
  };

  return (
    <Slice
      isDark={isDark}
      backgroundColor={background}
      id={buildTrackingId({ section: title.text })}
    >
      <SliceHeader
        isDark={isDark}
        title={title}
        description={subtitle}
        sx={{ marginBottom: { base: '32px', sm: '60px', lg: '80px' } }}
        bottomItems={
          <SocialProofToggle
            color={toggleColor}
            tabNames={tabNames}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        }
      />
      {tabContent.map((data, index) => renderTabContent(data.data, index))}
    </Slice>
  );
};
