import { useState } from 'react';
import { FaqItem } from './FaqItem';
import { FaqContainer } from './styledComponents';
import { Link } from 'prismic-reactjs';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useShowMoreLess, useSliceDetails } from '../../hooks';
import { LinkButton } from '../LinkButton';
import {
  Box,
  Button,
  GatsbyImg,
  PrismicTextField,
  TGatsbyImage,
} from '@smartproxy-web/ui';
import { Slice, SliceHeader } from '../Slice';
import { GOOGLE_SCHEMA, SCHEMA_LINKS } from '@smartproxy-web/shared/constants';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';

interface FaqItemProps {
  question: PrismicTextField;
  answer: PrismicTextField;
}

interface FaqBlockProps {
  primary: {
    background: string;
    is_background_dark?: boolean;
    title: PrismicTextField;
    cta_label?: PrismicTextField;
    cta_link?: Link;
    cta_icon?: TGatsbyImage;
    cta_icon_position: 'left' | 'right';
  };
  items: FaqItemProps[];
}

export const FaqBlock = ({
  primary: {
    title,
    background,
    is_background_dark: isDark,
    cta_link: ctaLink,
    cta_label: ctaLabel,
    cta_icon: ctaIcon,
    cta_icon_position: ctaIconPosition = 'left',
  },
  items,
}: FaqBlockProps) => {
  const { id: sliceId } = useSliceDetails();
  const [openedItem, setOpenedItem] = useState<number | null>(0);
  const { showAll, handleShowMoreLess, buttonText } = useShowMoreLess();

  const isItemsMoreThanSix = items.length > 6;
  const slicedItems = items.slice(0, showAll ? items.length : 6);
  const color = isDark ? 'white' : 'black';

  const trackingSelector = initBuildTrackingId({
    section: 'faq',
  });

  const toggleOpen = (idx: number | null, item: string) => {
    logPostHogEvent(trackingSelector({ context: item }));
    setOpenedItem(openedItem !== idx ? idx : null);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: item,
        sectionId: sliceId,
      });
    }
  };

  const Cta = (
    <LinkButton
      to={ctaLink?.url}
      fullWidth
      variant="outlined"
      color={color}
      leftIcon={ctaIconPosition === 'left' && <GatsbyImg image={ctaIcon!} />}
      rightIcon={ctaIconPosition === 'right' && <GatsbyImg image={ctaIcon!} />}
      data-tracker={trackingSelector({ context: 'cta-' + ctaLabel?.text })}
      onClick={() =>
        logPostHogEvent(trackingSelector({ context: 'cta-' + ctaLabel?.text }))
      }
    >
      {ctaLabel?.text}
    </LinkButton>
  );

  const ButtonShowMoreLess = (
    <Button
      fullWidth
      variant="outlined"
      color={color}
      leftIcon={ctaIconPosition === 'left' && <GatsbyImg image={ctaIcon!} />}
      rightIcon={ctaIconPosition === 'right' && <GatsbyImg image={ctaIcon!} />}
      data-tracker={trackingSelector({ context: 'cta-' + buttonText })}
      onClick={() => {
        handleShowMoreLess();
        logPostHogEvent(trackingSelector({ context: buttonText }));
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <Slice backgroundColor={background} isDark={isDark}>
      <FaqContainer
        className={GOOGLE_SCHEMA.schemaMarkup}
        itemScope
        itemType={SCHEMA_LINKS.FAQ}
        data-section-id="faq"
      >
        <SliceHeader title={title} isDark={isDark} />
        {slicedItems.map(({ question, answer }, index) => (
          <FaqItem
            question={question}
            answer={answer}
            key={index}
            isOpen={openedItem === index}
            onToggle={() => toggleOpen(index, question.text)}
            isDark={isDark}
            selector={trackingSelector({ context: question.text })}
          />
        ))}
        {isItemsMoreThanSix && (
          <Box
            sx={{
              marginTop: { base: '32px', md: '36px' },
              width: { base: '100%', md: 'fit-content' },
            }}
          >
            {ButtonShowMoreLess}
          </Box>
        )}
        {ctaLink?.url && !isItemsMoreThanSix && (
          <Box
            sx={{
              marginTop: { base: '32px', md: '36px' },
              width: { base: '100%', md: 'fit-content' },
            }}
          >
            {Cta}
          </Box>
        )}
      </FaqContainer>
    </Slice>
  );
};
