import {
  AwardsImage,
  ImageContainer,
  StyledCard,
  StyledTitle,
} from '../styledComponents';
import { GatsbyImg } from '@smartproxy-web/ui';
import { RichText } from '../../RichText';
import { Description } from './Description';
import { Author } from './Author';
import { CardItemProps } from '../types';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { useSliceDetails } from '../../../hooks';
import { logEvent } from '../../../helpers';

export const Card = ({
  title,
  isDark,
  stars_review: stars,
  logo,
  icon,
  description,
  tabName,
  index,
  user_name: userName,
  block_url: blockUrl,
  date,
}: CardItemProps) => {
  const { id: sliceId } = useSliceDetails();

  const buildTrackingId = initBuildTrackingId({
    section: 'social-proof',
  });

  const cardTrackingId = buildTrackingId({
    context: `${tabName.text + (index + 1)}`,
    type: 'link',
  });

  return (
    <StyledCard
      hasLink={!!blockUrl?.url}
      key={title.text}
      isDark={isDark}
      to={blockUrl?.url}
      data-tracker={cardTrackingId}
      rel="noopener noreferrer nofollow"
      onClick={() =>
        logEvent({
          sliceId,
          eventId: cardTrackingId,
          elementId: `${tabName.text + (index + 1)}`,
        })
      }
    >
      <div>
        {!!stars ? (
          <ImageContainer>
            <GatsbyImg image={stars} />
          </ImageContainer>
        ) : (
          <AwardsImage>
            <GatsbyImg image={logo! || icon!} />
          </AwardsImage>
        )}
        <StyledTitle>
          <RichText field={title} />
        </StyledTitle>
        <Description description={description} link={blockUrl?.url} />
      </div>
      {!!userName?.text && <Author user_name={userName} date={date} />}
    </StyledCard>
  );
};
