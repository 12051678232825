import styled from '@emotion/styled';
import { Heading, theme, mediaQueries, Text } from '@smartproxy-web/ui';

export const Wrapper = styled.div({
  padding: '1.25rem',
  width: '100%',

  [mediaQueries.xl]: {
    padding: '3rem',
  },
  [mediaQueries.md]: {
    padding: '2.5rem',
  },
});

export const HeadingStyled = styled(Heading)({
  textAlign: 'left',
  marginBottom: '1.5rem',
  fontSize: '1.25rem',

  [mediaQueries.md]: {
    fontSize: '1.5rem',
  },
});

export const BlogAuthorContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  padding: '2.25rem',
  borderRadius: '0.75rem',
  backgroundColor: theme.colors.neutral.N04,
});

export const BlogAuthorImageWrapper = styled.div({
  width: '4.5rem',
  height: '4.5rem',
  img: {
    borderRadius: '50%',
    verticalAlign: 'middle',
    display: 'inline-block',
    objectFit: 'cover',
  },
});

export const BlogAuthorInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '0.25rem',
});

export const BlogAuthorWrapperContainer = styled.div({
  display: 'flex',
  width: '100%',
  gap: '1.5rem',
});

export const BlogAuthorName = styled.div({
  p: {
    fontSize: '1.125rem',
    fontWeight: 700,

    [mediaQueries.md]: {
      fontSize: '1.25rem',
    },
  },
});

export const BlogAuthorDescription = styled.div({
  p: {
    fontSize: '0.75rem',
  },
});

export const ParagraphWrapper = styled(Text)({
  marginTop: '3rem',
  color: theme.colors.neutral.N50,
  fontSize: '0.75rem',
  lineHeight: '1rem',
});
