import { COMPACT_HEADER_WIDTH } from '../constants';
import { ROOT_NAV_STACK_ITEM } from './constants';
import { INavItem } from './types';

export const isCompactHeader = () => window.innerWidth <= COMPACT_HEADER_WIDTH;

export const buildRootNavStack = (categories: INavItem[]): INavItem[] => {
  return [
    {
      ...ROOT_NAV_STACK_ITEM,
      children: categories,
    },
  ];
};
