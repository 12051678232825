import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

export const logEvent = ({
  eventId,
  elementId,
  sliceId,
  elementGroup,
  elementSubGroup,
}: {
  eventId: string;
  elementId: string;
  sliceId?: string;
  elementGroup?: string;
  elementSubGroup?: string;
}) => {
  logPostHogEvent(eventId);
  if (sliceId) {
    logGAElementClickEvent({
      elementId: elementId,
      sectionId: sliceId,
      elementGroup,
      elementSubGroup,
    });
  }
};
