import styled from '@emotion/styled';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
} from 'theme';
import hideable from 'utils/hideable';

const Modal = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  position: CSS_POSITION.FIXED,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  textAlign: CSS_ALIGN.CENTER,
  background: 'rgba(0,0,0,.9)',
  zIndex: 10,
  overflowY: isVisible ? 'hidden' : 'auto',
}));

export default hideable(Modal);
