import styled from '@emotion/styled';
import { mediaQueries, theme } from '../../theme';
import { hexToRgba } from '@smartproxy-web/shared/utils';
import { toRem } from '../../utils';

export const StyledLightbox = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  overflowY: 'auto',
  backgroundColor: hexToRgba(theme.colors.neutral.N99, 0.9),
  zIndex: 10,

  '&.lightbox-backdrop-enter': {
    opacity: '0',
  },
  '&.lightbox-backdrop-enter-active': {
    opacity: '1',
    transition: 'opacity 750ms',
  },
  '&.lightbox-backdrop-exit': {
    opacity: '1',
  },
  '&.lightbox-backdrop-exit-active': {
    opacity: '0',
    transition: 'opacity 750ms',
  },

  '&.lightbox-backdrop-enter [data-lightbox]': {
    opacity: '0',
    transform: 'scale(0.9)',
  },
  '&.lightbox-backdrop-enter-active [data-lightbox]': {
    opacity: '1',
    transform: 'scale(1)',
    transition: 'opacity 750ms, transform 500ms',
    transitionDelay: '500ms',
  },
  '&.lightbox-backdrop-exit [data-lightbox]': {
    opacity: '1',
    transform: 'scale(1)',
  },
  '&.lightbox-backdrop-exit-active [data-lightbox]': {
    opacity: '0',
    transform: 'scale(0.95)',
    transition: 'opacity 750ms, transform 500ms',
  },

  [mediaQueries.md]: {
    padding: theme.spacings.sectionSpacing,
  },
});
export const StyledLightboxInner = styled.div({
  width: '100%',
  height: '100%',
  aspectRatio: '16 / 9',
  maxWidth: toRem(1440),

  '> div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
});
export const StyledLightboxControls = styled.div({
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  padding: theme.spacings.XS,
  display: 'flex',
  justifyContent: 'flex-end',
  zIndex: 1,
});

export const StyledLightboxTriggerWrapper = styled.div({
  height: '100%',
  width: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
