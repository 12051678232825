import { useEffect, useRef, useState } from 'react';

export function useHover() {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const hoverRef = useRef<HTMLElement | null>(null);

  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  useEffect(() => {
    const node = hoverRef.current;

    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [hoverRef.current]);

  return { hoverRef, isHovered };
}
