export const getSessionStorageItem = (itemName: string) =>
  sessionStorage.getItem(itemName)
    ? sessionStorage.getItem(itemName)
    : undefined;

export const setSessionStorageItem = (itemName: string, itemValue: string) =>
  sessionStorage.setItem(itemName, itemValue);

export const getLocalStorageItem = (itemName: string) =>
  localStorage.getItem(itemName) ? localStorage.getItem(itemName) : undefined;

export const setLocalStorageItem = (itemName: string, itemValue: string) =>
  localStorage.setItem(itemName, itemValue);
