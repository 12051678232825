import styled from '@emotion/styled';
import { Box } from '../Box';
import { ThemeColor, mediaQueries, theme } from '../../theme';

const tabMinWidth = 160;
export const tabContainerPadding = 2;

export const ToggleButtonContainer = styled.div<{ color?: ThemeColor }>(
  ({ color }) => ({
    position: 'relative',
    borderRadius: '24px',
    padding: tabContainerPadding,
    ...(color === 'gradient' && { borderColor: theme.colors.neutral.N80 }),
    display: 'flex',
    backgroundColor:
      color === 'gradient' ? 'transparent' : theme.colors.neutral.N00,
    flexDirection: 'column',
    width: '100%',
    gap: '20px',
    margin: '0 auto',
    ':hover': {
      transition: 'all 0.1s ease-in-out 0s',
      background: 'transparent',
    },

    [mediaQueries.md]: {
      flexDirection: 'row',
      width: 'fit-content',
      gap: 0,
      border:
        color === 'gradient'
          ? `1px solid ${theme.colors.neutral.N80}`
          : `1px solid ${theme.colors.neutral.N04}`,
      ':hover': {
        background:
          color === 'gradient'
            ? theme.colors.neutral.N94
            : theme.colors.neutral.N04,
      },
    },
  })
);

const pseudoGradientBorderStyles = {
  position: 'absolute',
  content: '""',
  inset: -1,
  background: theme.colors.gradient.default,
  padding: 2,
  WebkitMask:
    'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  WebkitMaskComposite: 'xor',
  maskComposite: 'exclude',
};

export const Tab = styled.div<{ isActive: boolean; color?: ThemeColor }>(
  ({ isActive, color }) => ({
    padding: '12px 24px',
    borderRadius: '24px',
    minWidth: tabMinWidth,
    cursor: 'pointer',
    textAlign: 'center',
    color: isActive ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
    fontFamily: theme.fonts.family.main,
    fontSize: theme.fonts.size.p_medium,
    lineHeight: 1,
    fontWeight: 500,
    zIndex: 2,
    transition: '0.3s',
    backgroundColor: isActive ? theme.colors.neutral.N99 : 'none',
    border: isActive ? 'none' : `1px solid ${theme.colors.neutral.N04}`,
    ...(color === 'gradient' && {
      backgroundColor: 'unset',
      color: theme.colors.neutral.N00,
      border: `1px solid ${theme.colors.neutral.N80}`,
      position: 'relative',
      '&::after': {
        ...pseudoGradientBorderStyles,
        content: isActive ? '""' : 'none',
        height: 'auto',
        borderRadius: '24px',
      },
    }),

    [mediaQueries.md]: {
      border: 'none',
      position: 'static',
      backgroundColor: 'none',
      '&::after': {
        content: 'none',
      },
      ':hover': {
        transition: 'all 0.1s ease-in-out 0s',
        background: 'transparent',
      },
    },
  })
);

export const SelectedTabBackground = styled(Box)<{ color?: ThemeColor }>(
  ({ color }) => ({
    position: 'absolute',
    minWidth: tabMinWidth,
    top: tabContainerPadding,
    padding: '12px 24px',
    borderRadius: '24px',
    height: `calc(100% - ${tabContainerPadding * 2}px)`,
    minHeight: '40px',
    zIndex: 1,
    left: 0,
    transition: '0.3s',
    ...(color === 'gradient' && {
      '&::after': {
        ...pseudoGradientBorderStyles,
        borderRadius: '24px',
      },
      display: 'none',
    }),

    [mediaQueries.md]: {
      display: 'block',
      backgroundColor: theme.colors.neutral.N99,
    },
  })
);
