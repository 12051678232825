import styled from '@emotion/styled';
import { DOM_STATE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';

export const LocationFlagsContainer = styled.div<{ flagsAlignment: string }>(
  ({ flagsAlignment }) => ({
    margin: '0 auto',
    display: 'flex',
    gridArea: 'flags',
    columnGap: flagsAlignment !== 'center' ? '44px' : 'unset',
    alignItems: flagsAlignment !== 'center' ? 'flex-start' : 'center',
    width: '100%',
    maxWidth: '1024px',
    textAlign: 'center',
    [MEDIA_QUERIES.TABLET]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  })
);

export const StyledSlice = styled.section<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  width: '100%',
  padding: '80px 40px',
  backgroundColor,
  'h1, h2, h3, h4, h5': {
    color: theme.colors.neutral.N99,
  },
  'p, ul, ol, em, strong, li': {
    color: theme.colors.neutral.N80,
  },
  [MEDIA_QUERIES.TABLET]: {
    padding: '60px 40px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: '32px 20px',
  },
}));

export const Content = styled.div<{ flagsAlignment: string }>(
  ({ flagsAlignment }) => ({
    margin: '0 auto',
    maxWidth: '1120px',
    display: 'grid',
    justifyContent: 'center',
    columnGap: '44px',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas:
      flagsAlignment == 'center'
        ? '"text" "flags" "cta"'
        : flagsAlignment == 'left'
        ? ' "flags text"  "flags cta"'
        : '"text flags"  "cta flags"',
    header: {
      gridArea: 'text',
      alignItems: flagsAlignment !== 'center' ? 'flex-start' : 'center',
      marginBottom: flagsAlignment !== 'center' ? '36px' : '80px',
      'h2,h3,h4,h5,p': {
        textAlign: flagsAlignment !== 'center' ? 'left' : 'center',
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateAreas: '"text" "flags" "cta"',
      header: {
        marginBottom: '60px',
        alignItems: 'flex-start',
        'h2,h3,h4,h5,h6,p': {
          textAlign: 'left',
        },
      },
    },
  })
);

export const LocationFlagsList = styled.ul<{
  columnNumber: number;
  flagsAlignment: string;
}>(({ columnNumber, flagsAlignment }) => ({
  listStyle: 'none',
  display: 'grid',
  gridTemplateColumns:
    flagsAlignment !== 'center'
      ? 'repeat(3, minmax(125px, 148px))'
      : `repeat(${columnNumber || 6}, 148px)`,
  padding: 0,
  width: '100%',
  columnGap: '16px',
  marginBottom: '36px',
  rowGap: themev2.spacings.S,
  justifyContent: 'center',
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: 'repeat(3, minmax(125px, 148px))',
    justifyContent: 'flex-start',
    columnGap: themev2.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: 'repeat(2, minmax(125px, 148px))',
  },
}));

export const LocationFlagsItem = styled.li({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '202px',
  width: '148px',
  background: themev2.colors.white.default,
  borderRadius: '12px',
  boxShadow: '0px 4px 16px rgba(29, 29, 31, 0.08)',
  border: `1px solid ${themev2.colors.neutral.N04}`,
  transition: '0.3s',
  a: {
    color: themev2.colors.neutral.N99,
  },
  p: {
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    fontWeight: 400,
    color: themev2.colors.black.S600,
    margin: 0,
    ':last-of-type': {
      marginTop: '16px',
    },
  },
  strong: {
    fontWeight: 700,
    color: themev2.colors.black.S600,
    fontSize: themev2.fonts.size.h4,
    lineHeight: themev2.fonts.lineHeight.h4,
  },
  [DOM_STATE.HOVER]: {
    border: `1px solid ${themev2.colors.blue.default}`,
    cursor: 'pointer',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '100%',
  },
});

export const FlagWrapper = styled.div({
  marginBottom: '16px',
});

export const CtaContainer = styled.div({
  width: '219px',
  a: {
    width: '100%',
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
});

export const HideableCtaContainer = hideable(CtaContainer);

export const AvailableCitiesContainer = styled.div({
  marginTop: '36px',
  'p, strong, a': {
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    fontFamily: themev2.fonts.family.additional,
  },
  p: {
    color: themev2.colors.neutral.N80,
  },
  a: {
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    alignSelf: 'flex-start',
    p: {
      textAlign: 'left',
    },
  },
});

export const CtaComponentContainer = styled.div<{ flagsAlignment: string }>(
  ({ flagsAlignment }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: flagsAlignment !== 'center' ? 'left' : 'center',
    gridArea: 'cta',
    [MEDIA_QUERIES.TABLET]: {
      maxWidth: 'unset',
      position: 'initial',
      alignItems: 'flex-start',
      textAlign: 'left',
      bottom: 'initial',
    },
  })
);

export const HideableAvailableCitiesContainer = hideable(
  AvailableCitiesContainer
);
