import { LinkButton } from '../LinkButton';
import { Slice, SliceHeader } from '../Slice';
import { MostPopularPost } from './MostPopularPost';
import {
  BlogParentContainer,
  LeftSideContainer,
  RightSideContainer,
} from './styledComponents';
import { BlogParentProps } from './types';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { getMostPopularData } from './utils';
import { ArticleBox } from '../ArticleBox';
import { getStructuredPostData } from '../KHArticles/utils';
import { useBuildContext } from '../../contexts';
import { translations } from '../BlogPostIntro/constants';
import { logEvent } from '../../helpers';
import { useSliceDetails } from '../../hooks';
import { Box } from '@smartproxy-web/ui';

export const BlogParent = ({
  primary: {
    is_dark_theme: theme,
    title,
    subtitle,
    cta_text: ctaText,
    cta_url: ctaUrl,
  },
  items,
  articles,
}: BlogParentProps) => {
  const { id: sliceId } = useSliceDetails();
  const blogPostData = getMostPopularData(items);
  const mostRecentPost = getStructuredPostData(articles.slice(0, 1));
  const { lang } = useBuildContext();

  const ctaTrackingSelector = initBuildTrackingId({ context: ctaText });
  const ctaTheme = theme ? 'black' : 'white';

  return (
    <Slice isDark={!theme}>
      <SliceHeader title={title} description={subtitle} isDark={!theme} />
      <BlogParentContainer isDark={!theme}>
        <LeftSideContainer isDark={!theme}>
          <h3>{translations.most_recent[lang]}</h3>
          <ArticleBox isLightTheme={theme} {...mostRecentPost[0]} />
        </LeftSideContainer>
        <RightSideContainer isDark={!theme}>
          <h3>{translations.most_popular[lang]}</h3>
          {blogPostData.map((item) => (
            <MostPopularPost key={item.date} {...item} theme={theme} />
          ))}
        </RightSideContainer>
      </BlogParentContainer>
      <Box
        sx={{
          width: { base: '100%', md: 'fit-content' },
          margin: '48px auto 0',
        }}
      >
        <LinkButton
          fullWidth
          to={ctaUrl.url}
          color={ctaTheme}
          variant="outlined"
          data-tracker={ctaTrackingSelector()}
          onClick={() =>
            logEvent({
              sliceId,
              elementId: ctaText,
              eventId: ctaTrackingSelector(),
            })
          }
        >
          {ctaText}
        </LinkButton>
      </Box>
    </Slice>
  );
};
