import styled from '@emotion/styled';
import { Button, theme } from '@smartproxy-web/ui';
import { LinkButton } from '../LinkButton';

export const Card = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  display: 'flex',
  flexDirection: 'column',

  borderRadius: '1.5rem',
  border: `1px solid ${
    isDark ? theme.colors.neutral.N80 : theme.colors.neutral.N10
  }`,
  backgroundColor: isDark ? theme.colors.neutral.N94 : theme.colors.neutral.N02,
  padding: '1.5rem',
  height: '100%',
  flexBasis: '0px',
}));

export const HeaderIconWrapper = styled.span({
  minWidth: '2.25rem',
  minHeight: '2.25rem',
  maxWidth: '2.25rem',
  maxHeight: '2.25rem',
  marginBottom: '1rem',
});

export const TitleWrapper = styled.div<{ isDark: boolean }>(({ isDark }) => ({
  marginBottom: '0.75rem',

  strong: {
    color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  },
}));

export const DescriptionWrapper = styled.div<{ isDark: boolean }>(
  ({ isDark }) => ({
    marginBottom: '1.5rem',

    p: {
      color: isDark ? theme.colors.neutral.N40 : theme.colors.neutral.N80,
    },
  })
);

export const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

export const FooterWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

export const FooterIconsWrapper = styled.span({
  display: 'flex',
  width: '1.5rem',
  height: '1.5rem',
});
export const StyledButton = styled(Button)({
  padding: '0.25rem 1rem',
  minWidth: 'auto',
  height: 'auto',
  borderRadius: '0.375rem',
});

export const IconButton = styled(Button)<{ isDark: boolean }>(({ isDark }) => ({
  padding: '0.25rem',
  minWidth: 'auto',
  height: 'auto',
  borderRadius: '50%',
  path: {
    fill: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
  },
  ':hover': {
    backgroundColor: isDark
      ? theme.colors.neutral.N80
      : theme.colors.neutral.N20,
  },
}));

export const StyledLinkButton = styled(LinkButton)({
  marginRight: '0.25rem',
});
