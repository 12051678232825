import styled from '@emotion/styled';
import { richTextParagraphElements } from '@smartproxy-web/shared/constants';
import { mediaQueries, StyledButton, theme } from '@smartproxy-web/ui';

export const SideBannerWrapper = styled.div<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  display: 'none',
  [mediaQueries.md]: {
    backgroundColor,
    display: 'block',

    alignItems: 'start',
    width: '100%',
  },
}));

export const Heading = styled.div({
  fontFamily: theme.fonts.family.additional,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  color: theme.colors.neutral.N50,
  marginBottom: theme.spacings.S,
  letterSpacing: 1.2,
  textTransform: 'uppercase',
  fontWeight: 500,
  [richTextParagraphElements]: {
    fontSize: theme.fonts.size.p_xsmall,
  },
});

export const SideBannerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: `36px ${theme.spacings.S}`,
  position: 'relative',
  borderColor: 'transparent',
  ':after': {
    borderRadius: '12px',
    position: 'absolute',
    content: '""',
    inset: -1,
    background: theme.colors.gradient.default,
    padding: 1,
    WebkitMask:
      'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
});

export const StyledButtonContainer = styled.div({
  marginTop: theme.spacings.S,
  zIndex: 1,
  p: {
    paddingBottom: '12px',
    paddingTop: '12px',
    textAlign: 'center',
    fontFamily: theme.fonts.family.additional,
    color: theme.colors.neutral.N50,
  },
  [String(StyledButton)]: {
    width: '100%',
    span: {
      fontSize: theme.fonts.size.p_medium,
      lineHeight: '24px',
    },
  },
});

export const SideBannerTitle = styled.div({
  width: '100%',
  color: theme.colors.neutral.N99,
  fontWeight: 700,
  fontFamily: theme.fonts.family.additional,
  [richTextParagraphElements]: {
    fontSize: theme.fonts.size.p_large,
  },
});

export const SideBannerDescription = styled.div({
  color: theme.colors.neutral.N99,
  marginTop: '16px',
  [richTextParagraphElements]: {
    fontSize: theme.fonts.size.p_medium,
  },
});
