import { forwardRef } from 'react';
import { StyledButton } from '@smartproxy-web/ui';
import { StyledButtonInner, StyledLinkButton } from './styledComponents';
import { LinkButtonProps } from './types';

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkButtonProps>
>((props, ref) => {
  const {
    children,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    color = 'red',
    disabled = false,
    fullWidth = false,
    variant = 'filled',
    size = 'md',
    compact = false,
    onClick,
    to,
    ...rest
  } = props;

  return (
    <StyledLinkButton
      {...rest}
      ref={ref}
      to={to}
      variant={variant}
      color={color}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      <StyledButton
        as="div"
        size={size}
        variant={variant}
        compact={compact}
        fullWidth={fullWidth}
        color={color}
        disabled={disabled}
      >
        <StyledButtonInner>
          {LeftIcon}
          <span>{children}</span>
          {RightIcon}
        </StyledButtonInner>
      </StyledButton>
    </StyledLinkButton>
  );
});
