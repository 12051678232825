import styled from '@emotion/styled';
import { mediaQueries, theme, toRem } from '@smartproxy-web/ui';
import { richTextHeadingElements } from '@smartproxy-web/shared/constants';
import { Slice } from '../Slice';

export const StyledTextGridWrapper = styled(Slice)<{ isDark: boolean }>(
  ({ isDark }) => ({
    [StyledTextGridItem.toString()]: isDark && {
      color: theme.colors.neutral.N40,
    },
    [StyledTextGridItemHeader.toString()]: isDark && {
      color: theme.colors.neutral.N00,
    },
  })
);

export const StyledTextGrid = styled.div<{
  columnsCount: number;
}>(({ columnsCount }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: toRem(32),

  [mediaQueries.md]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    gap: toRem(60),
  },
  [mediaQueries.lg]: {
    gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
    gap: toRem(80),
  },
}));

export const StyledTextGridItem = styled.article({
  display: 'flex',
  flexDirection: 'column',
  gap: toRem(12),
  fontSize: theme.fonts.size.p_large,
  color: theme.colors.neutral.N80,
});

export const StyledTextGridItemHeader = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: toRem(16),
  color: theme.colors.neutral.N99,
  [richTextHeadingElements]: {
    fontFamily: theme.fonts.family.additional,
    fontSize: theme.fonts.size.h4,
    lineHeight: toRem(24),
  },
});

export const ImageWrapper = styled.div({
  width: '36px',
  height: '36px',
});

export const StyledTextGridWrapperFooter = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: toRem(32),
  a: {
    width: '100%',
  },
  [mediaQueries.md]: {
    marginTop: toRem(60),
  },
  [mediaQueries.lg]: {
    marginTop: toRem(80),
    a: {
      width: 'initial',
    },
  },
});
