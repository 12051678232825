import { PrismicTextField } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { RichTextWrapper, TextWrapper } from './styledComponents';

export interface TextBlockContentProps {
  primary: {
    text: PrismicTextField;
    is_centered: boolean;
    background: string;
    is_disclaimer: boolean;
  };
}

export const TextBlock = ({
  primary: {
    text,
    is_centered: isCentered,
    background,
    is_disclaimer: isDisclaimer,
  },
}: TextBlockContentProps) => {
  return (
    <RichTextWrapper text={text} background={background}>
      <TextWrapper isCentered={isCentered} isDisclaimer={isDisclaimer}>
        <RichText field={text} />
      </TextWrapper>
    </RichTextWrapper>
  );
};

export default TextBlock;
