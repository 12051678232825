import {
  StyledAuthorAvatar,
  StyledAvatarWrapper,
  StyledBlogIntroWrapper,
  StyledConstrainedWidthBlock,
  StyledExcerptWrapper,
  VideoContainer,
} from './styledComponents';
import {
  Box,
  GatsbyImg,
  Heading,
  IconChevronLeft,
  IconClock,
  TGatsbyImg,
  Text,
  theme,
} from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { useBuildContext } from '../../contexts';
import { LinkButton } from '../LinkButton';
import { useEffect, useState } from 'react';
import { PostShareMenu } from './PostShareMenu';
import { PostTags, PostTopic } from './PostTags';
import { PrismicTextField } from '@smartproxy-web/ui';
import { useSliceDetails } from '../../hooks';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { translations } from './constants';

export interface BlogPostIntroProps {
  title?: string;
  isNew?: boolean;
  excerpt?: PrismicTextField;
  heroImage?: TGatsbyImg;
  heroVideo?: string;
  topics?: PostTopic[];
  author?: {
    image: TGatsbyImg;
    name: string;
  };
  publishDate?: string;
  readingTime?: number;
  isV2: boolean;
}

export const BlogPostIntro = ({
  title,
  isNew,
  excerpt,
  heroImage,
  heroVideo,
  author,
  publishDate,
  readingTime,
  topics,
}: BlogPostIntroProps) => {
  const { site, lang } = useBuildContext();
  const [postUrl, setPostUrl] = useState(`https://${site}/blog`);

  const { id: sliceId, name: sliceName } = useSliceDetails();
  const createTrackingID = initBuildTrackingId({
    section: sliceName,
  });
  const backToBlogBtnTrackingID = createTrackingID({
    context: 'back-to-blog',
    type: 'button',
  });

  useEffect(() => {
    setPostUrl(window.location.href);
  }, []);

  const hasHeroImage = !!heroImage;
  const hasHeroVideo = !!heroVideo;
  const hasHeroAsset = hasHeroImage || hasHeroVideo;
  const hasPostTags = isNew || Number(topics?.length) > 0;

  return (
    <StyledBlogIntroWrapper>
      <LinkButton
        to="/blog"
        variant="text"
        leftIcon={<IconChevronLeft />}
        color="gray"
        compact
        data-tracker={backToBlogBtnTrackingID}
        onClick={() => {
          logPostHogEvent(backToBlogBtnTrackingID);
          if (sliceId) {
            logGAElementClickEvent({
              elementId: backToBlogBtnTrackingID,
              sectionId: sliceId,
            });
          }
        }}
      >
        {translations.back_to_blog[lang]}
      </LinkButton>
      {hasPostTags && <PostTags isNew={isNew ?? false} topics={topics ?? []} />}
      <Heading
        sx={{ marginTop: { base: 32, md: 12 }, fontSize: { md: 36 } }}
        level={2}
        as="h1"
      >
        {title}
      </Heading>
      {!!excerpt && (
        <StyledExcerptWrapper>
          <RichText field={excerpt} />
        </StyledExcerptWrapper>
      )}
      <StyledConstrainedWidthBlock>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '16px 20px',
            }}
          >
            {!!author && (
              <StyledAvatarWrapper>
                <StyledAuthorAvatar>
                  <GatsbyImg image={author.image} />
                </StyledAuthorAvatar>
                <Text size="sm">{author.name}</Text>
              </StyledAvatarWrapper>
            )}
            <Text size="sm" sx={{ color: theme.colors.neutral.N40 }}>
              {publishDate}
            </Text>
            {!!readingTime && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  color: theme.colors.neutral.N40,
                }}
              >
                <IconClock />
                <Text size="sm">
                  {readingTime} {translations.min_read[lang]}
                </Text>
              </Box>
            )}
          </Box>
          <PostShareMenu excerpt={excerpt?.text} title={title} url={postUrl} />
        </Box>
        {hasHeroAsset && (
          <Box
            sx={{
              display: 'flex',
              gap: 16,
              marginTop: 32,
              marginBottom: 32,
              borderRadius: 12,
              overflow: 'hidden',
            }}
          >
            {hasHeroImage && !hasHeroVideo && <GatsbyImg image={heroImage} />}
            {hasHeroVideo && (
              <VideoContainer dangerouslySetInnerHTML={{ __html: heroVideo }} />
            )}
          </Box>
        )}
      </StyledConstrainedWidthBlock>
    </StyledBlogIntroWrapper>
  );
};
