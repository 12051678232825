import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import { theme } from 'theme-v2/theme';

export const StyledCard = styled.div<{
  isClickable: boolean;
  isCentered: boolean;
}>(({ isClickable, isCentered }) => ({
  boxShadow: '0px 4px 16px rgba(29, 29, 31, 0.08)',
  backgroundColor: theme.colors.neutral.N00,
  padding: '36px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isCentered ? 'center' : 'flex-start',
  borderRadius: '12px',
  width: '238px',
  height: '100%',
  border: `1px solid ${theme.colors.neutral.N04}`,
  transition: '0.3s',
  cursor: isClickable ? 'pointer' : 'unset',
  alignSelf: 'stretch',

  '& p, h3, h4, h5, h6, em, strong': {
    color: theme.colors.neutral.N99,
    fontFamily: theme.fonts.family.additional,
  },
  a: {
    fontFamily: theme.fonts.family.additional,
  },
  ':hover': {
    border: `1px solid ${
      isClickable ? theme.colors.primary.P99 : theme.colors.neutral.N04
    }`,
  },
  'h3, h4, h5, h6': {
    fontSize: '20px',
    fontWeight: 700,
  },

  [MEDIA_QUERIES.TABLET]: {
    width: '260px',
  },

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
    padding: '32px 20px',
  },
}));
