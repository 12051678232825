import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import { getInputBorderColor } from 'components/Input/styledComponents';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  FONT_FAMILY,
  getThemeBorderColor,
  getThemeFontFamily,
  getThemeInputBorderShape,
  getThemeInputFontSizeShape,
  getThemeInputHeightShape,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeTextColor,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';
import { IStyledTextarea } from './types';

const Description = styled.div<ITheme>((props) => ({
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.S),
  color: getThemeTextColor(props, COLOR_SHADE.GRAY),
  lineHeight: '15px',
  height: '0px',
}));

export const Container = styled.div({
  marginBottom: '10px',
  width: '100%',
});

export const StyledTextarea = styled.textarea<IStyledTextarea & ITheme>(
  ({ height, hasError, ...props }) => ({
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    border: `1px solid ${getInputBorderColor(props, hasError)}`,
    height: getThemeInputHeightShape(props, height),
    padding: getThemeInputPaddingShape(props),
    width: getThemeInputWidthShape(props),
    resize: 'none',
    '::placeholder': {
      color: getThemeBorderColor(props, COLOR_SHADE.GRAY),
    },
  })
);

export const HideableDescription = hideable(Description);
