import { ComponentPropsWithoutRef } from 'react';
import { themev2 } from 'theme-v2';
import { Box } from '../Box';
import { BoxProps } from '../Box/types';

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type HeadingAs = `h${HeadingLevel}`;

type HeadingProps = BoxProps &
  ComponentPropsWithoutRef<HeadingAs> & {
    as?: HeadingAs;
    level?: HeadingLevel;
  };

export const fontSizes: string[] = [
  themev2.fonts.size.h1,
  themev2.fonts.size.h2,
  themev2.fonts.size.h3,
  themev2.fonts.size.h4,
  themev2.fonts.size.h5,
  themev2.fonts.size.p_large,
];

export const Heading = ({ as, level = 2, sx, ...rest }: HeadingProps) => (
  <Box
    as={as ?? `h${level}`}
    sx={{
      fontSize: fontSizes[level - 1],
      lineHeight: '1.5',
      fontWeight: '600',
      ...sx,
    }}
    {...rest}
  />
);
