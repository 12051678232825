import styled from '@emotion/styled';
import { mediaQueries, theme } from '../../theme';

export const StyledCarousel = styled.div<{
  slidesGap?: number;
  slideWidth?: number;
  isDark: boolean;
  sideGradient: boolean;
}>(({ slidesGap, slideWidth, isDark, sideGradient }) => ({
  position: 'relative',
  overflow: 'hidden',

  '&::after': {
    zIndex: 1,
    position: 'absolute',
    background: isDark
      ? `linear-gradient(to right, ${theme.colors.neutral.N99}, transparent 10%, transparent 90%, ${theme.colors.neutral.N99})`
      : `linear-gradient(to right, ${theme.colors.neutral.N00}, transparent 10%, transparent 90%, ${theme.colors.neutral.N00})`,
    inset: 0,
    pointerEvents: 'none',

    [mediaQueries.md]: {
      content: sideGradient ? '""' : 'none',
    },
  },

  '.swiper-free-mode > .swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },
  '.swiper-wrapper': {
    gap: slidesGap,
    overflowX: 'auto',
  },
  '.swiper-slide': {
    height: 'auto',
    ...(!!slideWidth && { width: slideWidth }),
  },
}));
