import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY, HEADER_HEIGHT_MOBILE } from '../constants';

export const StyledHeaderNav = styled.nav({
  flexGrow: 1,

  '.show-on-mobile': {
    display: 'none',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      display: 'flex',
    },
  },

  [COMPACT_HEADER_MEDIA_QUERY]: {
    position: 'fixed',
    left: 0,
    top: HEADER_HEIGHT_MOBILE,
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT_MOBILE}px)`,
    zIndex: 2,
    visibility: 'visible',
    justifyContent: 'flex-end',
    transform: 'translateX(100%)',
    transition: 'transform 0.4s ease-in-out 0.4s',

    '&[data-state="closed"]': {
      backdropFilter: 'blur(0px)',

      '> div': {
        visibility: 'hidden',
        '> ul': {
          visibility: 'hidden',
        },
      },
    },

    '&[data-state="open"]': {
      transform: 'translateX(0)',
      transition: 'none',

      '& > div': {
        transform: 'translateX(0)',
      },
    },
  },
});

export const StyledHeaderNavInner = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'hidden',
  isolation: 'isolate',
  zIndex: 2,

  [COMPACT_HEADER_MEDIA_QUERY]: {
    position: 'fixed',
    right: 0,
    top: 0,
    flexDirection: 'column',
    backgroundColor: themev2.colors.black.transparent,
    backdropFilter: 'blur(10px)',
    width: '260px',
    height: '100%',
    transform: 'translateX(100%)',
    transition: 'transform 0.4s ease-in-out, visibility 0.4s ease-in-out',
  },
});

export const StyledNavMenuList = styled.ul({
  padding: 0,
  margin: 0,
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',

  li: {
    margin: 0,
    padding: 0,
  },

  [COMPACT_HEADER_MEDIA_QUERY]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px 20px 0',
    gap: 0,

    li: {
      padding: '8px 0 0',
      width: '100%',
    },
  },
  a: {
    ':focus': {
      outlineWidth: 0,
    },
  },
});
