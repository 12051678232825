import { AnchorHTMLAttributes } from 'react';
import { stripUrlProtocol } from '@smartproxy-web/shared/utils';
import { LinkConfig } from '../../contexts/LinkConfigContext';

export const getFollowStatus = (
  config: LinkConfig | undefined,
  urlString?: string
): AnchorHTMLAttributes<'a'>['rel'] => {
  if (!urlString?.startsWith('http')) return;

  if (!!config) {
    const isUrlExcluded = config.nofollow_exclusions.some(
      ({ domain: excludedUrlString }) => {
        const normalizedUrlString = stripUrlProtocol(urlString);
        const normalizedExcludedUrlString = stripUrlProtocol(excludedUrlString);

        try {
          const url = new URL(`http://${normalizedUrlString}`);
          const excludedUrl = new URL(`http://${normalizedExcludedUrlString}`);

          const doDomainsMatch = url.hostname === excludedUrl.hostname;
          const doPathnamesPartiallyMatch = url.pathname.startsWith(
            excludedUrl.pathname
          );
          return doDomainsMatch && doPathnamesPartiallyMatch;
        } catch (error) {
          return false;
        }
      }
    );
    if (isUrlExcluded) return;
    return 'nofollow';
  }
  return undefined;
};
