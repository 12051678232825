export interface TimeComponents {
  days: number;
  hours: number;
  mins: number;
  secs: number;
}

export const splitToTimeComponents = (
  durationInMs: number
): Record<keyof TimeComponents, string> => {
  const padToTwoDigits = (num: number) => `${num}`.padStart(2, '0');

  const secs = padToTwoDigits(Math.floor(durationInMs / 1000) % 60);
  const mins = padToTwoDigits(Math.floor(durationInMs / (60 * 1000)) % 60);
  const hours = padToTwoDigits(
    Math.floor(durationInMs / (60 * 60 * 1000)) % 24
  );
  const days = `${Math.floor(durationInMs / (24 * 60 * 60 * 1000))}`;

  return {
    secs,
    mins,
    hours,
    days,
  };
};
