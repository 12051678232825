import { Title } from 'features/Hero/styledComponents';
import React from 'react';
import { PrismicTextField } from 'types';
import hideable from 'utils/hideable';
import { ipCheckerMsgs } from './ipCheckerMsgs';
import { StyledSubTitle } from './styledComponents';
import { IIpInfo } from './types';

interface ITitle extends IIpInfo {
  color: string;
  title: PrismicTextField;
}

const TitleBlock: React.FC<ITitle> = ({ color, title, info }) => {
  const { ipCheckerLocation, ipCheckerCityCode } = ipCheckerMsgs;

  return (
    <>
      <Title color={color}>
        <h1>
          {title.text} {info.proxy?.ip}
        </h1>
      </Title>
      <StyledSubTitle>
        <p>
          {ipCheckerLocation.defaultMessage}:&nbsp;{info.city?.name}
        </p>
      </StyledSubTitle>
      <StyledSubTitle>
        <p>
          {ipCheckerCityCode.defaultMessage}&nbsp;{info.country?.code}
        </p>
      </StyledSubTitle>
    </>
  );
};

export default hideable(TitleBlock);
