import { AuthorImage, AuthorText } from '../styledComponents';
import { AuthorProps } from '../types';
import { Box } from '@smartproxy-web/ui';

export const Author = ({ user_name: userName, date }: AuthorProps) => {
  const userInitials = userName?.text
    .split(' ')
    .slice(0, 2)
    .map((name) => name[0])
    .join('');

  return (
    <Box
      sx={{
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <AuthorImage>{userInitials}</AuthorImage>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <AuthorText>{userName?.text}</AuthorText>
        <AuthorText>{date}</AuthorText>
      </Box>
    </Box>
  );
};
