import styled from '@emotion/styled';
import { sizeStyles, variantStyles } from './constants';
import type { ButtonSize, StyledButtonProps } from './types';

export const getSizeStyles = ({ size }: { size: ButtonSize }) =>
  sizeStyles[size];

export const getVariantStyles = ({
  variant,
  color,
}: Pick<StyledButtonProps, 'variant' | 'color'>) =>
  variantStyles[variant][color];

export const StyledButton = styled.button<StyledButtonProps>(
  ({ color, fullWidth, variant, size, compact, disabled }) => {
    const variantStyles = getVariantStyles({ variant, color });
    const sizeStyles = getSizeStyles({ size });

    return {
      borderRadius: '6px',
      margin: 0,
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '500',
      transition: '0.3s ease-in-out',
      backgroundColor: 'transparent',
      border: 'none',
      width: fullWidth ? '100%' : 'auto',
      display: fullWidth ? 'flex' : 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: sizeStyles.height,
      ...sizeStyles,
      ...variantStyles.base,
      ...(compact && { padding: 0, minWidth: 0 }),

      ':hover': {
        ...(!disabled && variantStyles.hover),
      },

      ':disabled': {
        cursor: 'not-allowed',
        ...(disabled && { opacity: 0.6 }),
      },
    };
  }
);

export const StyledButtonInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '> svg': {
    height: '16px',
    width: '16px',
  },
});
