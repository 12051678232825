const getBoxPositionOnWindowCenter = (width: number, height: number) => ({
  left:
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2,
  top:
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2,
});

export const openDialog = (url: string) => {
  const size = {
    height: 585,
    width: 585,
  };

  const config: Record<string, string | number> = {
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...size,
    ...getBoxPositionOnWindowCenter(size.width, size.height),
  };

  const dialog = window.open(
    url,
    '_blank',
    Object.keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', ')
  );

  return dialog;
};
