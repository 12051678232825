import { SliceContextValue, useSliceContext } from '../contexts/SliceContext';

export const useSliceDetails = (
  defaultValue?: SliceContextValue
): Partial<SliceContextValue & { id: string }> => {
  let sliceContext = useSliceContext();
  sliceContext = sliceContext ?? defaultValue;
  if (!sliceContext) return {};
  const { name, position } = sliceContext;
  const sliceContextWithID = {
    ...sliceContext,
    id: name ? `${name}/${position}` : '',
  };
  return sliceContextWithID;
};
