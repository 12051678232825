import { BigImageBlock } from './BigImageBlock';
import {
  transformPrismicImageTabsToBigImageProps,
  transformPrismicBlogImagePropsToBigImageProps,
} from './transformers';
import { PrismicImageTabsProps, PrismicBlogImageProps } from './types';

export const PrismicImageTabs = (props: PrismicImageTabsProps) => {
  return <BigImageBlock {...transformPrismicImageTabsToBigImageProps(props)} />;
};

export const PrismicBlogImage = (props: PrismicBlogImageProps) => {
  return (
    <BigImageBlock {...transformPrismicBlogImagePropsToBigImageProps(props)} />
  );
};
