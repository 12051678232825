import { Slice } from '@smartproxy-web/features/src/components/Slice';
import React from 'react';
import {
  RelatedArticlesContainer,
  StyledArticleThumbnails,
} from './ArticleThumbnails/styledComponents';
import { IPostThumbnail } from './ArticleThumbnails/types';

const RelatedArticles: React.FC<IPostThumbnail> = ({
  primary: { title, background_color: background },
  items,
}) => (
  <Slice backgroundColor={background}>
    <RelatedArticlesContainer>
      <StyledArticleThumbnails title={title} thumbnails={items} />
    </RelatedArticlesContainer>
  </Slice>
);

export default RelatedArticles;
