import { normalizeString } from '@smartproxy-web/shared/utils';
import {
  DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ,
  planIds,
} from 'constants/plans';
import { Product } from 'constants/products';

export const getPlanId = (
  plan: string,
  product?: Product,
  isDDCNonSeq?: boolean
) => {
  const normalizedPlan = plan.toLowerCase();
  const normalizedProduct = !!product ? normalizeString(product) : undefined;

  if (isDDCNonSeq)
    return DEDICATED_DATACENTER_PROXIES_PLANS_NON_SEQ[normalizedPlan];
  if (normalizedProduct && normalizedProduct in planIds) {
    return planIds[normalizedProduct][normalizedPlan];
  }
};
