import {
  DocHelpIcon,
  GatsbyImg,
  Lightbox,
  PlayCircleIcon,
  Text,
  convertEmbedDimensionsToFill,
} from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { PrismicTextField, TGatsbyImage } from '@smartproxy-web/ui/src/types';
import { LinkProps } from './GettingStarted';
import { useState } from 'react';
import { TrackingTypes } from '@smartproxy-web/shared/helpers';
import {
  Card,
  ContentWrapper,
  DescriptionWrapper,
  FooterIconsWrapper,
  FooterWrapper,
  HeaderIconWrapper,
  IconButton,
  TitleWrapper,
  StyledLinkButton,
} from './CardStyledComponents';

export type KnowledgeHubCardProps = {
  card_title: string;
  cardDescription: PrismicTextField;
  card_toggle_type: string;
  header_icon: TGatsbyImage;
  button_link?: LinkProps;
  button_text?: string;
  video_iframe?: string;
  doc_link?: LinkProps;
  isDark: boolean;
};

export default function KnowledgeHubCard({
  card_title,
  cardDescription,
  card_toggle_type,
  header_icon,
  button_link,
  button_text,
  video_iframe,
  doc_link,
  onTrack,
  trackSelector,
  isDark,
}: KnowledgeHubCardProps & {
  onTrack?: ({
    cardTitle,
    key,
    type,
  }: {
    cardTitle: string;
    key: string;
    type?: TrackingTypes;
  }) => void;
  trackSelector?: (key: string, type?: TrackingTypes) => string;
}) {
  const [isLightboxVisible, setIsLightboxVisible] = useState<boolean>(false);

  function handleShowLightbox(value: boolean) {
    setIsLightboxVisible(value);
  }

  return (
    <>
      <Card isDark={isDark}>
        <HeaderIconWrapper>
          <GatsbyImg image={header_icon} />
        </HeaderIconWrapper>
        <TitleWrapper isDark={isDark}>
          <Text as="strong" size="xl">
            {card_title}
          </Text>
        </TitleWrapper>
        <ContentWrapper>
          <DescriptionWrapper isDark={isDark}>
            <RichText field={cardDescription} />
          </DescriptionWrapper>
          <FooterWrapper>
            {!!button_link?.url && !!button_text && (
              <StyledLinkButton
                variant="outlined"
                color={isDark ? 'white' : 'black'}
                size="sm"
                to={button_link.url}
                target="_blank"
                rel="noopener noreferrer"
                data-tracker={trackSelector?.(
                  `${card_toggle_type}-${card_title}-${button_text}`,
                  'link'
                )}
                onClick={() =>
                  onTrack?.({
                    cardTitle: card_title,
                    key: button_text,
                    type: 'link',
                  })
                }
              >
                {button_text}
              </StyledLinkButton>
            )}
            {video_iframe && (
              <IconButton
                isDark={isDark}
                variant="text"
                onClick={() => {
                  handleShowLightbox(true);
                  onTrack?.({
                    cardTitle: card_title,
                    key: 'open-video',
                    type: 'button',
                  });
                }}
                data-tracker={trackSelector?.(
                  `${card_title}-open-video`,
                  'button'
                )}
              >
                <FooterIconsWrapper>
                  <PlayCircleIcon />
                </FooterIconsWrapper>
              </IconButton>
            )}
            {doc_link?.url && (
              <IconButton
                isDark={isDark}
                variant="text"
                href={doc_link.url}
                as={'a'}
                target="_blank"
                rel="noopener noreferrer"
                data-tracker={trackSelector?.(
                  `${card_toggle_type}-${card_title}-open-doc`,
                  'link'
                )}
                onClick={() =>
                  onTrack?.({
                    cardTitle: card_title,
                    key: 'open-doc',
                    type: 'link',
                  })
                }
              >
                <FooterIconsWrapper>
                  <DocHelpIcon />
                </FooterIconsWrapper>
              </IconButton>
            )}
          </FooterWrapper>
        </ContentWrapper>
      </Card>

      {video_iframe && (
        <Lightbox
          closeBtnTrackingId={trackSelector?.(
            `${card_title}-close-video`,
            'button'
          )}
          isOpen={isLightboxVisible}
          handleClose={() => {
            if (!isLightboxVisible) return;
            handleShowLightbox(false);
            onTrack?.({
              cardTitle: card_title,
              key: 'close-video',
              type: 'button',
            });
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: convertEmbedDimensionsToFill(video_iframe),
            }}
          />
        </Lightbox>
      )}
    </>
  );
}
