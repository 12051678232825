import React, { createContext, useContext } from 'react';

export type SiteLang = 'en' | 'cn';

export interface BuildContextValue<S extends string = string> {
  site?: S;
  lang: SiteLang;
}

export const BuildContext = createContext<BuildContextValue<string>>({
  lang: 'en',
});

export const useBuildContext = <S extends string = string>() =>
  useContext<BuildContextValue<S>>(BuildContext);

export const BuildContextProvider = <S extends string>({
  children,
  value,
}: React.PropsWithChildren<{ value: BuildContextValue<S> }>) => (
  <BuildContext.Provider value={value}>{children}</BuildContext.Provider>
);
