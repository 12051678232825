import { StyledCarousel } from './styledComponents';
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react';
import { Swiper as SwiperInstance } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/free-mode';

export type CarouselRenderItemInfo<ItemType> = {
  item: ItemType;
  index: number;
};

export type CarouselRenderItem<ItemType> = ({
  item,
  index,
}: CarouselRenderItemInfo<ItemType>) => React.ReactElement | null;

interface CarouselProps<ItemType> {
  config: SwiperProps & { slideWidth?: number };
  items: ItemType[];
  renderItem: CarouselRenderItem<ItemType>;
  isDark?: boolean;
  sideGradient?: boolean;
}

export type CarouselInstance = SwiperInstance;

const dropNoJSStyles = (swiper: CarouselInstance) => {
  swiper.wrapperEl.style.gap = '0px';
  swiper.wrapperEl.style.overflowX = 'unset';
};

export const Carousel = <ItemType,>({
  config,
  items,
  renderItem,
  isDark = false,
  sideGradient = true,
}: CarouselProps<ItemType>) => (
  <StyledCarousel
    sideGradient={sideGradient}
    isDark={isDark}
    slidesGap={config.spaceBetween as number}
    slideWidth={config.slideWidth}
  >
    <Swiper {...config} onInit={dropNoJSStyles}>
      {items.map((item, i) => (
        <SwiperSlide key={i}>{renderItem({ item, index: i })}</SwiperSlide>
      ))}
    </Swiper>
  </StyledCarousel>
);
