import styled from '@emotion/styled';
import { StyledMoneyBackContainer } from 'components/v2/MoneyBackTag/styledComponents';
import {
  MAX_LP_HERO_WIDTH_DESKTOP,
  MAX_LP_HERO_WIDTH_MOBILE,
} from 'constants/styles';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';

export const StyledHeroWrapper = styled.section({
  background: themev2.colors.neutral.N99,
  overflow: 'hidden',
  padding: `${themev2.spacings.L} 20px`,
  width: '100%',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: '32px 20px',
  },
});

export const StyledHeroWrapperInner = styled.div({
  maxWidth: MAX_LP_HERO_WIDTH_DESKTOP,
  margin: '0 auto',
  width: '100%',
  color: themev2.colors.white.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [String(StyledMoneyBackContainer)]: {
    alignSelf: 'center',
    'svg, span': {
      color: themev2.colors.neutral.N40,
    },
    span: {
      fontFamily: themev2.fonts.family.additional,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: MAX_LP_HERO_WIDTH_MOBILE,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    [String(StyledMoneyBackContainer)]: {
      alignSelf: 'flex-start',
    },
  },
});

export const StyledImageWrapper = styled.div({
  '[data-gatsby-image-wrapper]': {
    width: '100%',
    height: '100%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    alignSelf: 'flex-start',
    '[data-gatsby-image-wrapper]': {
      height: '24px',
      width: '105px',
    },
  },
});

export const StyledTitle = styled.div({
  width: '100%',
  h1: {
    width: 'auto',
    fontSize: themev2.fonts.size.h1,
    lineHeight: 1.5,
    fontWeight: 600,
    span: {
      backgroundImage: `linear-gradient(135deg, #FE39C7 0%, #5200FF 52%, ${themev2.colors.green.default} 100%)`,
      backgroundClip: 'text',
      color: 'transparent',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      fontSize: themev2.fonts.size.h1,
      textAlign: 'left',
    },
  },
});

export const StyledDescription = styled.div({
  p: {
    fontFamily: themev2.fonts.family.additional,
    marginTop: '16px',
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      textAlign: 'left',
      marginTop: '20px',
    },
  },
});

export const StyledCtasWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: themev2.spacings.S,
  'a > div': {
    minWidth: '200px',
  },
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: 'column',
    width: '100%',
    'a > div': {
      width: '100%',
    },
  },
});

export const StyledFeaturesWrapper = styled.ul({
  width: '100%',
  display: 'grid',
  alignItems: 'start',
  gridTemplateColumns: 'repeat(4, 1fr)',
  marginTop: '48px',
  paddingLeft: 0,
  gap: themev2.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
    gap: '20px',
    marginTop: '32px',
    gridTemplateColumns: '1fr',
  },
});

export const StyledCheckmarkListItemWrapper = styled.li({
  display: 'inline-flex',
  alignItems: 'flex-start',
  gap: '8px',
  paddingBottom: 0,
  svg: {
    flexShrink: 0,
  },
  p: {
    fontWeight: 400,
    fontFamily: themev2.fonts.family.additional,
  },
});

export const StyledReviewsWrapper = styled.div({
  display: 'flex',
  width: '100%',
  marginTop: '48px',
  padding: `${themev2.spacings.S} 36px`,
  borderRadius: '10px',
  border: `1px solid ${themev2.colors.neutral.N80}`,
  gap: '36px',
  alignItems: 'center',
  [MEDIA_QUERIES.TABLET]: {
    marginTop: '48px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
    border: 'none',
    padding: 0,
    marginTop: '32px',
    alignItems: 'flex-start',
    gap: '20px',
  },
});

export const StyledReviewTitle = styled.div({
  minWidth: '200px',
  p: {
    fontSize: themev2.fonts.size.h4,
    fontWeight: 700,
    fontFamily: themev2.fonts.family.additional,
  },
});

export const StyledReviewContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: themev2.spacings.XS,
  p: {
    display: 'inline-block',
    fontSize: themev2.fonts.size.p_xsmall,
    fontFamily: themev2.fonts.family.additional,
    letterSpacing: '1.2px',
    color: themev2.colors.neutral.N20,
    textTransform: 'uppercase',
    padding: `2px ${themev2.spacings.XS}`,
    border: `1px solid ${themev2.colors.neutral.N80}`,
    borderRadius: '48px',
  },
});
