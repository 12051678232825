import styled from '@emotion/styled';
import { theme } from '@smartproxy-web/ui';
import { MEDIA_QUERIES } from 'globalStyles';

export const RichTextWrapper = styled.div<{
  background: string;
}>(({ background }) => ({
  background,
  width: '100%',
  padding: `0 20px ${theme.spacings.L}`,
}));

export const TextWrapper = styled.div<{
  isCentered: boolean;
  isDisclaimer: boolean;
}>(({ isCentered, isDisclaimer }) => ({
  width: '100%',
  maxWidth: '1024px',
  margin: '0 auto',
  textAlign: isCentered ? 'center' : 'left',
  color: isDisclaimer ? theme.colors.neutral.N50 : theme.colors.neutral.N99,
  'ul, ol': {
    listStylePosition: 'inside',
    fontSize: theme.fonts.size.h4,
    li: {
      marginBottom: '20px',
    },
  },
  'p, strong': {
    marginBottom: '1.5rem',
    fontSize: isDisclaimer ? theme.fonts.size.p_small : theme.fonts.size.h4,
  },
  strong: {
    fontWeight: '700',
  },
  'h1, h2, h3, h4, h5, h6': {
    fontFamily: theme.fonts.family.main,
    width: '100%',
    marginBottom: theme.spacings.XS,
    textAlign: isCentered ? 'center' : 'left',
  },
  'em, a': {
    fontFamily: theme.fonts.family.additional,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    'p, strong, em, a, h5, li': {
      fontSize: isDisclaimer
        ? theme.fonts.size.p_small
        : theme.fonts.size.p_medium,
    },
    h2: {
      fontSize: theme.fonts.size.h3,
    },
    h3: {
      fontSize: theme.fonts.size.h4,
    },
    h4: {
      fontSize: theme.fonts.size.h5,
    },
    h6: {
      fontSize: theme.fonts.size.p_small,
    },
  },
}));
