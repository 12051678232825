import { SiteLang } from '../../contexts/BuildContext';

// TODO(https://datatroops.atlassian.net/browse/WS-2985): implement robust internationalization
export const translations = {
  back_to_blog: {
    cn: '返回博客',
    en: 'Back to blog',
  },
  min_read: {
    cn: '分钟阅',
    en: 'min. read',
  },
  copy_link: {
    cn: '复制链接',
    en: 'Copy Link',
  },
  copied_link: {
    cn: '已复制!',
    en: 'Copied!',
  },
  new: {
    cn: '全新',
    en: 'New',
  },
  see_all: {
    cn: '查看所有',
    en: 'See all',
  },
  show_more: {
    cn: '显示更多',
    en: 'Show more',
  },
  most_recent: {
    cn: '最新',
    en: 'Most recent',
  },
  most_popular: {
    cn: '热门',
    en: 'Most popular',
  },
  show_less: {
    cn: '显示更少',
    en: 'Show less',
  },
  or: {
    cn: '或者',
    en: 'or',
  },
  read_more: {
    cn: ' 阅读更多',
    en: 'Read more',
  },
} as const satisfies Record<string, Record<SiteLang, string>>;
