import { ComponentPropsWithoutRef } from 'react';
import styled from '@emotion/styled';
import { themev2 } from 'theme-v2';
import { Box } from '../Box';
import { BoxProps } from '../Box/types';

type TextSize = 'lg' | 'md' | 'sm' | 'xs';
type TextAs = 'p' | 'span' | 'em' | 'strong' | 'small' | 'pre';

type TextProps = BoxProps &
  ComponentPropsWithoutRef<TextAs> & {
    as?: TextAs;
    size?: TextSize;
  };

const fontSizes: Record<TextSize, string> = {
  lg: themev2.fonts.size.p_large,
  md: themev2.fonts.size.p_medium,
  sm: themev2.fonts.size.p_small,
  xs: themev2.fonts.size.p_xsmall,
};

const StyledText = styled(Box)(({ as }) => ({
  '&&': {
    fontFamily: themev2.fonts.family.additional,
  },
  ...(as === 'pre' && {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    fontFamily: themev2.fonts.family.mono,
  }),
}));

export const Text = ({ as = 'p', size = 'md', sx, ...rest }: TextProps) => (
  <StyledText
    as={as}
    sx={{ fontSize: fontSizes[size], lineHeight: '1.5', ...sx }}
    {...rest}
  />
);
