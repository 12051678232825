import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import HeaderLogo from './Logo';
import { StyledHeader } from '../Header/styledComponents';
import { StyledHeaderContents } from './styledComonents';
import { HeaderLpDealsProps } from './types';
import hideable from 'utils/hideable';

export const HeaderLpDeals: React.FC<HeaderLpDealsProps> = ({
  primary: { logo_alignment: isLogoCentered, logo_clickable: isLogoClickable },
}) => {
  const { tags, type } = usePageContext();

  const buildTrackingId = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'header-lp-deals',
  });
  const logoTrackerId = buildTrackingId({ context: 'logo', type: 'link' });

  return (
    <StyledHeader>
      <StyledHeaderContents isLogoCentered={isLogoCentered}>
        <HeaderLogo
          logoTrackerId={logoTrackerId}
          isClickable={isLogoClickable}
        />
      </StyledHeaderContents>
    </StyledHeader>
  );
};

export const HideableHeaderLpDeals = hideable(HeaderLpDeals);
