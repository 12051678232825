import {
  StyledTabbedCodeBlockActions,
  StyledTabbedCodeBlockHeaderWrapper,
  StyledTabbedCodeBlockSubtitleWrapper,
  StyledTabbedCodeBlockTitleWrapper,
} from './styledComponents';
import { LinkButton } from '../LinkButton';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { PrismicTextField } from '@smartproxy-web/ui';
import { RichText } from '../RichText';
import { BuildTrackingIdParams } from '@smartproxy-web/shared/helpers';

interface TabbedCodeBlockHeaderProps {
  title?: PrismicTextField;
  subtitle?: PrismicTextField;
  actions?: { label: string; url: string }[];
  buildTrackingId: (props?: BuildTrackingIdParams) => string;
}

export const TabbedCodeBlockHeader = ({
  title,
  subtitle,
  actions,
  buildTrackingId,
}: TabbedCodeBlockHeaderProps) => (
  <StyledTabbedCodeBlockHeaderWrapper>
    {title?.text && (
      <StyledTabbedCodeBlockTitleWrapper>
        <RichText field={title} />
      </StyledTabbedCodeBlockTitleWrapper>
    )}
    {subtitle?.text && (
      <StyledTabbedCodeBlockSubtitleWrapper>
        <RichText field={subtitle} />
      </StyledTabbedCodeBlockSubtitleWrapper>
    )}
    {!!actions && actions?.length > 0 && (
      <StyledTabbedCodeBlockActions>
        {actions.map((action) => (
          <>
            <LinkButton
              key={action.url}
              data-theme="light"
              to={action.url}
              variant="outlined"
              color="black"
              data-tracker={buildTrackingId({
                context: action.label,
                type: 'link',
              })}
              onClick={() =>
                logPostHogEvent(
                  buildTrackingId({
                    context: action.label,
                    type: 'link',
                  })
                )
              }
            >
              {action.label}
            </LinkButton>
            <LinkButton
              data-theme="dark"
              to={action.url}
              variant="outlined"
              color="white"
              data-tracker={buildTrackingId({
                context: action.label,
                type: 'link',
              })}
              onClick={() =>
                logPostHogEvent(
                  buildTrackingId({
                    context: action.label,
                    type: 'link',
                  })
                )
              }
            >
              {action.label}
            </LinkButton>
          </>
        ))}
      </StyledTabbedCodeBlockActions>
    )}
  </StyledTabbedCodeBlockHeaderWrapper>
);
