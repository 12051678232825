import {
  IconAdspower,
  IconBitBrowser,
  IconHubStudio,
  IconMailv2,
  IconGeeLark,
  IconQQ,
  IconSkypev2,
  IconSupport,
  IconTelegramv2,
  IconVMLogin,
  IconWechat,
  IconClonBrowser,
  IconXLogin,
  IconMuLogin,
  IconYunLogin,
  IconHidemium,
  IconDiscordv2,
  IconDuoPlus,
} from 'images';

export enum FOOTER_SLICES {
  NAV = 'nav_item',
  TOP_SLICE = 'top_slice',
}

export interface IFooter {
  isDealsOrLpPage: boolean;
}

export interface FooterLink {
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  label?: string;
  key: string;
  url?: string;
}

export const FOOTER_ACTIONS_SLICE_NAME = 'footer_action_list';

export const footerConnectLinks: FooterLink[] = [
  { icon: IconMailv2, key: 'mail', url: 'mailto:china-sales@smartproxy.com' },
  {
    icon: IconSupport,
    key: 'chat',
    url: 'https://direct.lc.chat/12092754/5',
  },
  {
    icon: IconSkypev2,
    key: 'skype',
    url: 'https://join.skype.com/invite/CCSOEo4jlFGF',
  },
  {
    icon: IconTelegramv2,
    key: 'telegram',
    url: 'https://t.me/Smartproxy_salesCN',
  },
  { icon: IconQQ, key: 'qq', url: '' },
  { icon: IconWechat, key: 'wechat', url: '' },
  {
    icon: IconDiscordv2,
    key: 'discord',
    url: 'https://discord.gg/ZDjwYxumKd',
  },
];

export const footerSocialLinks: FooterLink[] = [
  {
    key: 'zhihu',
    label: '知乎',
    url: 'https://www.zhihu.com/org/smartproxy-china',
  },
  {
    key: 'csdn',
    label: 'CSDN',
    url: 'https://blog.csdn.net/SmartGarret',
  },
  {
    key: 'juejin',
    label: '稀土掘金',
    url: 'https://juejin.cn/user/879255093799640',
  },
  {
    key: 'github',
    label: 'Github',
    url: 'https://github.com/Smartproxy',
  },
  {
    key: 'bilibili',
    label: 'Bilibili',
    url: 'https://space.bilibili.com/1032781620?spm_id_from=333.337.search-card.all.click',
  },
  { key: 'discord', label: 'Discord', url: 'https://discord.gg/gvJhWJPaB4' },
];

export const footerPartnerLinks: FooterLink[] = [
  {
    icon: IconAdspower,
    label: 'AdsPower',
    key: 'adspower',
    url: 'https://www.adspower.net/?code=smartproxy',
  },
  {
    icon: IconMuLogin,
    label: 'MuLogin',
    key: 'MuLogin',
    url: 'https://www.mulogin.com/?l=cn',
  },
  {
    icon: IconGeeLark,
    label: 'GeeLark',
    key: 'geelark',
    url: 'https://www.geelark.cn/?utm_source=useful-links&utm_medium=partner&utm_campaign=smartdaili',
  },
  {
    icon: IconVMLogin,
    label: 'VMLogin',
    key: 'vmlogin',
    url: 'https://vmlogin.cc/',
  },
  {
    icon: IconClonBrowser,
    label: 'ClonBrowser',
    key: 'clonbrowser',
    url: 'https://www.clonbrowser.net/?qd=Smartproxy-zh',
  },
  {
    icon: IconHidemium,
    label: 'Hidemium',
    key: 'hidemium',
    url: 'https://hidemium.io/',
  },
  {
    icon: IconBitBrowser,
    label: '比特浏览器',
    key: 'bitbrowser',
    url: 'http://www.bitbrowser.cn/',
  },
  {
    icon: IconHubStudio,
    label: 'HubStudio',
    key: 'hubstudio',
    url: 'https://hubstudio.cn/',
  },
  {
    icon: IconXLogin,
    label: 'XLogin',
    key: 'xlogin',
    url: 'https://xlogin.us/?sub=smartproxy',
  },
  {
    icon: IconYunLogin,
    label: 'Yunlogin',
    key: 'yunlogin',
    url: 'https://www.yunlogin.com/?linkuuid=GJ',
  },
  {
    icon: IconDuoPlus,
    label: 'DuoPlus',
    key: 'DuoPlus',
    url: 'https://www.duoplus.cn/?r=smartdaili',
  },
];
