import { ArticlesFoundContainer } from './styledComponents';
import { ArticlesFoundProps } from './types';

export const ArticlesFound = ({ count, inputValue }: ArticlesFoundProps) => (
  <ArticlesFoundContainer data-tracker="search-results" count={count}>
    <p>{count} blog posts found with a keyword:</p>
    <h2>{inputValue}</h2>
    {count === 0 && <p>Please try again.</p>}
  </ArticlesFoundContainer>
);
