import { GatsbyImg, Text } from '@smartproxy-web/ui';
import {
  ContentContainer,
  ContentText,
  CtaContainer,
} from './styledComponents';
import { LinkButton } from '../LinkButton';
import { OfferCountdownStatus } from './OfferCountdownStatus';
import { ItemProps } from './types';
import { logPostHogEvent } from '@smartproxy-web/analytics';

export const ContentItem = ({
  content,
  cta_text: ctaText,
  cta_link: ctaLink,
  banner_icon: icon,
  end_date: endDate,
  lang,
  index,
}: ItemProps & { lang: string; index: number }) => {
  const trackingSelector = `promo-banner-${index + 1}-cta`;
  return (
    <ContentContainer>
      <ContentText>
        {!!icon.url && (
          <div>
            <GatsbyImg image={icon} />
          </div>
        )}
        <Text size="sm" sx={{ fontWeight: 600 }}>
          {content}
        </Text>
      </ContentText>
      {!!endDate && <OfferCountdownStatus expiry={endDate} lang={lang} />}
      <CtaContainer>
        <LinkButton
          to={ctaLink.url}
          size="sm"
          color="black"
          fullWidth
          data-tracker={trackingSelector}
          onClick={() => logPostHogEvent(trackingSelector)}
        >
          {ctaText}
        </LinkButton>
      </CtaContainer>
    </ContentContainer>
  );
};
